.products_inner_listing {
  .tech_btn {
    visibility: hidden;
  }
}
.technical_sec3 {
  margin-top: 7.188em;
  margin-bottom: 4.188em;
  h3 {
    width: 100%;
    max-width: 70%;
  }
  ul {
    //   display:inline-flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    li {
      padding: 2em 1em 0 0;
      // padding: 1em 0 0 0;
      text-align: left;
      font: normal normal 300 20px/33px National;
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      display: block;
      padding-left: 1em;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 2.5em;
        height: 0.5em;
        width: 0.5em;
        // margin: 0.2em;
        // margin-right: 0.4em;
        background-color: #064f9e;
        border-radius: 50%;
      }
    }
  }
}

.left_section {
  .yarns {
    margin-top: 0.5em;
    font-family: "National-Medium";
    font-size: 2.28em;
    line-height: 1.2em;
  }
  .new_link {
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
}
.products_inner_watch_now {
  // .video_section {
  //   display: inline-block;
  //   // max-width: 41.6875em;
  //   width: 100%;
  //   position: relative;
  //   .playicon {
  //     max-width: 4.375em;
  //     width: 100%;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     cursor: pointer;
  //     transform: translate(-50%, -50%);
  //   }
  // }
}

@media (max-width: 834px) {
  .products_inner {
    .left_section {
      .hollow_title {
        font-size: 7em !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .technical_sec3 {
    margin-top: 7.188em;
    // margin-bottom: 4.188em;
    h3 {
      max-width: 100%;
      font-size: 2.5em;
    }
    ul li {
      font-size: 1.6em;
      // padding: 0.5em 0em;
      &::before {
        top: 2.85em;
      }
    }
  }
  .products_inner_watch_now {
    margin-top: 4em;
  }
  .left_section {
    .yarns {
      font-size: 3.125em;
    }
  }
}
