.our_setup_infrastructure {
  .fg_img {
    top: 37%;
  }
  .left_section {
    ul li {
      position: relative;
      padding-left: 1em;
      &::before {
        content: "";
        position: absolute;
        top: 0.5em;
        left: 0.1em;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #002b5a;
      }
    }
    .Infrastructure {
      margin-top: 0.5em;
      font-family: "National-Medium";
      font-size: 2.28em;
      line-height: 1.2em;
    }
    .hollow_title {
      font-size: 8.75em !important;
      line-height: 1.3em !important;
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
    .new_link {
      color: inherit;
      font-family: inherit;
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
  }
}
.infra_section2 {
  .infra_section2_second_section {
    min-height: 61.5625em;
    margin-top: 22.28125em;
    position: relative;
    background-color: #002b5a;
    figure {
      top: -24em;
      width: 100%;
      position: absolute;
    }
  }
}
.infra_sec2 {
  margin-top: 8.625em;
  ul {
    margin-top: 2em;
    li {
      position: relative;
      padding-left: 1em;
      margin-top: 1em;
      letter-spacing: 0px;
      color: #1c1c1c;
      font-family: "National-Light";
      font-size: 1.57em;
      opacity: 1;
      width: 100%;
      max-width: 80%;
      &::before {
        content: "";
        position: absolute;
        top: 0.5em;
        left: 0.1em;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #064f9e;
      }
    }
  }
  .new_link {
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
}

@media (max-width: 834px) {
  .infra_section2 {
    .infra_section2_second_section {
      min-height: 40.5625em;
    }
  }
  .infra_sec2 {
    ul {
      margin-top: 0.5em;
      margin-bottom: 3em;
      li {
        margin-top: 0.3em;
        max-width: 100%;
        font-size: 1.6em;
      }
    }
  }
  .our_setup_infrastructure {
    .left_section {
      .hollow_title {
        font-size: 12.5em !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .infra_section2 {
    .infra_section2_second_section {
      margin-top: 9.28125em;
      min-height: 37.5625em;
      figure {
        top: -9em;
      }
    }
    .infra_section2_third_section {
      background-color: #002b5a;
      // margin-top: 0;
      .benefits_slider_heading_section {
        position: relative;
      }
      .navigations {
        margin: 2em 0 4em;
        // position: absolute;
        // right: 34%;
        // bottom: -53em;
        // background-color: #1c1c1c;
        display: flex;
        justify-content: center;
        .left_arrow:after {
          right: 0;
        }
        button {
          padding-right: 2em;
          padding-top: 0.5em;
        }
        .right_arrow {
          padding-left: 1em;
        }
      }
    }
  }
  .infra_sec2 {
    ul {
      margin-top: 0.5em;
      margin-bottom: 3em;
      li {
        margin-top: 0.3em;
        max-width: 100%;
      }
    }
  }
  .our_setup_infrastructure {
    .left_section {
      .hollow_title {
        font-size: 5em !important;
        line-height: 1.88em !important;
      }
    }
  }
}
