/* Importing Common CSS File */
@import url("../../common.css");
.contactUs {
  padding: 3em 0 0;
  margin-top: 8.5em;
  /* margin-top: 9.4em; */
  /* background-color: #00000029;  */
}

.contact_us_wrapper {
  margin-top: 1.9375em;
  display: flex;
  justify-content: space-between;
}

.contactUs .left_section h3 {
  margin-top: 0.5em;
}
.form_wrapper {
  margin-top: 3em;
}
.contactUs .left_section {
  max-width: 40em;
}
.contactUs .right_section figure {
  max-width: 45.625em;
  height: auto;
}

.contactUs .left_section text_area {
  resize: none;
}
/* .form_input_wrapper:not(:first-child) { margin-top: 2.5em; } */
.PhoneInputInput {
  border: none;
}

.submit_button_container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.submit_button_container:hover .arrow {
  transform: scaleX(1.2);
}
.sanathan_btn {
  text-align: left;
}

.sanathan_submit_btn {
  padding: 0;
  border: none;
  margin-top: 1em;
  /* position: relative; */
  background: transparent;
  color: #1e5093;
  font-family: "National-Medium";
  font-size: 1.375em;
  line-height: 2.77em;
  text-transform: uppercase;
}

.submit_button_container .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left;
  transition: 0.25s ease-in-out;
}

.contactUs .right_section {
  position: relative;
}

.contactUs .right_section_content {
  padding: 6.5em 6.625em 6.5em 5.625em;
  position: absolute;
  top: 0;
}

.contactUs .right_section_content h3 {
  color: #fff;
  font-family: "National-Medium";
}

.contactUs .right_section_content ul {
  margin-top: 3em;
}

.contactUs .right_section_content .contact_details {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  color: #fff;
  font-family: "National-Light";
  font-size: 1em;
  line-height: 1em;

  .email {
    color: #fff;
  }
}

.contactUs .right_section_content .contact_details p {
  font-family: "National-Light";
  font-size: 1em;
  line-height: 1.5em;
}

.contactUs .right_section_content .contact_details li:not(:first-child) {
  margin-top: 2.5em;
}

.contactUs .right_section .contact_details img {
  max-width: 4em;
  margin-right: 1em;
  display: inline-block;
  vertical-align: top;
}

.contactUs .right_section .contact_details p {
  max-width: 75%;
  display: inline-block;
}

.contactUs .right_section .contact_details a {
  margin-top: 1em;
  font-size: 1.5em;
}

.contactUs .right_section .social_icons {
  max-width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* align-items: flex-end; */
}

.err {
  color: red;
}

.contactUs .contact_wrapper {
  // border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin-top: 2.5em;
}

.contactUs .contact_wrapper .PhoneInputCountry {
  padding: 4px 0 5px;
}

.contactUs .contact_wrapper .PhoneInputInput {
  height: 1.4375em;
  padding: 4px 0 5px;
  font-size: 1rem;
  outline: none;
}

.contactUs .contact_wrapper .PhoneInput {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.map_wrapper {
  padding: 11em 0 0;
}

.MuiFormControl-root {
  width: 100%;
}

// .contactUs .contact_select_wrapper {
//   margin-top: 1.5em;
// }

// .contactUs .contact_select_wrapper .are_you_select {
//   margin-top: 1em;
//   &:hover {
//     &::before {
//       border-bottom: 1px solid rgba(0, 0, 0, 0.42);
//     }
//   }
// }

@media (max-width: 992px) {
  .contactUs .contact_wrapper .PhoneInputInput {
    height: initial;
  }
}

@media (max-width: 768px) {
  .contactUs .right_section {
    display: block;
    text-align: left;
    margin-right: 0;
  }
  .contact_us_wrapper {
    flex-flow: column;
  }
  .contactUs .left_section {
    max-width: 100%;
  }
  .contactUs .left_section h3,
  .contactUs .right_section_content h3 {
    font-size: 2.4em;
    line-height: 1.2083em;
  }
  .form_input_wrapper:nth-child(3) {
    margin-top: 3.5em;
  }
  .submit_button_container button {
    font-size: 1.4em;
  }
  .contactUs .right_section {
    margin-top: 8em;
  }
  .contactUs .right_section_content {
    padding: 5.3em 3.7em 5.3em 2.2em;
  }

  .contactUs .right_section figure {
    max-width: 100%;
    height: 45em;
  }

  .contactUs .right_section figure img {
    height: 100%;
  }
  .contactUs .right_section_content .contact_details {
    margin-top: 1.6em;
  }

  .contactUs .right_section_content .social_icons {
    max-width: 65%;
    margin-top: 4.7em;
  }

  .contactUs .right_section .contact_details p {
    font-size: 0.946em;
    /*!important;*/
    line-height: 1.53em;
    /*!important;*/
  }

  .contactUs .right_section_content .contact_details li:not(:first-child) {
    margin-top: 1.5em;
  }
  .contactUs .right_section .contact_details img {
    max-width: 3.5em;
  }
  .contactUs .right_section .contact_details a {
    margin-top: 1em;
  }

  .contactUs .right_section .social_icons {
    align-items: unset;
  }
}

@media (max-width: 450px) {
  .contactUs .right_section .social_icons {
    max-width: 55%;
    align-items: center;
  }

  .contact_details li:first-child img {
    margin-bottom: 2em;
  }
  .contactUs .right_section_content {
    padding: 8em 3.7em 5.3em 2.2em;
  }
}

@media (max-width: 376px) {
  .contactUs .right_section_content .social_icons {
    max-width: 65%;
  }
  .contact_details li:first-child img {
    margin-bottom: 1em;
  }
  .contact_details li:first-child img {
    margin-bottom: 2.5em;
  }
  /* .contactUs .social_icons li a { height: 1em !important; } */
}
