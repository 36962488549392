/* Importing Common CSS File */
@import url("../../common.css");

.aboutus_the_company {
  padding: 4em 0 8.5625em;
  margin-top: 8.5em;
  position: relative;
  background-color: #f0f6fd;
}

.aboutus_the_company .left_section {
  max-width: 50%;
}

.aboutus_the_company .left_section h3 {
  margin-top: 0.5em;
}

.aboutus_the_company .left_section p {
  max-width: 80%;
  margin-top: 1.1875em;
  font-size: 1.45em;
}

.aboutus_the_company .right_section .bg_img {
  max-width: 41.0625em;
  position: absolute;
  top: 0;
  right: 0;
}

.aboutus_the_company .right_section .fg_img {
  max-width: 46.1875em;
  position: absolute;
  top: 31%;
  right: 8%;
  transform: translateY(-30%);
}

.aboutus_the_company .additional_content {
  max-width: 67em;
  margin: 12.125em auto 0;
}

.aboutus_the_company .additional_content h2 {
  max-width: 100%;
  position: relative;
  color: #002b5a;
  font-family: "National-Medium";
  line-height: 1.2125em;
  text-align: center;
  text-transform: none;
}

.aboutus_the_company .additional_content h2:before {
  content: "";
  max-width: 3.8em;
  width: 100%;
  min-height: 3.8em;
  height: 100%;
  border: 5px dashed #004083;
  border-radius: 50%;
  position: absolute;
  top: -1.2em;
  left: -0.1em;
  opacity: 0.5;
}

.aboutus_the_company .additional_content p {
  font-family: "National-Medium";
  font-size: 2.625em;
  line-height: 1.5em;
  text-align: right;
}

.clients {
  /* margin-top: -10em; */
  background-color: #002b5a !important;
}

.clients .hollow_title {
  opacity: 0.4;
  -webkit-text-stroke-color: #fff;
}

.clients .hollow_title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #fff, #fff 50%, transparent 50%);
  opacity: 0.4;
  -webkit-text-stroke-color: #fff;
}

.clients .hollow_title {
  -webkit-text-stroke-color: #fff;
}

.clients h3 {
  color: #fff;
}

.clients .clients_slider_wrapper {
  padding: 4.55125em 0 6.663125em 0;
}

.clients_slider_wrapper ul {
  margin-top: 7em;
}

.clients .clients_slider_wrapper ul li {
  max-width: 21.1875em;
}

.clients_slider_wrapper ul li img {
  max-width: 16.6875em;
}

.clients .clients_slider_wrapper_heading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3em;
}

.clients .clients_slider_wrapper .navigations button {
  color: #fff !important;
}

.clients .clients_slider_wrapper .navigations .left_arrow::after {
  border: 2px dashed #fff;
}

.clients .clients_slider_wrapper .navigations .right_arrow::before {
  border: 2px dashed #fff;
}

.clients_slider_wrapper .swiper-wrapper {
  align-items: center;
}

/* .pagination_wrapper {
    height: 50px;
    margin-top: 4em;
    display: flex;
    justify-content: center;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 1px solid #1E5093;
    border-radius: 50%;
    margin-left: 0.4375em;
    background-color: transparent;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    width: 100px;
    border-radius: 4px;
    background: #1E5093;
    color: #1E5093;
    transition: 0.3s ease;
} */

/* .aboutus_subsidiaries { 
    padding: 6.34375em 0 10em;
    position: relative;
    background-image: url("https://dummyimage.com/739x560/002c5a/002B5A"); 
}

.aboutus_subsidiaries .hollow_title {
    line-height: 1.53em;
    opacity: 0.5;
    -webkit-text-stroke-color: #fff; 
}

.aboutus_subsidiaries .subsidiaries_heading p {
    max-width: 35em;
    width: 100%;
    color: #fff;
    font-family: 'National-Light';
    font-size: 1.25em;
    line-height: 1.65em;
}

.aboutus_subsidiaries .subsidiaries_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aboutus_subsidiaries .subsidiaries_slider { margin-top: 9.6875em; }

.aboutus_subsidiaries .navigations {
    position: absolute;
    top: 40%;
    right: 10%;
} */

.aboutus_the_company .bg_img_mobile {
  display: none;
}

.stockWrapper {
  background-color: #f0f6fd;
}
@media (max-width: 1920px) {
  .clients_slider_wrapper {
    margin-left: 7em;
  }
}

@media (max-width: 1536px) {
  .clients_slider_wrapper {
    margin-left: 8em;
  }
}

@media (max-width: 1480px) {
  .clients_slider_wrapper {
    margin-left: 6em;
  }
}

@media (max-width: 1367px) {
  .clients_slider_wrapper {
    margin-left: 5em;
  }
}

@media (max-width: 1280px) {
  .clients_slider_wrapper {
    margin-left: 8em;
  }
}

@media (max-width: 1195px) {
  .clients_slider_wrapper {
    margin-left: 4em;
  }
}

@media (max-width: 991px) {
  .aboutus_the_company .right_section .bg_img {
    max-width: 40%;
  }

  .aboutus_the_company .right_section .fg_img {
    max-width: 50%;
  }
}

@media (max-width: 835px) {
  .aboutus_the_company .left_section {
    max-width: 100%;
  }

  .aboutus_the_company .right_section .bg_img {
    top: initial;
    bottom: 9%;
  }

  .aboutus_the_company .right_section .fg_img {
    position: relative;
    top: initial;
    right: initial;
    left: 25%;
    transform: translateY(0);
    margin-top: 7em;
  }

  .aboutus_the_company .right_section .bg_img .bg_img_desktop {
    display: block;
  }

  .aboutus_the_company .right_section .bg_img .bg_img_mobile {
    margin-top: 0;
  }
  .stockWrapper .stock_box{
    margin-top: 0em;
    padding-right: 0;
  }
  .stockWrapper {
    padding-bottom: 8.563em;
  }
  .stockWrapper .stock_box .stock_rate_graph_wrapper {
    width: auto;
  }
}

@media (min-width: 835px) {
  .stock_fg_img_wrpper_right .stock_box{
    position: absolute !important;
    max-width: 46.1875em !important;
  }
}
@media (max-width: 768px) {
  .aboutus_the_company {
    padding: 2em 0 7.5em;
    position: relative;
  }

  .aboutus_the_company .left_section {
    max-width: 100%;
  }

  .aboutus_the_company .left_section h3 {
    font-size: 2.4em;
    line-height: 1.25em;
  }

  .aboutus_the_company .left_section p {
    max-width: 100%;
    font-size: 1.3em;
  }

  .aboutus_the_company .right_section {
    margin-top: 7.2em;
    position: relative;
    margin-right: 0;
  }

  /* .aboutus_the_company .right_section .fg_img {
    top: 6.5em;
  } */
  /* .aboutus_the_company .right_section .bg_img {
    top: 17em;
    position: absolute;
    bottom: 0;
  } */
  .aboutus_the_company .additional_content {
    margin-top: 55em;
  }

  /* .aboutus_the_company .additional_content h2 {
        font-size: 2.4em;
        line-height: 4.041em;
    }

    .aboutus_the_company .additional_content h2:before {
        max-width: 4em;
        min-height: 0.8em;
        border: 4px dashed #004083;
        top: 0;
        left: 7em;
    }

    .aboutus_the_company .additional_content p {
        margin: -1.5em 8.7em 0 0;
        font-size: 2em;
        line-height: 3.15em;
    }

    .aboutus_subsidiaries { padding-bottom: 12em; }
    .aboutus_subsidiaries .subsidiaries_slider { margin-top: 6.5em; }

    .aboutus_subsidiaries .navigations {
        top: unset;
        right: unset;
        bottom: 5em;
        left: 50%;
        transform: translateX(-50%);
    }

    .aboutus_subsidiaries .navigations button {
        font-size: 1.6em;
        line-height: 2.9375em;
    }

    .aboutus_subsidiaries .hollow_title {
        font-size: 6em;
        line-height: 1.567em;
    }

    .aboutus_subsidiaries .subsidiaries_heading p { display: none; } */

  .clients {
    padding-top: 0;
    margin-top: 40em;
  }

  .swiper-pagination {
    display: none;
  }

  .clients_slider_wrapper_heading_section .navigations {
    position: absolute;
    bottom: 5em;
    left: 50%;
    transform: translateX(-50%);
  }

  .clients h3 {
    margin-top: -0.5em;
    margin-bottom: 1.5em;
  }

  .clients_slider_wrapper ul {
    margin-top: 5em;
  }

  .aboutus_the_company .bg_img_mobile {
    display: block;
  }

  .aboutus_the_company .bg_img_desktop {
    display: none;
  }

  .aboutus_the_company .right_section .bg_img .bg_img_desktop {
    display: none;
  }

  .aboutus_the_company .right_section {
    margin-right: -2em;
    display: block;
  }

  .aboutus_the_company .right_section .bg_img {
    bottom: 0;
    /* max-width: 41.0625em; */
  }

  .aboutus_the_company .right_section .fg_img {
    /* max-width: 46.1875em; */
    margin-top: 12em;
  }
}

@media (max-width: 500px) {
  .aboutus_the_company .right_section .fg_img {
    max-width: 92%;
    left: 0;
  }

  .aboutus_the_company .right_section .bg_img {
    max-width: 80%;
  }
}

@media (max-width: 376px) {
  .clients {
    padding-top: 0;
    margin-top: 30em;
  }

  /* .aboutus_the_company .additional_content { margin-top: 50em; }

    .aboutus_the_company .additional_content h2:before {
        max-width: 4em;
        min-height: 0.8em;
        border: 4px dashed #004083;
        top: 0;
        left: 0.4em;
    }

    .aboutus_the_company .additional_content p { margin: -1.5em 1em 0 0; }
    .subsidiaries_slider { margin-left: 2em; }
    .aboutus_subsidiaries .subsidiaries_slider { margin-top: 3.5em; } */
  .aboutus_the_company .right_section {
    margin-right: 0em;
  }
}