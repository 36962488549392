/* Importing Common CSS File */
@import url("../../common.css");
.esg {
  padding: 2em 0 10em;
  margin-top: 8.5em;
  background-color: #f0f6fd;
}

.esg .hollow_title {
  line-height: 1em !important;
}

.esg .left_section {
  max-width: 50%;
  margin-top: 2em;
}

.esg_main_section .main_heading {
  font-size: 4.375em;
}

.esg .left_section h3 {
  margin-top: 0.5em;
}

.esg .left_section p {
  width: 100%;
  max-width: 65%;
  margin-top: 1.1875em;
}

.esg .bg_img {
  max-width: 50.375em;
  width: 100%;
  margin-top: 1em;
  float: right;
}

.esg_main_section {
  margin-top: 18.311875em;
}

.esg_main_section .left_section {
  max-width: 50.9375em;
  /* max-width: 100%; */
  width: 100%;
}

.esg_main_section .esg_first_main_section {
  display: flex;
  justify-content: space-between;
}

.esg_first_main_section .right_section {
  margin-top: 8em;
  flex-basis: 42%;
}
.esg_first_main_section .right_section p {
  margin-top: 0.5em;
  margin-bottom: 2em;
  width: 100%;
  max-width: 85%;
}

.esg_first_main_section .right_section figure {
  max-width: 28.9375em;
  width: 100%;
  margin-top: 8.0625em;
  float: right;
}

.esg_second_main_section {
  margin-top: 7.5625em;
}

.esg_second_main_section .esg_second_main_section_heading_and_desc {
  display: flex;
  justify-content: space-between;
}

.esg_second_main_section .esg_second_main_section_slider_wrapper {
  margin-top: 4.9375em;
}

.esg_second_main_section .esg_second_main_section_slider_wrapper figcaption {
  /* margin-top: 1em; */
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.375em;
  line-height: 2.72em;
}

.csr_section {
  margin-top: 8em;
  display: flex;
  justify-content: space-between;
}

.csr_section .left_section p {
  margin-top: 1.1875em;
}

.csr_section .right_section {
  margin-top: 3em;
  position: relative;
}

.csr_section .right_section .bg_img {
  max-width: 49.6875em;
  width: 100%;
}

.csr_section .right_section .fg_img {
  max-width: 23.75em;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: -8em;
}

.renuable_energy {
  margin-top: 21.4375em;
  position: relative;
  background-color: #002b5a;
}

.renuable_energy .wrapper {
  padding: 18.71875em 0 9.9375em;
}

.renuable_energy .wrapper .hollow_title {
  line-height: 0.925em;
  opacity: 0.4;
  -webkit-text-stroke-color: #fff;
}

.renuable_energy .content {
  display: flex;
  justify-content: space-between;
}

.renuable_energy .content p {
  margin-top: 6.53125em;
  color: #fff;
  opacity: 0.4;
}

.renuable_energy figure {
  max-width: 66.5em;
  width: 100%;
  position: absolute;
  top: -6em;
  right: 0;
}

.esg_mobile {
  display: none;
}
.esg_second_main_section .navigations {
  display: none;
}
.csr_section_desc_mobile {
  display: none;
}
.renuable_energy_mobile {
  display: none;
}
.content_mobile {
  display: none;
}
.esg_main_section_h3_mobile {
  display: none;
}
.esg_second_main_section_p_mobile {
  display: none;
}

.esg_second_main_section_slider_wrapper .navigations button {
  color: #1e5093 !important;
}
.esg_second_main_section_slider_wrapper .navigations .left_arrow::after {
  border: 2px dashed #1e5093;
}
.esg_second_main_section_slider_wrapper .navigations .right_arrow::before {
  border: 2px dashed #1e5093;
}
.esg_second_main_section_slider_wrapper .swiper-wrapper {
  align-items: center;
}

.left_section_hollow_box,
.right_section_hollow_box {
  max-width: 50.9375em;
  width: 100%;
  min-height: 41.9375em;
  /* background-color: #002B5A; */
}

/* .right_section_hollow_box_sanathan_reviro { background-color: #002B5A; } */

.esg_third_main_section,
.esg_fourth_main_section,
.esg_fifth_main_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.esg_third_main_section .mobile_heading {
  margin-bottom: 1.5em;
}

.esg_fifth_main_section .mobile_heading {
  margin-bottom: 1.5em;
}

.esg_third_main_section,
.esg_fourth_main_section,
.esg_fifth_main_section {
  margin-top: 5em;
}

.esg_third_main_section p,
.esg_fourth_main_section p,
.esg_fifth_main_section p {
  margin-top: 1.1875em;
}

.esg_fifth_main_section h4 {
  margin-top: 0.1em;
  font-family: "National-SemiBold";
  font-size: 1.875em;
  line-height: 2em;
}

.esg_fifth_main_section .right_section {
  max-width: 45em;
}

.new_renuable_energy {
  margin-top: 16.8125em;
  position: relative;
}

.new_renuable_energy .left_section {
  padding: 7.5em 10.9375em 6.75em 8.875em;
  background-color: #002b5a;
}

.new_renuable_energy .left_section h3 {
  color: #fff;
}

.new_renuable_energy .left_section p {
  /* margin-top: 2.3125em; */
  color: #fff;
}

.new_renuable_energy .right_section {
  max-width: 60em;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.esg_third_main_section_p_mobile {
  display: none;
}
.new_renuable_energy_mobile {
  display: none;
}

.new_renuable_energy_mobile .points_wrapper {
  counter-reset: list;
}
.new_renuable_energy_mobile .points_wrapper > li {
  display: flex;
  align-items: flex-start;
  list-style: none;
}
.new_renuable_energy_mobile .points_wrapper > li:before {
  content: counter(list, decimal) ". ";
  counter-increment: list;
  margin: 0.4em 0.25em 0 0;
  color: #fff;
}

.esg_fifth_main_section_p_mobile {
  display: none;
}
.left_section_hollow_box_third {
  width: 100%;
  max-width: 48%;
}
.right_section_hollow_box_third {
  width: 100%;
  max-width: 52%;
  padding-left: 11.188em;
}
.left_section_hollow_box iframe {
  width: 100%;
  height: 41.9375em;
}
.right_section_hollow_box iframe {
  width: 100%;
  height: 41.9375em;
}
.new_renuable_energy_p_desktop,
.new_renuable_energy_p_mobile {
  margin-top: 2.3125em;
}

.new_renuable_energy_desktop .points_wrapper {
  counter-reset: list;
}
.new_renuable_energy_desktop .points_wrapper > li {
  display: flex;
  align-items: flex-start;
  list-style: none;
}

.new_renuable_energy_desktop .points_wrapper > li:before {
  content: counter(list, decimal) ". ";
  counter-increment: list;
  margin: 0.2em 0.25em 0 0;
  color: #fff;
  font-size: 1.2em;
  line-height: 1.65em;
}

.new_renuable_energy_desktop ul {
  max-width: 63%;
  /* margin-left: 1em; */
}

.new_renuable_energy_desktop ul li {
  display: list-item;
  list-style-type: disc;
  color: #fff;
}

.esg_fourth_main_section_p_mobile {
  display: none;
}
.esg_fourth_main_section_mobile {
  display: none;
}
.esg_main_section_p_mobile {
  display: none;
}
.esg_mobile_wrapper {
  display: none;
}
.esg_fourth_desk {
  width: 100%;
  max-width: 80%;
}
.esg_third_main_section_p_desktop .new_link,
.esg_third_main_section_p_mobile .new_link {
  color: inherit;
  font-family: inherit;
  text-decoration: underline;
}
.esg_third_main_section_p_desktop .new_link:hover,
.esg_third_main_section_p_mobile .new_link:hover {
  font-weight: bold;
}

@media (max-width: 1918px) {
  .esg .bg_img {
    max-width: 48.375em;
  }
}

@media (max-width: 1280px) {
  .new_renuable_energy .right_section {
    max-width: 59.8em;
    bottom: 0;
  }
}

@media (max-width: 1194px) {
  .new_renuable_energy .left_section {
    width: 100%;
    max-width: 50%;
    padding: 7.5em 2em 6.75em 4em;
  }
  .new_renuable_energy .right_section {
    width: 100%;
    max-width: 50%;
    height: 100%;
  }
  .new_renuable_energy .right_section img {
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .esg .bg_img {
    max-width: 45.375em;
  }

  .left_section_hollow_box,
  .right_section_hollow_box {
    padding-right: 1.25em;
  }

  .esg_fifth_main_section .right_section {
    padding-left: 1.25em;
  }
}

@media (max-width: 991px) {
  .new_renuable_energy_desktop {
    display: none;
  }
  .new_renuable_energy .left_section {
    max-width: 100%;
    padding: 7em 2.5em;
  }
  .new_renuable_energy .right_section {
    max-width: 100%;
    position: static;
  }
  .new_renuable_energy_mobile {
    display: block;
  }
}

@media (max-width: 908px) {
  .esg .bg_img {
    max-width: 40.375em;
  }
}

@media (max-width: 834px) {
  .esg_desktop {
    display: none;
  }
  .esg_mobile {
    padding-bottom: 0;
    display: block;
  }
  .esg_mobile .left_section {
    max-width: 100%;
  }

  .esg_mobile .bg_img {
    max-width: 100%;
    float: none;
  }

  .esg_mobile .left_section p {
    max-width: 100%;
    margin-top: 2.5em;
  }
  .esg_main_section {
    margin-top: 10em;
  }

  .esg_main_section .esg_first_main_section {
    flex-flow: column;
  }
  .esg_main_section .left_section {
    max-width: 100%;
  }

  .esg_first_main_section .right_section {
    margin-top: 3.6em;
    text-align: left;
  }

  .esg_first_main_section .right_section figure {
    float: none;
  }
  .esg_second_main_section_heading_and_desc {
    flex-flow: column;
  }
  .esg_second_main_section_heading_and_desc p {
    margin-top: 1em;
  }

  .esg_second_main_section .navigations {
    display: block;
  }
  .esg_second_main_section_slider_wrapper {
    padding-bottom: 10em;
  }

  .esg_second_main_section_slider_wrapper .navigations {
    margin-top: 3em;
    position: absolute;
    bottom: 5em;
    left: 50%;
    transform: translateX(-50%);
  }

  .csr_section {
    margin-top: 0;
    flex-flow: column;
  }
  .csr_section_desc_mobile {
    display: block;
  }
  .csr_section_desc_desktop {
    display: none;
  }

  .csr_section .right_section {
    margin-top: 5.4em;
  }

  .csr_section .right_section .bg_img {
    max-width: 62em;
    margin-left: 9em;
  }

  .csr_section .right_section .fg_img {
    max-width: 30em;
    bottom: -10em;
    left: 0;
  }

  .renuable_energy .wrapper {
    padding-top: 7em;
  }
  .renuable_energy_mobile {
    display: block;
  }
  .renuable_energy_desktop {
    display: none;
  }
  .renuable_energy figure {
    max-width: 100%;
    margin-top: 3.85em;
    position: static;
  }
  .renuable_energy_mobile .content_mobile {
    flex-flow: column;
  }
  .renuable_energy .content p {
    margin-top: 3em;
  }
  .renuable_energy .content p:last-child {
    margin-top: 2em;
  }

  .renuable_energy_mobile .content_mobile {
    display: block;
  }
  .renuable_energy_mobile .content_desktop {
    display: none;
  }

  .esg_second_main_section .esg_second_main_section_slider_wrapper figcaption {
    margin-top: 0;
  }

  .esg_main_section_h3_mobile {
    display: none;
  }
  .esg_main_section_h3_desktop {
    display: block;
  }

  .esg_second_main_section_p_mobile {
    display: none;
  }
  .esg_second_main_section_p_desktop {
    display: block;
  }

  .dash_wwrapper {
    margin-top: -1em;
  }
  .dash {
    width: 9em;
  }

  .left_section_hollow_box,
  .right_section_hollow_box {
    max-width: 100%;
  }

  .esg_third_main_section {
    flex-flow: column;
    align-items: flex-start;
  }
  .esg_third_main_section_p_mobile {
    display: block;
  }
  .esg_third_main_section_p_desktop {
    display: none;
  }
  .esg_third_main_section .right_section {
    margin-top: 4em;
  }

  .esg_fourth_main_section {
    flex-flow: column;
    align-items: flex-start;
  }
  .esg_fourth_main_section_p_mobile {
    display: block;
  }
  .esg_fourth_main_section_p_desktop {
    display: none;
  }
  .esg_fourth_main_section .right_section_hollow_box {
    margin-top: 4em;
  }

  .esg_fifth_main_section {
    flex-flow: column;
    align-items: flex-start;
  }
  .esg_fifth_main_section_p_mobile {
    display: block;
  }
  .esg_fifth_main_section_p_desktop {
    display: none;
  }
  .esg_fifth_main_section .right_section {
    max-width: 100%;
    margin-top: 6em;
  }

  .new_renuable_energy .right_section {
    position: static;
    max-width: 100%;
  }

  .new_renuable_energy .right_section .hollow_title {
    line-height: 1.5em;
  }

  .new_renuable_energy_desktop {
    display: none;
  }
  .new_renuable_energy_mobile {
    display: block;
  }
  .new_renuable_energy_mobile .left_section {
    padding: 7em 2.5em;
  }

  .new_renuable_energy_mobile ul {
    margin-left: 1em;
  }
  .new_renuable_energy_mobile ul li {
    display: list-item;
    list-style-type: disc;
    color: #fff;
  }

  .esg_fourth_main_section_mobile {
    display: block;
  }
  .esg_fourth_main_section_desktop {
    display: none;
  }
  .esg_fourth_main_section_mobile .left_section {
    margin-top: 4em;
  }

  .esg_main_section_p_mobile {
    display: block;
  }
  .esg_main_section_p_desktop {
    display: none;
  }

  .esg_mobile {
    padding-bottom: 75em;
  }
  .esg_mobile_wrapper {
    margin-top: -73em;
    display: block;
  }

  .esg_mobile_wrapper p {
    margin-top: 2em;
  }

  .left_section_hollow_box,
  .right_section_hollow_box {
    padding-right: 0;
  }

  .esg_fifth_main_section .right_section {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .esg_fifth_main_section .right_section {
    margin-top: 2.9em;
  }
  .esg_fifth_main_section .right_section .hollow_title {
    line-height: 1;
  }
  .esg_first_main_section .right_section p {
    max-width: 100%;
  }
  .left_section_hollow_box_third {
    max-width: 100%;
  }
  .right_section_hollow_box_third {
    max-width: 100%;
    padding-left: 0;
  }
  .enviro_h3 {
    margin-bottom: 1em;
  }
  .mb_hollow_title {
    line-height: 0;
    margin-top: 0.7em;
  }
  .mb_dash_wrapper {
    margin: 1em 0;
  }

  .esg .hollow_title {
    line-height: 1.1em !important;
  }

  .new_renuable_energy_mobile .points_wrapper > li:before {
    margin: 0.2em 0.25em 0 0;
    font-size: 1.25em;
  }
}
@media (max-width: 450px) {
  .esg_main_section {
    margin-top: 5em;
  }
  .esg_main_section .right_section {
    margin-top: 0em;
  }
  .esg_main_section_h3_mobile {
    display: block;
  }
  .esg_main_section_h3_desktop {
    display: none;
  }
  .esg_first_main_section .right_section figure {
    margin-top: 1em;
    max-width: 100%;
  }
  .renuable_energy_mobile {
    margin-top: 9em;
  }
  .esg_first_main_section .right_section {
    display: block;
  }
  .esg_second_main_section_p_mobile {
    display: block;
  }
  .esg_second_main_section_p_desktop {
    display: none;
  }
  .csr_section .right_section {
    display: block;
  }
  .csr_section .right_section .fg_img {
    max-width: 15em;
    bottom: -5em;
  }
  .csr_section .right_section .bg_img {
    max-width: 30em;
    margin-left: 3em;
  }

  .new_renuable_energy_mobile .left_section {
    padding: 5em 2.1em 5em 3.15em;
  }
  .new_renuable_energy {
    margin-top: 5em;
  }

  .esg_mobile {
    padding-bottom: 47em;
  }
  .esg_mobile_wrapper {
    margin-top: -45em;
    display: block;
  }

  .esg_mobile_wrapper p {
    margin-top: 1em;
  }
  .esg_third_main_section {
    margin-top: 5em;
  }
  .esg_third_main_section .mobile_heading {
    margin-bottom: 0.5em;
  }
  .esg_fourth_main_section .right_section_hollow_box {
    margin-top: 1em;
  }
  .esg_fourth_main_section_mobile .left_section {
    margin-top: 0em;
  }
  .esg_fifth_main_section .mobile_heading {
    margin-bottom: 0.5em;
  }
  .esg_fifth_main_section .hollow_title {
    font-size: 5em;
    line-height: 1.3em;
  }
  .esg_fifth_main_section p {
    margin-top: 1em;
  }
  .new_renuable_energy_mobile ul {
    margin-left: 0;
  }
}
@media (max-width: 380px) {
  .new_renuable_energy_mobile .left_section {
    padding: 5em 2.1em;
  }
}
