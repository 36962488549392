@import url("../../common.css");

.banner {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("../../images/Home/Banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
  .wrapper {
    padding: 22.065em 0 38.065em 0;
    position: relative;
  }
  h2,
  .h2 {
    color: #fff;
    font-family: "National-Normal";
    font-size: 5em;
    line-height: 1.2em;
    overflow: hidden;
    text-transform: capitalize;
    &:last-child {
      overflow: hidden;
      height: 85px;
    }
  }

  ul {
    margin-top: 0;
    text-align: center;
    list-style: none;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
  li {
    // line-height: 100px;
    margin: 0;
    display: block;
    text-align: left;
  }
  .left_section {
    display: inline-block;
    .style_heading_of_banner_content {
      font-family: "National-SemiBold";
      // min-height: 1.5em;
      // margin-top: -0.3em;
      // margin-bottom: 0.3em;
    }
  }
  .text_slider_wrapper {
    max-height: 1.2em;
    vertical-align: center;
    .swiper-slide {
      transition: transform 3s ease-in-out;
    }
  }
  .custom_size {
    font-size: 4em;
  }
}
.banner_right_section {
  max-width: 68em;
  width: 100%;
  position: absolute;
  top: 16em;
  right: 5em;
  .main_slider_wrapper {
    position: relative;
  }
  .second_slider {
    max-width: 52.25em;
    width: 100%;
    min-height: 53.313em;
    margin-right: 5em;
    position: absolute;
    top: 0;
    right: 0;
    a {
      margin-top: 1em;
      position: relative;
      color: #002b5a;
      font-family: "National-Medium";
      font-size: 1.375em;
      line-height: 2.77em;
      text-decoration: none;
      text-transform: uppercase;
      margin-top: 0.2em;
      color: #fff;
    }
    .products_link {
      text-transform: none;
      font-size: inherit;
      line-height: inherit;
      margin: 0;
      display: inline-block;
      padding: 8.6875em 5.375em 14.6875em 5.4375em;
      min-height: 53.313em;
    }
    .swiper-pagination2 .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 7em;
    }
    .swiper-pagination2 {
      text-align: center;
      position: absolute;
      z-index: 9;
      .swiper-pagination-bullet {
        background: #fff;
        opacity: 0.4;
      }
      .swiper-pagination-bullet-active {
        color: #fff;
        background-color: #fff;
        border: 5px #fff;
        opacity: 1;
      }
    }
    .second_slider_wrapper_content_heading {
      color: #fff;
      font-family: "National-Normal";
      font-size: 3.5em;
      line-height: 1.1875em;
      .puro_icon {
        max-width: 2.1em;
        display: block;
        margin-bottom: 0.1em;
      }
      .borndyed_icon {
        max-width: 15px;
        margin-bottom: 0.2em;
      }
      .revir_icon {
        max-width: 5em;
        margin: 0 0 0.3em 0.1em;
      }
      .dry_cool_icon {
        max-width: 5em;
        margin: 0 0 0.3em 0.1em;
      }
    }
    .second_slider_wrapper_content_description {
      margin-top: 1.5em;
      color: #fff;
      font-family: "National-Light";
      font-size: 1.25em;
      line-height: 1.5em;
    }
  }
}

.home_sec2 {
  .home_sec2_fifth_row_slider_content {
    ul {
      li {
        p {
          font-size: 1.25em !important;
        }
      }
    }
  }
  .home_sec2_fifth_row_swiper_wrapper {
    .animate_wrapper {
      padding-bottom: 1em;
    }
    // .animate:hover {
    //   img {
    //     transform: scale(1.1);
    //     transition: all 0.3s ease-out;
    //   }
    // }
  }
}

.info_container {
  padding: 5em 0 0;
  .info_title_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .info_title {
      color: #1c1c1c;
      font-size: 2.28em;
      line-height: normal;
    }
    .down_arrow {
      font-size: 2em;
    }
    .down_arrow.active {
      transform: rotate(180deg);
    }
  }
  .info_desc {
    color: #1c1c1c;
    font-size: 1.5em;
    line-height: normal !important;
    height: 0;
    // overflow: hidden;
    display: none;
    transition: height 0.01s ease;
    padding-top: 0.75em;
  }
  .info_desc.show {
    height: auto;
    display: block;
  }
}
@media (max-width: 1600px) {
  .banner h2:last-child {
    margin-top: 0.3em;
  }
}

@media (max-width: 1367px) {
  .banner h2:last-child {
    height: 100px;
  }
}

@media (max-width: 1280px) {
  .banner h2:last-child {
    height: 70px;
  }
}

@media (max-width: 1024px) {
  .banner_right_section {
    .second_slider {
      margin-right: 0;
    }
  }
}

@media (max-width: 991px) {
  .banner_right_section {
    .second_slider {
      max-width: 90%;
      left: 10%;
      right: initial;
      margin-top: 33em;
      margin: 33em auto 0;
      // .second_slider_wrapper_content,
      // .second_slider_wrapper_content1,
      // .second_slider_wrapper_content2,
      // .second_slider_wrapper_content3,
      // .second_slider_wrapper_content4,
      // .second_slider_wrapper_content5 {
      //   padding: 8.6875em 4.375em 20.5em 3.4375em;
      //   max-height: 38.313em;
      //   min-height: 21.75em;
      //   margin: 0 auto;
      // }

      .products_link {
        padding: 8.6875em 4.375em 20.5em 3.4375em;
        max-height: 57em;
        min-height: 57em;
        margin: 0 auto;
      }
    }
  }

  .banner {
    .wrapper {
      .left_section {
        display: block;
        text-align: center;
      }
    }
    li {
      text-align: center;
    }
  }
}

@media (max-width: 835px) {
  .info_container {
    padding: 5em 2em 0;
  }
}

@media (max-width: 768px) {
  .banner {
    height: 100%;
    .wrapper {
      padding: 14.065em 0 38.065em 0;
      .left_section {
        display: block;
        text-align: center;
        h2,
        .h2 {
          height: unset;
          margin: 0 auto;
          font-size: 3.6em;
          line-height: 1.2em;
          &:last-child {
            height: 50px;
          }
        }
        ul li {
          text-align: center;
          line-height: 45px;
        }
      }
    }
  }
  .banner_right_section {
    .second_slider {
      max-width: 95%;
      margin-right: 0;
      // left: 15.7%;
      left: 5%;
      margin-top: 20em;
      a {
        margin-top: 0;
        font-size: 1.4em;
        line-height: 3em;
      }

      // .second_slider_wrapper_content2 {
      //   padding: 8.6875em 4.375em 20.5em 3.4375em;
      //   max-height: 38.313em;
      //   min-height: 21.75em;
      // }
      // .second_slider_wrapper_content,
      // .second_slider_wrapper_content1,
      // .second_slider_wrapper_content3,
      // .second_slider_wrapper_content4,
      // .second_slider_wrapper_content5 {
      //   padding: 5.6875em 4.375em 20.5em 3.4375em;
      //   max-height: 38.313em;
      //   min-height: 21.75em;
      // }
      .products_link {
        padding: 5.6875em 4.375em 20.5em 3.4375em;
        max-height: 57em;
        min-height: 57em;
      }

      .swiper-pagination2 {
        display: none;
      }
    }
    .second_slider_wrapper_content_heading {
      .borndyed_icon {
        max-width: 7px;
      }
      .revir_icon {
        max-width: 5em;
      }
      .dry_cool_Product_icon {
        max-width: 5em;
      }
    }
  }
  .home_sec2 {
    // margin-top: 0;
    .wrapper {
      // padding: 9.5625em 0 0em;
      .home_sec2_first_row_desc {
        text-align: left;
        margin-bottom: 8em;
      }
    }
    .home_sec2_second_row_content {
      li:not(:first-child) {
        margin-top: 3.7em;
      }
      li:nth-child(3) {
        text-align: center;
      }
      li:nth-child(3) h5 {
        margin-bottom: 0;
      }
      li:nth-child(1) h5 {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .info_container {
    padding: 5em 0 0;
    .info_title_wrapper {
      .info_title {
        font-size: 2.4em;
      }
      .down_arrow {
        font-size: 2em;
      }
    }
    .info_desc {
      font-size: 1.5em;
    }
  }

  .banner_right_section {
    .second_slider {
      left: 15.7%;
      right: 0;
      margin: 20em 0 0;
    }
  }
}

@media (max-width: 451px) {
  .banner_right_section {
    .second_slider {
      .products_link {
        max-height: 45em;
        min-height: 45em;
      }
    }
  }
}

// @keyframes scale {
//   50% {
//     -webkit-transform: scale(1.05);
//     -moz-transform: scale(1.05);
//     -ms-transform: scale(1.05);
//     -o-transform: scale(1.05);
//     transform: scale(1.05);
//   }
// }

// @keyframes change {
//   0%,
//   12.66%,
//   100% {
//     transform: translateY(0);
//   }
//   16.66%,
//   29.32% {
//     transform: translateY(-25%);
//   }
//   33.32%,
//   45.98% {
//     transform: translateY(-50%);
//   }
//   49.98%,
//   62.64% {
//     transform: translateY(-75%);
//   }
//   66.64%,
//   79.3% {
//     transform: translateY(-50%);
//   }
//   83.3%,
//   95.96% {
//     transform: translateY(-25%);
//   }
// }
