/* Importing Common CSS File */
@import url("../../common.css");
.our_setup_certifications {
  padding: 2em 0 7.7em;
  margin-top: 8.8em;
  /* position: relative; */
  background-color: #f3f6fa;
}

.our_setup_certifications .wrapper {
  position: relative;
}
.our_setup_certifications .left_section {
  max-width: 50%;
}
.our_setup_certifications .left_section h3 {
  margin-top: 0.5em;
}

.our_setup_certifications .left_section p {
  max-width: 80%;
  margin-top: 1.1875em;
}

.our_setup_certifications .right_section .bg_img {
  max-width: 24em;
  position: absolute;
  bottom: -25em;
  right: 26em;
}

.our_setup_certifications .right_section .fg_img {
  max-width: 45.375em;
  position: absolute;
  top: 13em;
  right: 0;
  transform: translateY(-30%);
}

.certifications_slider_wrapper {
  margin-top: 27.28125em;
}

.certifications_slider_heading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.certifications_slider_heading_section .navigations {
  margin-top: 3em;
}

.certifications_slider_wrapper li figure {
  max-width: 28.8125em;
  width: 100%;
}

.certifications_slider_wrapper .navigations button {
  color: #1e5093 !important;
}
.certifications_slider_wrapper .navigations .left_arrow:after {
  border: 2px dashed #1e5093;
}
.certifications_slider_wrapper .navigations .right_arrow::before {
  border: 2px dashed #1e5093;
}

.certifications_slider_wrapper figcaption {
  margin-top: 1.5em;
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.875em;
  line-height: 1.2em;
}

.certifications_slider_heading_section_mobile {
  display: none;
}

.cerfitications_main_slider_wrapper h3 {
  font-size: 2.25em;
  line-height: 1.194em;
  text-transform: none;
}

.certifications_slider_wrapper ul {
  margin-top: 2em;
}
.certifications_slider_heading_section_mobile {
  display: none;
}
.certifications_slider_navigation_heading_section_mobile {
  display: none;
}

.certificates_modal .btn-close {
  margin-top: 1em;
  margin-bottom: 1em;
  float: right;
  display: inline-block;
  /* float: right; */
  /* margin: 0; */
  background-color: #002b5a;
  border: 0;
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
}
.cert_close.certificates_modal .btn-close {
  background-color: #002b5a;
}
.certificates_modal .btn-close .icon-cross {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 24px;
  height: 40px;
}
.certificates_modal .btn-close .icon-cross:before,
.certificates_modal .btn-close .icon-cross:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
  border-radius: 6px;
}
.certificates_modal .btn-close .icon-cross:before {
  transform: rotate(45deg);
}
.certificates_modal .btn-close .icon-cross:after {
  transform: rotate(-45deg);
}
.certificates_modal .btn-close .icon-cross span {
  display: block;
}
.btn-close:hover,
.btn-close:focus {
  transform: rotateZ(90deg);
  /* background: #0052cc; */
}

.certificates_modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 43, 90, 1);
  overflow: hidden;
  z-index: 100;
}

.certificates_modal_popup {
  max-width: 40.5625em;
  width: 100%;
  min-height: 26.4375em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  text-align: center;
  z-index: 1;
}

.certificates_modal_popup .close_button {
  width: 90%;
  margin: 0 auto;
  text-align: right;
}

.certificates_modal .modal_data {
  padding: 4em 2em;
}
.cerfitications_main_slider_wrapper li {
  cursor: pointer;
}

.Certification {
  margin-top: 0.5em;
  font-family: "National-Medium";
font-size: 2.28em;
line-height: 1.2em;
}

.hollow_title {
  font-size: 8.75em !important;
line-height: 1.3em !important;
  margin: 0 !important;
  padding: 0;
  max-width: 100% !important;
}

@media (max-width: 1920px) {
  .certifications_slider_wrapper {
    margin-left: 6.5em;
  }
  .certifications_slider_heading_section {
    max-width: 1720px;
  }
}
@media screen and (min-width: 1460px) and (max-width: 1550px) {
  .certificates_modal_popup {
    height: 48em;
  }
  .certificates_modal_popup {
    height: 40em;
    overflow-y: scroll;
  }
  .certificates_modal_popup::-webkit-scrollbar {
    width: 0.75em;
    cursor: pointer;
  }

  .certificates_modal_popup::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .certificates_modal_popup::-webkit-scrollbar-thumb {
    border-radius: 2em;
    cursor: pointer;
    background-color: #bbb4b4;
  }
}
@media (max-width: 1536px) {
  .certifications_slider_heading_section {
    max-width: 1370px;
  }
  .certifications_slider_wrapper {
    margin-left: 7em;
  }
  .fleet_of_factory .navigations {
    right: 2em;
    bottom: -36em;
  }
}

@media (max-width: 1480px) {
  /* .certifications_slider_heading_section { max-width: 1290px; } */
  .certifications_slider_wrapper {
    margin-left: 4.5em;
  }
  .fleet_of_factory .navigations {
    right: 0;
    bottom: -59em;
  }
}

@media (max-width: 1367px) {
  .certifications_slider_heading_section {
    max-width: 1290px;
  }
  .certifications_slider_wrapper {
    margin-left: 3em;
  }
  .fleet_of_factory .navigations {
    right: 0;
    bottom: -63em;
  }
}

@media (max-width: 1280px) {
  .certifications_slider_heading_section {
    max-width: 1146.67px;
  }
  .certifications_slider_wrapper {
    margin-left: 6.5em;
  }
  .fleet_of_factory .navigations {
    right: 0;
    bottom: -56em;
  }
}

@media (max-width: 1195px) {
  .certifications_slider_heading_section {
    max-width: 1127.67px;
  }
  .certifications_slider_wrapper {
    margin-left: 4em;
  }
  .fleet_of_factory .navigations {
    right: 0;
    bottom: -61em;
  }
}

@media (max-width: 786px) {
  .our_setup_certifications .left_section {
    max-width: 100%;
  }
  .our_setup_certifications .left_section p {
    max-width: 100%;
  }

  .our_setup_certifications .right_section {
    max-width: 100%;
    margin-top: 5.3em;
  }

  .our_setup_certifications .right_section .fg_img {
    max-width: 100%;
    /* margin-top: 5.3em; */
    position: absolute;
    top: 0;
    transform: none;
  }

  .our_setup_certifications .right_section .bg_img {
    max-width: 24em;
    position: relative;
    bottom: -60em;
    left: -3em;
  }

  .certifications_slider_wrapper {
    margin: 70em 0 0 2em;
  }

  .certifications_slider_heading_section_desktop {
    display: none;
  }

  .certifications_slider_heading_section {
    text-align: center;
  }

  .certifications_slider_heading_section_mobile {
    padding: 0.5em 1em 0.5em 0;
    margin-top: 1em;
    display: block;
    position: static;
    text-align: left;
    transform: none;
  }

  .our_setup_certifications .right_section {
    text-align: right;
    margin-right: -12px;
    position: relative;
  }
  .certifications_slider_navigation_heading_section_mobile {
    padding: 2em 0 1em;
    margin-top: 1em;
    display: block;
    text-align: center;
  }

  /* .certifications_slider_heading_section { justify-content: flex-start; } */
}
@media (max-width: 768px) {
  .certificates_modal_popup {
    max-width: 31.5625em;
  }
  .hollow_title {
    font-size: 5em !important;
    line-height: 1.88em !important;
  }
}

@media (max-width: 450px) {
  .our_setup_certifications .right_section {
    display: block;
  }
  .our_setup_certifications .right_section .bg_img {
    bottom: -15em;
  }
  .certifications_slider_wrapper {
    margin: 29em 0 0 2em;
  }
  .certifications_slider_navigation_heading_section_mobile {
    margin-right: 2em;
  }
  /* .certifications_slider_wrapper li { width: 100%; } */
  .certifications_slider_wrapper .hollow_title {
    line-height: 1.2em;
    text-align: center;
  }
  .certifications_slider_heading_section_mobile {
    transform: none;
  }
  .our_setup_certifications .right_section .bg_img {
    max-width: 15em;
    bottom: -23em;
  }

  .certifications_slider_heading_section_mobile {
    display: block;
  }
  .certifications_slider_heading_section_desktop {
    display: none;
  }
}
@media (max-width: 375px) {
}
