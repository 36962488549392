.investor_relations {
  .mobile_section {
    display: none;
  }
}

.products_popup {
  // height: 40em;
  overflow-y: scroll;
  overflow-x: hidden;
}
.products_popup::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
}

.products_popup::-webkit-scrollbar-track {
  background-color: transparent;
}
.products_popup::-webkit-scrollbar-thumb {
  border-radius: 2em;
  cursor: pointer;
  background-color: #bbb4b4;
}

.pdf_modal_wrapper2 {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 43, 90, 0.2);
  width: 100%;
  height: 100%;
  z-index: 100;
  .products_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 85%;
    background-color: #fff;
    padding: 4em 2em;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0.75em;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2em;
      cursor: pointer;
      background-color: #bbb4b4;
    }
    h3 {
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 1em;
      font-family: "National-Medium";
      font-size: 3.125em;
      line-height: 1.2em;
    }
    .pdf_modal_cross2 {
      position: absolute;
      top: 1em;
      right: 1em;
      width: 100%;
      max-width: 3.25em;
      cursor: pointer;
    }
    .pdf_flex2 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .pdf_flex2 {
      flex-wrap: wrap;
      margin: 0 9em;
      .pdf_content2 {
        width: 100%;
        max-width: 32%;
        text-align: left;
        // background-color: aquamarine;
        padding: 1em;
        margin-bottom: 2em;
        margin-right: 1em;

        // border-radius: 5px;
        // -moz-box-shadow: 0px 0px 0px 3px rgba(0, 43, 90, 0.2);
        // -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 43, 90, 0.2);
        // box-shadow: 0px 0px 0px 3px rgba(0, 43, 90, 0.2);
      }
      .no_shadow {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}
// vijay css start
.other_information{
  .salesEnquiry{
    min-height: 37em;
    .form_wrapper{
      margin-top: 2em;
    }
    .sanathan_btn{
      padding-left: 0;
    }
  }
}
.flexbox_new{
  flex-wrap: wrap;
  .num1{
    margin-right: 0;
  }
  .fi_numsdesc{
    width: 100%;
    max-width: 95%;
  }
  .pdf_box{
    width: 100%;
    max-width: 30%;
   padding-left: 5em;
  }
  .pdf_box:nth-child(3n+1){
    padding-left: 0;
  }
  .pdf_box:nth-child(n+4){
    margin-top: 2.8em;
  }
  .pdf_box_4{
    .btn_arrow{
      max-width: 8.5em;
    }
  }
}
// vijay css end






@media (max-width: 767px) {
  .investor_relations {
    .mobile_section {
      display: block;
    }
    .right_section {
      display: none;
    }
  }
  .pdf_modal_wrapper2 {
    .products_popup {
      .pdf_flex2 {
        flex-direction: column;
        max-width: 100%;
        margin: 0;
      }
    }
    .pdf_content2 {
      max-width: 100% !important;
    }
    .pdf_content2:last-child {
      display: none;
    }
  }

  // vijay css start
  .flexbox_new {
    .pdf_box{
      max-width: 100%;
      padding-left: 0;
    }
    .fi_numsdesc{
      max-width: 100%;
    }
    .pdf_box:nth-child(n+4){
      margin-top: 2em;
    }
  }
  // vijay css end

}
@media (max-width: 450px) {
  // vijay css start
  .other_information{
    .salesEnquiry{
      max-width: 34em;
    }
  }
  // vijay css end
}