/* Importing Common CSS File */
@import url("../../common.css");
.newsroom {
  padding: 2em 0 20em;
  margin-top: 8.5em;
  background-color: #f0f6fd;
  .left_section {
    max-width: 50%;
    margin-top: 2em;
    p {
      max-width: 39.25em;
      margin-top: 1.1875em;
    }
  }
  .bg_img {
    max-width: 45.375em;
    width: 100%;
    margin-top: 1em;
    float: right;
  }
}

.bg_img_mobile {
  display: none;
}

.article {
  margin-top: 12.343125em;
  .article_slider_wrapper {
    margin-top: 0;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        flex-basis: 30%;
        margin-right: 2.5em;
        margin-left: 1em;
        margin-top: 3.5em;
        // &:not(:first-child) {
        //   margin-left: 1em;
        // }
        &:nth-child(3n+1) {
          margin-left: 0;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }
  li {
    position: relative;
  }
  .article_content {
    padding: 0 6.125em 0 2.0625em;
    position: absolute;
    bottom: 2.5em;
  }
  span {
    color: #fff;
    font-family: "National-Light";
    font-size: 1.25em;
    line-height: 1.4em;
    opacity: 0.7;
  }
  p {
    color: #fff;
    font-family: "National-Normal";
    font-size: 1.625em;
    line-height: 1.23em;
  }
}

.photos {
  margin-top: 4.4375em;
  .photos_slider_heading_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin-top: -0.5em;
    }
  }
  .photos_slider_wrapper {
    .navigations {
      margin-top: 4em;
      button {
        color: #1e5093 !important;
      }
      .left_arrow::after {
        border: 2px dashed #1e5093;
      }
      .right_arrow::before {
        border: 2px dashed #1e5093;
        left: -1.5em;
      }
    }
    ul {
      margin-top: 2em;
    }
  }
  .navigations_mobile {
    display: none;
  }
}

@media (max-width: 1920px) {
  .photos {
    .photos_slider_wrapper {
      margin-left: 6em;
    }
    .photos_slider_heading_section {
      max-width: 1720px;
    }
  }
}

@media (max-width: 1536px) {
  .photos {
    .photos_slider_wrapper {
      margin-left: 6.5em;
    }
    .photos_slider_heading_section {
      max-width: 1370px;
    }
  }
}

@media (max-width: 1480px) {
  .photos {
    .photos_slider_wrapper {
      margin-left: 4.5em;
    }
  }
}

@media (max-width: 1367px) {
  .photos {
    .photos_slider_wrapper {
      margin-left: 3em;
    }
    .photos_slider_heading_section {
      max-width: 1290px;
    }
  }
}

@media (max-width: 1280px) {
  .photos {
    .photos_slider_wrapper {
      margin-left: 6em;
    }
    .photos_slider_heading_section {
      max-width: 1156.67px;
    }
  }
}

@media (max-width: 1195px) {
  .photos {
    .photos_slider_wrapper {
      margin-left: 4em;
    }
    .photos_slider_heading_section {
      max-width: 1156.67px;
    }
  }
}

@media (max-width: 834px) {
  .newsroom {
    padding-bottom: 50em;
    .left_section {
      max-width: 100%;
      margin-top: -2em;
    }
    .bg_img {
      max-width: 100%;
      margin-top: 5.5em;
      float: none;
    }
  }
  .bg_img_mobile {
    margin-top: -45em;
    display: block;
  }
  .bg_img_desktop {
    display: none;
  }

  .article {
    margin-top: 4.85em;
    .article_slider_wrapper {
      ul {
        justify-content: space-between;
        li {
          flex-basis: 45%;
          margin-right: 0;
          &:not(:first-child) {
            margin-left: 0;
          }
          &:nth-child(n + 2) {
            margin-top: 5em;
          }
        }
      }
    }
    .navigations_mobile {
      display: flex;
    }
    .navigations_desktop {
      display: none;
    }
  }

  .photos {
    .navigations {
      margin-top: 3em;
      position: static;
      transform: none;
      text-align: center;
      button {
        color: #1e5093 !important;
      }
      .left_arrow::after {
        border: 2px dashed #1e5093;
      }
      .right_arrow::before {
        border: 2px dashed #1e5093;
        left: -2em;
      }
    }
  }
}

@media (max-width: 450px) {
  .newsroom {
    padding-bottom: 30em;
  }

  .bg_img_mobile {
    margin-top: -27em;
  }

  .article {
    .article_slider_wrapper {
      ul {
        li {
          flex-basis: 100%;
        }
      }
    }
  }

  .photos {
    .navigations {
      position: static;
      display: flex;
      button {
        color: #1e5093 !important;
      }
      .left_arrow::after {
        border: 2px dashed #1e5093;
      }
      transform: none;
      .right_arrow::before {
        border: 2px dashed #1e5093;
        left: -2em;
      }
    }
  }
}
