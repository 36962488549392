.annual_rep_row {
  margin: 0 auto;
  column-gap: 1.875em;
  .annual_rep_col {
    padding: 0;
    max-width: calc(33.33% - 1.4em);
    margin-bottom: 2.5em;
    .annual_data_box {
      .title {
        color: #012b5b;
        font-size: 2.5em;
        font-family: "National-Normal";
        letter-spacing: 0px;
        line-height: 1.2;
        padding-top: 0.5em;
      }
    }
  }
}

@media (max-width: 991px) {
  .annual_rep_row {
    column-gap: 0;
    .annual_rep_col {
      max-width: 50%;
      &:nth-child(2n + 1) {
        padding-right: 1em;
      }
      &:nth-child(2n + 2) {
        padding-left: 1em;
      }
      .annual_data_box {
        .title {
          font-size: 2.2em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .annual_rep_row {
    column-gap: 0;
    .annual_rep_col {
      max-width: 50%;
      margin-bottom: 2em;
      &:nth-child(2n + 1) {
        padding-right: 0.7em;
      }
      &:nth-child(2n + 2) {
        padding-left: 0.7em;
      }
      .annual_data_box {
        .title {
          font-size: 2.2em;
          letter-spacing: 0px;
          line-height: 1.227;
          padding-top: 0.546em;
        }
      }
    }
  }
}
