.products_inner_listing {
  .tech_btn {
    visibility: hidden;
  }
}
.sflex_sec3 {
  // margin-top: 10em;
  margin-bottom: 4.188em;
  h3 {
    width: 100%;
    max-width: 70%;
  }
  ul {
    //   display:inline-flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    li {
      padding: 2em 1em 0 0;
      // padding: 1em 0 0 0;
      text-align: left;
      font: normal normal 300 20px/33px National;
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      display: block;
      padding-left: 1em;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 2.5em;
        height: 0.5em;
        width: 0.5em;
        // margin: 0.2em;
        // margin-right: 0.4em;
        background-color: #064f9e;
        border-radius: 50%;
      }
    }
  }
}

.left_section {
  .yarns {
    margin-top: 0.5em;
    font-family: "National-Medium";
    font-size: 2.28em;
    line-height: 1.2em;
  }
  .new_link {
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
  .drycool_img {
    width: 45%;
    margin-bottom: 0.37em;
    margin-top: 3em;
  }
}
.products_inner_watch_now {
  // .video_section {
  //   display: inline-block;
  //   // max-width: 41.6875em;
  //   width: 100%;
  //   position: relative;
  //   .playicon {
  //     max-width: 4.375em;
  //     width: 100%;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     cursor: pointer;
  //     transform: translate(-50%, -50%);
  //   }
  // }
}

.video_container {
  .video_details {
    width: 100%;
    max-width: 100%;
    height: 42.5em;
    // height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.sflex_sec4 {
  padding-top: 2.0625em;
  .title {
    margin-bottom: 1.096em;
  }
  .app_row {
    margin: 0 auto;
    column-gap: 1.875em;
    .app_col {
      padding: 0;
      margin-bottom: 3em;
      max-width: 23.5%;
      .img_wrapper {
        width: 100%;
        max-width: 100%;
        border: 1px solid rgba($color: #000000, $alpha: 0.5);
        padding: 0 2em;
        overflow: hidden;
        &:hover {
          .app_img {
            transform: scale(1.15);
            transition: 0.5s ease;
          }
        }
      }
      .app_img {
        width: 100%;
        // max-width: 18.75em;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        transform: scale(1);
        transition: 0.5s ease;
      }
      .heading {
        color: #1c1c1c;
        font-family: "National-Normal";
        margin-top: 1em;
      }
    }
  }
  .content_wrapper {
    .head {
      display: block;
      font-weight: 600;
    }
  }
}

@media (max-width: 1034px) {
  .sflex_sec4 {
    .app_row {
      .app_col {
        max-width: 23.4%;
      }
    }
  }
}

// @media (max-width: 992px) {
//   .sflex_sec3 {
//     margin-top: 7.188em;
//   }
// }

@media (max-width: 991px) {
  .sflex_sec4 {
    .app_row {
      column-gap: 0;
      .app_col {
        max-width: 100%;
        &:nth-child(2n + 1) {
          padding-right: 1.5em;
        }
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .products_inner {
    .left_section {
      .hollow_title {
        font-size: 7em !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .sflex_sec3 {
    // margin-top: 7.188em;
    // margin-bottom: 4.188em;
    h3 {
      max-width: 100%;
      font-size: 2.5em;
    }
    ul li {
      font-size: 1.6em;
      // padding: 0.5em 0em;
      &::before {
        top: 2.85em;
      }
    }
  }
  .products_inner_watch_now {
    margin-top: 4em;
  }
  .left_section {
    .yarns {
      font-size: 3.125em;
    }
    .drycool_img {
      width: 80%;
    }
  }

  .video_container {
    width: 100%;
    max-width: 30em;
    .video_details {
      height: 15em;
    }
  }

  .sflex_sec4 {
    padding-top: 0.5625em;
    .title {
      font-size: 2.5em;
      margin-bottom: 1.28em;
    }
    .app_row {
      margin: 0 auto;
      .app_col {
        margin-bottom: 2.4em;
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .heading {
          margin-top: 0.7em;
        }
      }
    }
  }
}
