/* Importing Common CSS File */
@import url("../../common.css");
.popup_form_wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  overflow: hidden;
  z-index: 100;
  /* position: absolute; */
}

.products_popup {
  width: 100%;
  max-width: 60%;
  height: 100%;
  max-height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1;
  position: relative;
}

.products_popup_small {
  max-height: fit-content;
}

.products_popup .new_link {
  color: inherit;
  font-family: inherit;
  text-decoration: underline;
}

.products_popup .new_link:hover {
  font-weight: bold;
}

.products_popup .wrapper {
  max-width: 90%;
  width: 100%;
  padding: 3.75em 0 5.625em;
}

.products_popup .img_and_close_button {
  position: relative;
}

.products_popup .img_and_close_button button {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0.75em 0.75em 0 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  color: #002b5a;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products_popup .img_and_close_button button span {
  margin-top: -0.2em;
}

.products_popup figure {
  max-width: 100%;
  width: 100%;
}

.products_popup p {
  margin-top: 1.375em;
  font-family: "National-Light";
  font-size: 1.375em;
  line-height: 1.27em;
}

.products_popup .product_items {
  margin-top: 3.0625em;
}
.products_popup .product_items ul {
  margin-top: 0.875em;
}

.products_popup .product_items ul li {
  margin-left: 1.3em;
  display: list-item;
  list-style-type: disc;
}

.products_popup ul li p {
  margin-top: 0.375em;
}
.popup_form_wrapper .pop_sec3 {
  margin-top: 3em;
}
.popup_form_wrapper .pop_sec3 .pop_box {
  background-color: #f0f6fd;
  padding: 3em 0 3em 1em;
  width: 100%;
  max-width: 20em;
  margin-right: 1em;
}
.popup_form_wrapper .pop_sec3 .pop_box .pop_head {
  text-align: left;
  font-size: 1.625em;
  line-height: 1.5em;
  font-family: "National-Light";
  letter-spacing: 0px;
  font-weight: 700;
  color: #1c1c1c;
  opacity: 1;
}
.popup_form_wrapper .pop_sec3 .pop_box .pop_desc {
  text-align: left;
  font-size: 1.25em;
  line-height: 1.5em;
  font-family: "National-Light";
  letter-spacing: 0px;
  color: #1c1c1c;
  opacity: 1;
}

@media (max-width: 768px) {
  .products_popup .img_and_close_button button span {
    margin-top: -0.02em;
  }
}
