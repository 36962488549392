/* Importing Common CSS File */
@import url("../../common.css");
.aboutus_our_journey {
  padding: 4em 0 10em;
  margin-top: 8.5em;
  position: relative;
  background-color: #f0f6fd;
}

.aboutus_our_journey .wrapper {
  position: relative;
}

.aboutus_our_journey_heading {
  margin-top: 1.84375em;
  display: flex;
  justify-content: space-between;
  flex-basis: 40%;
}
.aboutus_our_journey_heading .new_link {
  color: inherit;
  font-family: inherit;
  text-decoration: underline;
}
.aboutus_our_journey_heading .new_link:hover {
  font-weight: bold;
}
.aboutus_our_journey_heading h3 {
  max-width: 40%;
}

.aboutus_our_journey_heading p {
  max-width: 37.25em;
  font-size: 1.45em;
}
.aboutus_our_journey_slider {
  /* height: 100vh; */
  position: relative;
  background-image: url("https://dummyimage.com/1920x1595/002B5A/002B5A");
}

.aboutus_our_journey_slider {
  padding: 5em 0 11em;
}
.aboutus_our_journey_slider .wrapper {
  position: relative;
}

.aboutus_our_journey_slider .aboutus_our_journey_content_slider_wrapper {
  /* padding: 46.78125em 0 0;  */
  text-align: center;
}

.aboutus_our_journey_slider figure {
  max-width: 100%;
  position: absolute;
  top: -62em;
}

.aboutus_our_journey_slider
  .aboutus_our_journey_content_slider_wrapper
  .swiper-slide
  .hollow_title {
  opacity: 0.3;
  text-transform: none;
  -webkit-text-stroke-color: #fff;
}

.aboutus_our_journey_slider
  .aboutus_our_journey_content_slider_wrapper
  .swiper-slide
  .hollow_title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #fff, #fff 50%, transparent 50%);
  opacity: 0.4;
  -webkit-text-stroke-color: #fff;
}

.aboutus_our_journey_slider
  .aboutus_our_journey_content_slider_wrapper
  .swiper-slide
  .hollow_title:hover {
  -webkit-text-stroke-color: #fff;
}

/* .aboutus_our_journey_slider .aboutus_our_journey_slider_wrapper li {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
} */

.aboutus_our_journey_slider .aboutus_our_journey_year_slider_wrapper {
  margin-top: 3.34375em;
  text-align: center;
}

.aboutus_our_journey_slider .aboutus_our_journey_year_slider_wrapper ul {
  align-items: center;
}

.aboutus_our_journey_slider .aboutus_our_journey_year_slider_wrapper li span {
  color: #fff;
  font-family: "Gordita-Regular";
  font-size: 3.8125em;
  line-height: 2.948em;
  display: inline-block;
  opacity: 0.3;
  /* transition: 0.3s ease-in-out; */
}

.aboutus_our_journey_slider
  .aboutus_our_journey_year_slider_wrapper
  li.swiper-slide-active
  span {
  max-width: 21.3125em;
  width: 100%;
  padding: 1em 0;
  display: inline-block;
  position: relative;
  color: #fff;
  font-family: "Gordita-Regular";
  font-size: 6em;
  line-height: 2.27em;
  opacity: 1;
}

.aboutus_our_journey_slider
  .aboutus_our_journey_year_slider_wrapper
  li.swiper-slide-active
  span:before {
  content: "";
  max-width: 4.2em;
  width: 100%;
  min-height: 2em;
  height: 100%;
  padding: 1em 0;
  border: 5px dashed #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0.1em;
  /* top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px; */
  opacity: 0.5;
  /* animation: spin 10s linear infinite; */
}

/* @keyframes spin { 
    100% { 
      transform: rotateZ(360deg); 
    }
  } */

.aboutus_our_journey_slider .aboutus_our_journey_description_slider_wrapper {
  margin-top: 3em;
}

.aboutus_our_journey_slider_content {
  max-width: 46.5em;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  font-size: 1.25em;
  line-height: 1.65em;
  text-align: center;
}

/* .aboutus_our_journey_slider .aboutus_our_journey_description_slider_wrapper {
    padding: 10.375em 0 10.625em;
} */

/* .aboutus_our_journey_slider_wrapper .swiper-pagination { */
/* position: absolute; */
/* top: 65%; */
/* display: flex;
    justify-content: space-between; */
/* } */

/* .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets { left: -3em; } */

.aboutus_our_journey_year_slider_wrapper .swiper-pagination-bullets {
  /* padding: 5px 10px; */
  /* border-radius: 0; */
  /* width: auto;
	/* background: red; */
  /* height: 30px;
    bottom: 6em !important; */
  /* display: flex;
    justify-content: space-evenly; */
  color: #fff;
  font-size: 4.8125em;
  line-height: 2.948em;
  opacity: 0.3;
}

/* .swiper-pagination-bullet {
    width: 0;
    height: 0;
}

.swiper-pagination-bullet-active {
    margin-top: -0.5em !important;
    font-size: 2em !important;
    line-height: 2.27em;
} */

/* .swiper-pagination-bullet-active {
    color: #fff !important;
    font-size: 8.75em;
    line-height: 2.27em;
    opacity: 1;
} */

.aboutus_our_journey_slider .navigations {
  position: absolute;
  bottom: -7em;
  left: 50%;
  transform: translateX(-50%);
}

/* .aboutus_our_journey_slider .swiper-pagination-bullet-active { position: relative; } */

/* .aboutus_our_journey_slider .swiper-pagination-bullet-active::before {
    content: '';
    max-width: 3.8em;
    width: 100%;
    min-height: 3.8em;
    height: 100%; 
    border: 5px dashed #004083;
    border-radius: 50%;
    position: absolute;
    top: -1.2em;
    left: -0.1em;
    opacity: 0.5;
} */

@media (max-width: 768px) {
  .aboutus_our_journey_heading {
    flex-flow: column;
  }

  .aboutus_our_journey_heading h3 {
    font-size: 2.4em;
    line-height: 1.25em;
    max-width: 100%;
  }

  .aboutus_our_journey_heading p {
    max-width: 100%;
    margin-top: 1.7em;
    font-size: 1.3em;
    line-height: 1.53em;
  }

  /* .aboutus_our_journey_slider figure { max-width: 70.75em; } */
  .aboutus_our_journey_slider {
    padding-top: 7em;
  }

  .aboutus_our_journey_slider
    .aboutus_our_journey_content_slider_wrapper
    .swiper-slide
    .hollow_title {
    max-width: 30%;
    line-height: 0.96em;
    text-transform: capitalize;
  }

  .aboutus_our_journey_slider .aboutus_our_journey_year_slider_wrapper li span {
    font-size: 2em;
  }

  .aboutus_our_journey_slider
    .aboutus_our_journey_year_slider_wrapper
    li.swiper-slide-active
    span {
    font-size: 4em;
  }

  .aboutus_our_journey_slider
    .aboutus_our_journey_year_slider_wrapper
    li.swiper-slide-active
    span:before {
    max-width: 2.3em;
    min-height: 2em;
    top: 0;
    left: 1.1em;
    border: 4px dashed #fff;
  }

  .aboutus_our_journey_slider_content {
    max-width: 35em;
    font-size: 1.3em;
    line-height: 1.53em;
  }

  .aboutus_our_journey_slider figure {
    /* max-width: 33em;  */
    top: -45em;
  }

  .aboutus_our_journey_slider
    .aboutus_our_journey_year_slider_wrapper
    li.swiper-slide-active
    span:before {
    content: "";
    max-width: 4.2em;
    width: 100%;
    min-height: 2em;
    height: 100%;
    padding: 1em 0;
    border: 4px dashed #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
}

@media (max-width: 450px) {
  .aboutus_our_journey_slider
    .aboutus_our_journey_content_slider_wrapper
    .swiper-slide
    .hollow_title {
    max-width: 75%;
  }
  .aboutus_our_journey_slider
    .aboutus_our_journey_year_slider_wrapper
    li.swiper-slide-active
    span:before {
    left: 2em;
  }
}

@media (max-width: 376px) {
  .aboutus_our_journey {
    padding-bottom: 16em;
  }
  .aboutus_our_journey_slider figure {
    /* max-width: 33em;  */
    top: -22em;
  }
  .aboutus_our_journey_slider {
    padding-top: 14em;
  }
  .aboutus_our_journey_slider
    .aboutus_our_journey_content_slider_wrapper
    .swiper-slide
    .hollow_title {
    max-width: 70%;
  }
  .aboutus_our_journey_slider
    .aboutus_our_journey_year_slider_wrapper
    li.swiper-slide-active
    span:before {
    left: 2em;
  }
}
