.products_inner {
  .left_section {
    .revir_text {
      color: #45a000;
      text-transform: uppercase;
    }
    .revior_oimg {
      width: 45%;
      margin-bottom: 0.37em;
      margin-top: 3em;
    }
    .new_link {
      color: inherit;
      font-family: inherit;
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
  }
}

.products_inner_list_revir {
  .products_inner_list_heading_wrapper {
    h3 {
      text-align: left;
      width: 100%;
      max-width: 50%;
      margin-bottom: 1em;
      font-family: "National-Medium";
      font-size: 2.28em;
      line-height: 1.2em;
    }
  }
  .products_inner_listing {
    margin-bottom: 8em;
    .img_desc {
      margin: 1em 0 0 0;
      text-align: left;
      font: normal normal 300 1.625em/1.875em National;
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      line-height: 1.2em;
    }
  }
}
.reviro_sec2 {
  margin-top: 3em;
  margin-bottom: 7em;
  .products_inner_listing {
    .headanddesc {
      width: 100%;
      max-width: 60%;
    }
    .small_icon {
      max-width: 24%;
    }
    .bornimg_desc {
      text-align: left;
      margin-top: 1.5em;
      font-size: 1.57em;
      line-height: 1.65em;
      font-family: "National-Light";
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      width: 100%;
      max-width: 60%;
    }
  }
}

.reviro_sec3 {
  .availablein {
    margin-top: 5.0625em;
    position: relative;
    .box_list {
      margin-top: 1.625em;
      margin-bottom: 5em;
      .two_box {
        display: flex;
        li {
          display: flex;
          width: 100%;
          max-width: 100%;
          margin-right: 5em;

          .box_content {
            max-width: 29em;
            width: 100%;
            background-color: #002b5a;
            display: flex;
            text-align: left;
            flex-direction: column;
            justify-content: center;
            padding: 1em 2.5em;
            .box_head {
              font-size: 2em;
              color: #fff;
              font-weight: normal;
              letter-spacing: 0px;
              font-family: "National-Medium";
              font-weight: normal;
            }
            p {
              margin-top: 0.5em;
              font-size: 1.25em;
              line-height: 1.3em !important;
              letter-spacing: 0px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.revior_sec4 {
  .first_section {
    background-color: #002b5a;
    color: #ffffff;
    padding: 8em 13em 6em 3em;
    .big_text {
      text-align: left;
      font-size: 2.75em;
      font-family: "National-Light";
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      line-height: 1.5em;
    }
    .btn_wrapper {
      margin-top: 1.5em;
      .download_btn_text {
        color: #fff;
      }
      .btn_arrow {
        max-width: 10.125em;
      }
    }
    .pdf_modal_wrapper {
      .certifications_slider_heading_section {
        .pdf_modal_cross {
          position: absolute;
          top: 1em;
          right: 1em;
          width: 100%;
          max-width: 3.25em;
          cursor: pointer;
        }
        .certifications_slider_wrapper {
          margin-top: 0 !important;
        }
      }
    }
  }
  .second_section {
    background-color: #f0f6fd;
    color: #002b5a;
    padding: 2em 5em;
    .big_text1 {
      font-size: 18.188em;
      font-family: "National-Light";
      //   font: normal normal normal 17.188em/15.625em National;
      color: #002b5a;
      opacity: 1;
    }
    .big_text2 {
      padding: 1em 3em 1em 0;
      font-size: 3.125em;
      font-family: "National-Light";
      // font: normal normal normal 3.125em/15.625em National;
      color: #002b5a;
      text-transform: uppercase;
      opacity: 1;
      .inner_list {
        font-family: "National-Light";
        margin-top: 0.3em;
        margin-left: 0.2em;
      }
    }
  }
}
.revior_sec5 {
  .first_section,
  .second_section {
    border-right: 1px solid rgba(0, 0, 0, 0.5);
  }
  .first_section,
  .second_section,
  .third_section {
    color: #1c1c1c;
    padding: 0 9em 0 3em;
    margin: 8em 0 0 0em;

    .big_text {
      text-align: left;
      font-size: 1.875em;
      font-family: "National-Light";
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      line-height: 1.5em;
    }
  }
}
// .reviro_video {
//   margin-top: 12em;
// }

@media (max-width: 767px) {
  .products_inner {
    padding-bottom: 0;
    // margin-bottom: 0;
    .left_section {
      .revior_oimg {
        width: 80%;
      }
      br {
        display: inline;
      }
    }
  }
  .products_inner_list_revir {
    margin-top: 8em;
    .products_inner_list_heading_wrapper {
      h3 {
        font-family: "National-Light";
        margin-top: 0em;
        margin-bottom: 0em;
        font-size: 2.6em;
        text-align: center;
        max-width: 100%;
      }
    }
  }
  .products_inner_listing {
    display: inline-block;
    .product_box {
      margin: 3em 0 0;
    }
    figure {
      .img_desc {
        margin: 0.5em 0 0 !important;
      }
    }
  }

  .reviro_sec2 {
    margin-bottom: 3em;
    .products_inner_listing {
      .headanddesc {
        width: 100%;
        max-width: 100%;
      }
      .small_icon {
        text-align: center;
        max-width: 100%;
        img {
          max-width: 25%;
        }
      }
      .bornimg_head {
        text-align: center;
      }
      .bornimg_desc {
        text-align: center;
        max-width: 100%;
        margin-top: 1.2em;
        font-size: 1.6em;
        padding: 0em 3em;
      }
    }
  }

  .reviro_sec3 {
    .box_list {
      .two_box {
        margin-bottom: 3em;
        text-align: center;
        .box_content {
          background-color: #002b5a;
          padding: 5em 2em;
          .box_head {
            color: #fff;
          }
          p {
            margin-top: 1em;
            color: #fff;
          }
        }
      }
    }
  }

  .revior_sec4 {
    .first_section {
      padding: 4em 4em 4em 2em;
    }
    .second_section {
      padding: 2em 1em;
    }
  }

  .revior_sec5 {
    margin-top: 4em;
    .first_section,
    .second_section {
      border-width: 8em;
    }
    .first_section,
    .second_section {
      border-right: none;
    }
    .first_section,
    .second_section,
    .third_section {
      color: #1c1c1c;
      padding: 0 1em;
      margin: 2em 0 0 0em;
      .big_text {
        padding-bottom: 1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }

  // .reviro_video {
  //   margin-top: 2em;
  // }
}
