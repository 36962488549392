/* Importing Common CSS File */
@import url("../../common.css");
.popup_form_wrapper_career {
  width: 100%;
  min-height: 100vh;
  border: 1px solid #707070;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 43, 90, 0.2);
  opacity: 1;
  z-index: 100;
}

.careers {
  max-width: 54.3125em;
  width: 100%;
  min-height: 44.1875em;
  /* margin-top: 50em; */
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.careers .wrapper {
  padding: 4em 7em 4em 6.4375em;
}

.careers .close_button {
  width: 95%;
  margin: 0 auto;
  text-align: right;
}

/* .careers .close_button button {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-top: 0.5625em;
    background-color: #002B5A;
    color: #fff;
    font-size: 1.5em;
} */

.careers .input_upload_group label {
  font-family: "National-Normal";
  font-size: 1.25em;
  line-height: 1.2em;
}

.careers .contact_wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.careers .contact_wrapper .PhoneInput {
  margin-top: 3em;
}

.careers .contact_wrapper .PhoneInputInput {
  height: 1.4375em;
  padding: 4px 0 5px;
  font-size: 1rem;
  outline: none;
}

/* .careers .select_department div { 
    padding-bottom: 0.5em;
    margin-left: 0; 
} */

.careers .main_file_wrapper {
  max-width: 50%;
  padding: 2em 0;
  border: 2px dashed #004083;
  margin-top: 0.5em;
  position: relative;
  text-align: center;
}

.careers .input_upload_group input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}

/* .careers input { padding: 0; } */
.careers
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
  padding: 0;
}
.careers .form_wrapper {
  margin-top: 1em !important;
}
.careers .form_input_wrapper:not(:first-child) {
  margin-top: 0 !important;
}
.careers .contact_wrapper .PhoneInputInput {
  font-size: 1.2em !important;
}
.careers .contact_wrapper {
  margin-bottom: 0;
}

.careers .additional_text {
  max-width: 50%;
  margin-top: 0.2em;
  display: flex;
  justify-content: space-between;
}

.careers .additional_text p {
  font-size: 1.125em;
}

.careers .choose_file_text {
  /* display: inline-block; */
  /* padding-bottom: 0.5em; */
  border-bottom: 1px solid #004883;
  color: #004083;
  /* text-decoration: underline; */
}

.upload_img {
  width: 19px;
  height: 19px;
  margin-right: 1em;
  display: inline-block;
}

.careers .form_wrapper .select_department {
  margin-top: 2em !important;
}
/* .careers .form_wrapper .input_upload_group { margin-top: 2em !important; } */
/* .careers .form_input_wrapper:not(:first-child) { margin-top: 0 !important; } */

.popup_form_wrapper_career .btn-close {
  margin-top: 1em;
  margin-left: auto;
  display: inline-block;
  /* float: right; */
  /* margin: 0; */
  border: 0;
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
}
.popup_form_wrapper_career .btn-close-careers.btn-close {
  background-color: #002b5a;
}
.popup_form_wrapper_career .btn-close .icon-cross {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 24px;
  height: 40px;
}
.popup_form_wrapper_career .btn-close .icon-cross:before,
.popup_form_wrapper_career .btn-close .icon-cross:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
  border-radius: 6px;
}
.popup_form_wrapper_career .btn-close .icon-cross:before {
  transform: rotate(45deg);
}
.popup_form_wrapper_career .btn-close .icon-cross:after {
  transform: rotate(-45deg);
}
.popup_form_wrapper_career .btn-close .icon-cross span {
  display: block;
}
.popup_form_wrapper_career .btn-close:hover,
.popup_form_wrapper_career .btn-close:focus {
  transform: rotateZ(90deg);
  /* background: #0052cc; */
}

/* .MuiFormControl-root .css-1869usk-MuiFormControl-root { margin: 0 !important; } */

.file_container {
  margin-top: 2.85em;
}
.file_container .upload_text {
  font-family: "National-Normal";
  font-size: 1.25em;
  line-height: 1.2em;
}
.file_container .upload_resume_box {
  width: 100%;
  max-width: 21.563em;
  min-height: 4.688em;
  border: 1px dashed #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  cursor: pointer;
  margin-right: 0;
}
.file_container .upload_resume_box img {
  width: 100%;
  max-width: 1.1875em;
}
.file_container .upload_resume_box span {
  font-size: 1.125em;
  color: #002b5a;
  letter-spacing: 0.29px;
  padding-left: 1.97em;
  text-decoration: underline;
  font-family: "National-SemiBold";
}
.file_container .file_format_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 24.167em;
  font-size: 1.125em;
  color: #525252;
  font-family: "National-Light";
  letter-spacing: 0.29px;
  margin-top: 0.2em;
}
.file_container .file_format_wrapper .err {
  color: red;
}
.submit_button_container {
  margin-top: 1em;
}
.careers {
  height: 50em;
  overflow-y: scroll;
}
.careers::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
}

.careers::-webkit-scrollbar-track {
  background-color: transparent;
}
.careers::-webkit-scrollbar-thumb {
  border-radius: 2em;
  cursor: pointer;
  background-color: #bbb4b4;
}

@media (max-width: 834px) {
  .careers .contact_wrapper .PhoneInputInput {
    height: initial;
  }
}

@media (max-width: 768px) {
  .careers .additional_text p {
    font-size: 1.2em !important;
  }
  .careers .form_input_wrapper:nth-child(3) {
    margin-top: 3.5em !important;
  }
}

@media (max-width: 450px) {
  .careers {
    max-width: 35em;
  }
  .careers .wrapper {
    padding: 2em 1em 3em;
  }
  .careers .main_file_wrapper {
    max-width: 100%;
  }
  .careers .additional_text {
    max-width: 100%;
  }

  .popup_form_wrapper_career .btn-close {
    width: 30px !important;
    height: 30px !important;
  }

  .popup_form_wrapper_career .btn-close .icon-cross {
    width: 15px !important;
    height: 36px !important;
  }
  .popup_form_wrapper_career .btn-close .icon-cross:before,
  .popup_form_wrapper_career .btn-close .icon-cross:after {
    height: 2px !important;
  }
}
