.products_popup12 {
  // height: 40em;
  overflow-y: scroll;
  overflow-x: hidden;
  .cert_img_1 {
    margin-bottom: 1.5em;
  }
}
.products_popup12::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
}

.products_popup12::-webkit-scrollbar-track {
  background-color: transparent;
}
.products_popup12::-webkit-scrollbar-thumb {
  border-radius: 2em;
  cursor: pointer;
  background-color: #bbb4b4;
}

@media (max-width: 767px) {
  .popup_form_wrapper12 {
    .products_popup12 {
      max-width: 90%;
      max-height: 72%;
      .wrapper {
        margin-left: 2em;
      }
      .pop_sec3 {
        margin-top: 0;
        //   .products_inner_listing {
        display: inline;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        .pop_box2 {
          padding: 3em 0.5em;
          margin-right: 0;
          margin-right: 2em;
          margin-left: 1.2em;
          margin-top: 2.75em;
          max-width: 100%;
          .pop_head {
            text-align: center;
          }
          .pop_desc {
            text-align: center;
          }
          .dashwrapper {
            visibility: hidden;
          }
          // }
        }
      }
      .img_and_close_button {
        button {
          width: 1.7em;
          height: 1.7em;
        }
        span {
          font-size: 1.25em;
          margin-top: -0.2em;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .popup_form_wrapper12 {
    .products_popup12 {
      max-width: 90%;
      max-height: 70%;
    }
  }
}
@media (max-width: 375px) {
  .popup_form_wrapper12 {
    .products_popup12 {
      max-width: 90%;
      max-height: 60%;
    }
  }
}
