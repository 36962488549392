/* Importing Common CSS File */
@import url("../../common.css");

* {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

header .wrapper {
  padding: 2.9375em 0;
}

.header_link {
  max-width: 24.420625em;
  display: inline-block;
}

nav {
  float: right;
}

header a,
header button {
  font-family: "National-Normal";
  font-size: 1.25em;
  line-height: 1.2em;
  text-transform: uppercase;
}

header a:hover {
  outline: none;
  text-decoration: none;
}

header button {
  border: none;
  background-color: transparent;
}

.changeColor .wrapper,
.homepage .wrapper,
.header .wrapper {
  padding: 2.9375em 0;
}

.changeColor {
  background-color: #fff;
}

.changeColor a,
.changeColor button {
  color: #000;
}

.homepage a,
.homepage button {
  color: #fff;
}

.header {
  background-color: #fff;
}

.header a,
.header button {
  color: #000;
}

.top_nav {
  text-align: right;
}

.bottom_nav {
  margin-top: 1.3125em;
}

.top_nav li:not(:first-child) {
  margin-left: 3.5625em;
}

.bottom_nav li:not(:first-child) {
  margin-left: 3.0625em;
}

.fa-caret-down {
  margin-left: 0.5em;
}

.changeColor,
.header {
  -webkit-box-shadow: 0px 1px 6px #00000029;
  -moz-box-shadow: 0px 1px 6px #00000029;
  box-shadow: 0px 1px 6px #00000029;
}

.dropdown_custom {
  position: relative;
  display: block;

  &.active {
    display: block;
  }
}

.dropdown_content.active {
  display: block;
}

.dropdown_content a:hover {
  background-color: #ddd;
}

.dropdown_content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown_content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.nav_icon {
  width: 2.188em;
  height: 2.188em;
  position: relative;
  z-index: 2;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

span.menu {
  display: block;
  width: 100%;
  height: 0.2em;
  background-color: #000;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.55s ease-in-out;
  -moz-transition: 0.55s ease-in-out;
  -o-transition: 0.55s ease-in-out;
  transition: 0.55s ease-in-out;
}

span.menu:nth-child(2) {
  width: 60%;
}

.mobile_homepage .nav_icon span.menu {
  background-color: #fff;
}

.mobile_changeColor .nav_icon span.menu {
  background-color: #000;
}

.mobile_header .nav_icon span.menu {
  background-color: #000;
}

.open_nav {
  transform: translateY(-0.5em);
}

.open_nav span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: translateY(1.2em) rotate(135deg);
}

.open_nav span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.open_nav span:nth-child(3) {
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: translateY(-0.28em) rotate(-135deg);
}

.open_nav .menu {
  background-color: #fff !important;
}

/* .mobile_changeColor .open_menu,
.mobile_header .open_menu,
.mobile_homepage .open_menu {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #002B5A;
} */

.mobile_header nav.open_menu a,
.mobile_changeColor nav.open_menu a,
.mobile_homepage nav.open_menu a {
  color: #fff;
  font-size: 2.2em;
  line-height: 1.227em;
}

.mobile_header nav.open_menu button,
.mobile_changeColor nav.open_menu button,
.mobile_homepage nav.open_menu button {
  color: #fff;
  font-size: 2.2em;
  line-height: 1.227em;
}

.dropdown_content a {
  font-size: 1.2em;
  line-height: 1em;
}

.nav_wrapper {
  display: none;
}



@media screen and (min-width: 1024px) {
  .dropdown_custom:hover .dropdown_content {
    display: block;
  }
}

@media (max-width: 1918px) {
  .bottom_nav li:not(:first-child) {
    margin-left: 2.5em;
  }
}

@media (max-width: 1024px) {
  .bottom_nav li:not(:first-child) {
    margin-left: 2.1em;
  }
}

@media (max-width: 940px) {
  .bottom_nav li:not(:first-child) {
    margin-left: 0.75em;
  }
}

@media (max-width: 769px) {
  .nav_wrapper {
    display: inline-block;
    vertical-align: middle;
  }

  .mobile_header .header_link,
  .mobile_changeColor .header_link,
  .mobile_homepage .header_link {
    max-width: 16.4em;
    position: relative;
    left: 47%;
    transform: translateX(-52%);
  }

  .mobile_header nav,
  .mobile_changeColor nav,
  .mobile_homepage nav {
    display: none;
    float: none;
  }

  nav.open_menu {
    width: 100vw;
    height: 100%;
    padding: 9em 0 3em 2.9375em;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #002b5a;
    background-image: url("../../images/General/bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  /* .mobile_header nav.open_menu a,
    .mobile_changeColor nav.open_menu a,
    .mobile_homepage nav.open_menu a {
        color: #fff;
        font-size: 2.2em;
        line-height: 1.227em;
    }

    .mobile_header nav.open_menu button,
    .mobile_changeColor nav.open_menu button,
    .mobile_homepage nav.open_menu button {
        color: #fff;
        font-size: 2.2em;
        line-height: 1.227em;
    } */

  .mobile_header nav.open_menu li button {
    padding: 0;
  }

  .mobile_header nav.open_menu li {
    margin-top: 2.2em;
  }

  .mobile_header nav.open_menu li:focus-visible {
    outline: none;
  }

  .mobile_header nav.open_menu li {
    margin-left: 0;
  }

  .mobile_homepage nav.open_menu li button {
    padding: 0;
  }

  .mobile_homepage nav.open_menu li {
    margin-top: 2.2em;
  }

  .mobile_homepage nav.open_menu li {
    margin-left: 0;
  }

  .mobile_changeColor nav.open_menu li button {
    padding: 0;
  }

  .mobile_changeColor nav.open_menu li {
    margin-top: 2.2em;
  }

  .mobile_changeColor nav.open_menu li {
    margin-left: 0;
  }

  .mobile_header .open_nav span {
    background-color: #fff !important;
  }

  .top_nav li {
    text-align: left;
  }

  .top_nav li,
  .bottom_nav li {
    display: block;
  }

  .dropdown_content {
    position: relative;
    background-color: transparent;
    box-shadow: none;
  }

  nav li:not(:first-child) a {
    font-size: 2.6em;
  }

  .dropdown_content {
    margin: 1em 0 0 1em;
  }

  .dropdown_content a {
    font-size: 2em !important;
    line-height: 1em !important;
  }

  .mobile_header .mobile_menu_wrapper {
    height: 100%;
    /* overflow-y: auto; */
  }

  .dropdown_content a:hover {
    background-color: transparent;
  }
}

@media (max-width: 767px) {

  .changeColor .wrapper,
  .homepage .wrapper,
  .header .wrapper {
    padding: 2.9375em 2.26em;
  }
}

@media (max-width: 376px) {

  .mobile_header .header_link,
  .mobile_changeColor .header_link,
  .mobile_homepage .header_link {
    max-width: 16.4em;
    position: relative;
    left: 47%;
    transform: translateX(-52%);
  }

  .mobile_header .open_nav span.menu {
    background-color: #fff;
  }

  .mobile_changeColor .open_nav span.menu {
    background-color: #fff;
  }

  nav.open_menu {
    width: 100vw;
    height: 100vh;
    padding: 7em 0 3em 2.8em;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #002b5a;
    background-image: url("../../images/General/bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .mobile_header nav.open_menu a,
  .mobile_changeColor nav.open_menu a,
  .mobile_homepage nav.open_menu a {
    color: #fff;
    font-size: 2.2em;
    line-height: 1.227em;
  }

  .mobile_header nav.open_menu button,
  .mobile_changeColor nav.open_menu button,
  .mobile_homepage nav.open_menu button {
    color: #fff;
    font-size: 2.2em;
    line-height: 1.227em;
  }

  .top_nav li {
    text-align: left;
  }

  .top_nav li,
  .bottom_nav li {
    display: block;
  }

  .dropdown_content {
    position: relative;
    background-color: transparent;
  }

  .dropdown_content a {
    font-size: 2em !important;
    line-height: 1em !important;
  }

  /* nav li:first-child button { font-size: 2.6em !important; }
    nav li:not(:first-child) a { font-size: 2.6em !important; } */
}