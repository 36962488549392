// .products_inner {
//   margin-bottom: 5em;
// }
// .selfpadding{
//   padding: 2em 0 23em;
// }
.products_inner_list {
  .products_inner_listing {
    .headanddesc {
      width: 100%;
      max-width: 60%;
    }
    .small_icon {
      max-width: 30%;
    }
    .bornimg_head {
      text-align: left;
      font-size: 2em;
      line-height: 3.75em;
      font-weight: 600;
      font-family: "National-Light";
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      line-height: 2em;
    }
    .bornimg_desc {
      text-align: left;
      font-size: 1.25em;
      line-height: 1.3em;
      font-family: "National-Light";
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
      width: 100%;
      max-width: 60%;
    }
  }
}
.born_sec3 {
  margin: 8em 0;
  .second_section {
    padding-left: 8em;
  }
  .hollow_title {
    line-height: 1em;
  }
}

@media (max-width: 767px) {
  // .selfpadding{
  //   padding-bottom: 0;
  // }
  .products_inner_list {
    .products_inner_listing {
      .product_box {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .born_sec3 {
    margin: 4em 0;
    .second_section {
      padding-left: 0;
      text-align: center;
      .hollow_title {
        margin-top: 0.5em;
        line-height: 1.3em;
      }
      p {
        text-align: left;
        margin-top: 1.5em;
        padding: 0 1em;
      }
    }
  }
  .products_inner_list {
    .products_inner_listing {
      .headanddesc {
        width: 100%;
        max-width: 100%;
      }
      .small_icon {
        text-align: center;
        max-width: 100%;
        img {
          max-width: 30%;
        }
      }
      .bornimg_head {
        text-align: center;
      }
      .bornimg_desc {
        text-align: center;
        max-width: 100%;
        font-size: 1.6em;
        padding: 1em 3em;
      }
    }
  }
}
