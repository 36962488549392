@import "../../common.css";

.bloginner_sec1 {
  padding: 13em 0 0em;
  // margin-top: 8.5em;
  .blog_heading {
    font-size: 2.5em;
    font-family: "National-SemiBold";
    color: #333333;
    line-height: 1.5em;
    letter-spacing: 0.4px;
    font-weight: 600;
    text-transform: capitalize;
    opacity: 1;
    margin: 2em 0;
    max-width: 70%;
  }
  .breadcrumb_styles {
    margin: 2em 0;
  }
  .mainimg {
    margin-top: 0em;
    z-index: 1;
    width: 100%;
    object-fit: cover;
  }
  .social_icon_date {
    margin: 1.5em 0 1.6em;
    font-family: "National-Light";
    .datecat_list {
      display: flex;
      align-items: center;
      padding: 0;
      .blog_date,
      .blog_category {
        display: inline;
        margin-right: 0.3em;
        font-size: 1.125em;
        font-family: "National-Light";
        line-height: 1.5em;
        letter-spacing: 0;
      }
    }
    .social_main_wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .share_title {
        font-size: 1.5em;
        font-family: "National-Light";
        color: #333333;
        opacity: 0.8;
        margin: 0 1em 0 0;
        padding: 0;
      }
      .social_icons {
        display: flex;
        justify-content: flex-end;
        flex-basis: 85%;
        align-items: center;
        .social_icon_li {
          .social_icon img {
            margin-right: 1.5em;
            max-width: 2.7em;
          }
        }
        a {
          display: contents;
          .social_name {
            margin-left: 1em;
            font-size: 1em;
            font-family: "National-Light";
            line-height: 1.875em;
          }
        }
      }
    }
    .social_icon_li_copy {
      cursor: pointer;
      position: relative;
      .social_name {
        position: absolute;
        left: 0em;
        bottom: -1.2em;
      }
    }
  }
  .horizontal_line {
    border-top: 1px solid black;
    margin-bottom: 2em;
    opacity: 0.35;
  }
  .left_section_paragraphs {
    margin-bottom: 1.5em;
    color: #333333;
    // opacity: 0.8;
  }
  .blog_inner_name {
    font-size: 3em;
    font-weight: bold;
    font-family: "National-SemiBold";
    color: #333333;
    margin-bottom: 0.7em;
  }
}

.bloginner_sec2 {
  margin-top: 5.313em;
  .heading_arrow_flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.063em;
    align-items: center;
    .gallery_heading {
      font-size: 2.5em;
      font-family: "National-SemiBold";
      line-height: 1.5em;
      letter-spacing: 0.4px;
      color: #333333;
      font-weight: 600;
      text-transform: capitalize;
      opacity: 1;
    }
    .navigations {
      position: absolute;
      top: 0;
      right: 3em;
      button {
        color: #1e5093 !important;
      }
      .left_arrow:after {
        border: 2px dashed #1e5093;
      }
      .right_arrow::before {
        border: 2px dashed #1e5093;
      }
    }
    .arrows_wrapper {
      .galleryleftarrowimg {
        max-width: 3.5em;
        margin-right: 1em;
        cursor: pointer;
      }
      .galleryrightarrowimg {
        max-width: 3.5em;
        cursor: pointer;
      }
    }
  }
  .gallery_slider_wrapper {
    margin-right: -5.5em;

    .gallery_img {
      width: 100%;
      max-width: 38.063em;
      min-height: 25.125em;
      object-fit: cover;
      &:hover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 100;
        opacity: 0.7;
      }
    }
    .content-overlay {
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      height: 100%;
      width: 100%;
      max-width: 38.063em;
      min-height: 25.125em;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
    }

    .content:hover .content-overlay {
      opacity: 1;
    }
    .content:hover .galleryImageName {
      display: inline;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 5%;
      color: #ffffff;
      text-transform: capitalize;
    }

    .video_wrapper_div {
      width: 100%;
      max-width: 38.063em;
      min-height: 25.125em;
      position: relative;
      z-index: 1;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.24;
      }
    }
    .galleryImageName {
      display: none;
    }
    .playicon {
      position: absolute;
      width: 100%;
      max-width: 5.188em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
}

.bloginner_sec3 {
  margin-top: 3.063em;
  margin-bottom: 6.5em;
  .horizontal_line {
    border-top: 1px solid black;
    margin: 5em 0 4em;
    opacity: 0.35;
    outline: none;
  }
  .heading_arrow_flex2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.063em;
    align-items: center;
    .moreblog_heading {
      margin: 0;
      font-family: "National-SemiBold";
      font-size: 2.5em;
      line-height: 1.5em;
      letter-spacing: 0.4px;
      color: #333333;
      font-weight: 600;
      text-transform: capitalize;
      opacity: 1;
    }
    .navigations {
      position: absolute;
      top: 3em;
      right: 3em;
      button {
        color: #1e5093 !important;
      }
      .left_arrow:after {
        border: 2px dashed #1e5093;
      }
      .right_arrow::before {
        border: 2px dashed #1e5093;
      }
    }
    .arrows_wrapper {
      .leftarrowimg {
        max-width: 3.5em;
        margin-right: 1em;
        cursor: pointer;
      }
      .rightarrowimg {
        max-width: 3.5em;
        cursor: pointer;
      }
    }
  }
  .moreblogs_wrapper {
    .moreblogs_swiper_container {
      .moreblogs_swiper_wrapper {
        padding: 0;
        .moreblog_detail_wrapper {
          width: 100%;
          max-width: 88%;
          position: relative;
          overflow: hidden;
          // &:hover {
          //   .moreblog_img {
          //     opacity: 0.7;
          //     filter: grayscale(100%);
          //     -webkit-filter: grayscale(100%);
          //   }
          // }
          &::before {
            content: "";
            width: 100%;
            background-color: #000;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            z-index: -2;
            transition: 0.3s opacity;
          }
          .imageanimate {
            overflow: hidden;
            border-radius: 3px;
            .content-overlay {
              overflow: hidden;
              background: rgba(0, 0, 0, 0.6);
              position: absolute;
              height: 100%;
              width: 100%;
              max-width: 38.063em;
              max-height: 31.5em;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              opacity: 0;
              -webkit-transition: all 0.4s ease-in-out 0s;
              -moz-transition: all 0.4s ease-in-out 0s;
              transition: all 0.4s ease-in-out 0s;
              border-radius: 3px;
            }
            &:hover .content-overlay {
              opacity: 1;
            }
            &:hover .moreblog_img {
              transform: scale(1);
            }
          }
          .moreblog_img {
            width: 100%;
            border-radius: 3px;
            position: relative;
            transform: scale(1.2);
            transition: 0.3s ease-in-out;
          }

          .text_container {
            // position: absolute;
            // bottom: 3.875em;
            bottom: -100%;
            left: 2.688em;
            z-index: 3;
            transition: 0.3s bottom;
            margin-top: 1.75em;
            .moreblog_date {
              display: inline;
              font-size: 1.57em;
              font-family: "National-Light";
              margin-bottom: 1.6em;
              color: #333333;
              opacity: 0.8;
            }
            .verticle_line {
              font-size: 1.57em;
              line-height: 1.5em;
              display: inline;
              padding: 0 0.2em;
              color: #333333;
            }
            .moreblog_category {
              display: inline;
              margin-bottom: 1.6em;
              font-size: 1.57em;
              font-family: "National-Light";
              color: #333333;
              opacity: 0.8;
            }
            .moreblog_name {
              font-size: 1.875em;
              font-family: "National-SemiBold";
              letter-spacing: 0.24px;
              font-weight: 600;
              color: #333333;
              text-transform: capitalize;
              opacity: 1;
              margin-top: 0.75em;
            }
            .moreblog_synopsis {
              font-size: 1.125em;
              font-family: "National-Light";
              letter-spacing: 0;
              margin-top: 0.75em;
              margin-bottom: 0.75em;
              width: 100%;
              color: #333333;
              opacity: 0.8;
            }
          }
        }
        .moreblog_detail_wrapper .moreblog_detail_wrapper:hover::before {
          opacity: 0.8;
        }
        .moreblog_detail_wrapper:hover .text_container {
          bottom: 3.875em;
        }
      }
    }
  }
}

@media (max-width: 2561px) {
  .bloginner_sec1 {
  }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                max-width: 100%;
                max-height: 38em;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .bloginner_sec1 {
  }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                max-width: 100%;
                max-height: 31.5em;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .bloginner_sec2 {
    .heading_arrow_flex {
      .navigations {
        top: -1em;
      }
    }
  }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                max-height: 30.2em;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1450px) {
  .bloginner_sec2 {
    .heading_arrow_flex {
      .navigations {
        top: -1.5em;
      }
    }
  }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                max-height: 31em;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1380px) {
  // .bloginner_sec1 {
  // }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                max-height: 31.8em;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .bloginner_sec2 {
    .heading_arrow_flex {
      .navigations {
        top: -2em;
      }
    }
  }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                max-height: 31em;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .imageanimate {
              .content-overlay {
                display: none;
              }
            }
            .moreblog_img {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}
/* Sachin Code start */

.left_section_paragraphs {
  ul li {
    position: relative;
    padding-left: 1.2em;
    &::before {
      content: "";
      position: absolute;
      top: 1em;
      left: 0.1em;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #002b5a;
    }
  }
}
.left_section_paragraphs p {
  font-size: 1.57em !important;
  line-height: 1.65em !important;
  /* margin-left: 0.1em; */
}
.margin-left {
  margin-left: 1em;
}
.margin-left2 {
  margin-left: 1em;
}
.margin-left3 li {
  margin-left: 2.5em;
}

/* Sachin Code End */
//responsive css starts here
@media (max-width: 834px) {
  .bloginner_sec1 {
    // margin-top: 11em;
    .breadcrumb_styles {
      display: none;
    }
    .blog_heading {
      max-width: 100%;
    }
    .mainimg {
      margin-top: 0em;
    }
    .breadcrumb_styles {
      margin: 2em 0 0;
    }
    .social_icon_date {
      .datecat_list {
        .blog_date,
        .blog_category,
        .blog_company {
          font-size: 1.3em;
          font-family: "National-Light";
        }
      }
      .social_main_wrapper {
        .share_title {
          font-size: 1.3em;
          font-family: "National-Light";
        }
      }
    }
    .left_section_paragraphs {
      font-size: 1.3em;
      font-family: "National-Light";
    }
  }
  .bloginner_sec2 {
    .gallery_slider_wrapper {
      margin-right: -5.1em;
      .content:hover .galleryImageName {
        display: inline;
      }
      .galleryImageName {
        display: inline;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 5%;
        color: #ffffff;
        text-transform: capitalize;
      }
    }
  }
  .bloginner_sec3 {
    .moreblogs_wrapper {
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            .text_container {
              font-size: 1.3em;
              font-family: "National-Light";
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bloginner_sec1 {
    // margin-top: 7em;
  }
  .bloginner_sec2 {
    .gallery_slider_wrapper {
      margin-right: -2.1em;
    }
  }
}
@media (max-width: 767px) {
  .bloginner_sec1 {
    text-align: center;
    .mainimg {
      min-height: 23em;
    }
    .social_icon_date {
      .datecat_list {
        .blog_date,
        .blog_category {
          font-size: 1.2em;
          font-family: "National-Light";
        }
        .blog_company {
          display: none;
        }
      }
      .col-md-5 {
        padding: 0;
        .share_title {
          font-size: 1.5em;
          font-family: "National-Light";
          text-align: left;
          margin: 0.7em 0;
        }
      }
      .social_main_wrapper {
        justify-content: flex-start;
        .share_title {
          font-size: 1.5em;
          font-family: "National-Light";
        }
        .social_icons {
          padding: 0;
          justify-content: flex-start;
          .social_icon_li {
            .social_icon {
              img {
                max-width: 4.3em;
              }
            }
          }
        }
      }
      .social_icon_li_copy {
        .social_name {
          left: 0.7em;
          bottom: -1.2em;
        }
      }
    }
    .left_section_paragraphs {
      /* font-size: 1.3em; */
      text-align: left;
      font-family: "National-Light";
    }
  }
  .bloginner_sec2 {
    margin-top: 4.313em;
    .heading_arrow_flex {
      justify-content: center;
    }
    .gallery_slider_wrapper {
      margin-right: 0;
      .navigations {
        position: relative;
        // top: 35em;
        // transform: translateX(-50%);
        button {
          margin: 0 2em;
          color: #1e5093 !important;
        }
        .left_arrow:after {
          border: 2px dashed #1e5093;
        }
        .right_arrow::before {
          border: 2px dashed #1e5093;
        }
      }
      .arrows_wrapper {
        margin-top: 2.9em;
        margin-bottom: 3em;
        margin-right: 0;
        display: flex;
        justify-items: center;
        justify-content: center;
        img {
          max-width: 4.438em;
        }
        .galleryleftarrowimg {
          margin-right: 0.8em;
        }
        .galleryrightarrowimg {
          margin-left: 0.8em;
        }
      }
    }
  }
  .bloginner_sec3 {
    .horizontal_line {
      display: none;
    }
    .heading_arrow_flex2 {
      justify-content: center;
    }
    .moreblogs_wrapper {
      .navigations {
        position: relative;
        // top: 35em;
        // transform: translateX(-50%);
        button {
          margin: 0 2em;
          color: #1e5093 !important;
        }
        .left_arrow:after {
          border: 2px dashed #1e5093;
        }
        .right_arrow::before {
          border: 2px dashed #1e5093;
        }
      }
      .arrows_wrapper {
        margin-top: 2.9em;
        margin-right: 0;
        display: flex;
        justify-items: center;
        justify-content: center;
        img {
          max-width: 4.438em;
        }
        .leftarrowimg {
          margin-right: 0.8em;
        }
        .rightarrowimg {
          margin-left: 0.8em;
        }
      }
      .moreblogs_swiper_container {
        .moreblogs_swiper_wrapper {
          .moreblog_detail_wrapper {
            max-width: 100%;
            .text_container {
              font-size: 1.3em;
              font-family: "National-Light";
            }
          }
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .bloginner_sec1 {
    .social_icon_date {
      .social_main_wrapper {
        .social_icons {
          .social_icon_li {
            .social_icon img {
              margin-right: 0;
              max-width: 3em;
            }
          }
        }
      }
    }
  }
  .social_icons li:not(:first-child) {
    margin-left: 1.9em;
  }
  .bloginner_sec1 .social_icon_date .col-md-5 .share_title {
    font-size: 2em;
    margin-bottom: 0.4em;
  }
  .bloginner_sec1 .blog_inner_name {
    font-size: 2.4em;
    text-align: left;
  }
}
