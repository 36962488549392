@import "../../common.css";
.dash {
  width: 4.75em;
  height: 0.3em;
  display: inline-block;
}
@media (max-width: 767px) {
  .dash {
    width: 2.8em;
    height: 0.3em;
  }
}
