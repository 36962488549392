/* Importing Common CSS File */
@import url("../../common.css");
.popup_form_wrapper4 {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  overflow: hidden;
  z-index: 100;
  /* position: absolute; */
}

.products_popup4 {
  width: 100%;
  max-width: 60%;
  height: 100%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1;
  position: relative;
}

.products_popup4 .wrapper {
  max-width: 90%;
  width: 100%;
  padding: 3.75em 0 5.625em;
}

.products_popup4 .img_and_close_button {
  position: relative;
}

.products_popup4 .img_and_close_button button {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin: 0.75em 0.75em 0 0;
  position: absolute;
  border: 2px solid #fff;
  top: 0;
  right: 0;
  background-color: #fff;
  color: #002b5a;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.products_popup4 .img_and_close_button button span {
  margin-top: -0.2em
}

.products_popup4 figure {
  max-width: 100%;
  width: 100%;
}

.products_popup4 p {
  margin-top: 1.375em;
  font-family: "National-Light";
  font-size: 1.375em;
  line-height: 1.27em;
}

.products_popup4 .product_items {
  margin-top: 3.0625em;
}

@media (max-width: 768px) {
  .products_popup4 .img_and_close_button button span {
    margin-top: -0.02em;
  }
}
