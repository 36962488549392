.invest_acc_container {
  .invest_acc {
    font-family: "National-Normal";
    .accordion-item {
      border: 1px solid #707070;
      border-bottom: 0.625em solid #012b5b;
      margin-bottom: 2.5em;
      padding: 1.875em 2.5em 0;
      &:last-child {
        margin-bottom: 0;
      }
      .accordion-header {
        font-size: inherit;
        padding-bottom: 1.875em;

        .accordion-button {
          border: none;
          background-color: transparent;
          width: 100%;
          max-width: 100%;
          text-align: left;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 2.5em;
          color: #000000;
          // padding: 0.75em 0;
          &.collapsed {
            .arrow {
              transform: rotate(180deg);
              transition: 0.3s ease;
            }
          }
        }
        .arrow {
          width: 100%;
          max-width: 0.525em;
          margin-left: 0.5em;
          transition: 0.3s ease;
        }
      }
      .accordion-body {
        border-top: 1px solid #707070;
        padding: 2.5em 0;
        .pdf_container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1.75em;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .pdf_text_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // width: 100%;
          // max-width: 70%;
          margin-right: 0.625em;
        }
        .pdf_icon {
          width: 100%;
          max-width: 2.4375em;
        }
        .pdf_title {
          color: #000000;
          font-size: 2em;
          font-family: "National-Normal";
          letter-spacing: 0px;
          line-height: 1.219;
          margin-left: 0.625em;
        }
        .pdf_link {
          display: block;
          text-decoration: underline;
          // width: 100%;
          // max-width: 30%;
          color: #012b5b;
          font-size: 1.25em;
          font-family: "National-Normal";
          letter-spacing: 0px;
          line-height: 1.2;
          text-align: right;
        }
        .dwn_icon {
          width: 100%;
          max-width: 1.05em;
          min-width: 1.05em;
          margin-left: 0.5em;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .invest_acc_container {
    .invest_acc {
      .accordion-item {
        padding: 1.875em 1.6em 0;
        .accordion-header {
          .accordion-button {
            font-size: 2.2em;
          }
          .arrow {
            max-width: 0.455em;
          }
        }
        .accordion-body {
          .pdf_title {
            font-size: 1.8em;
          }
          .pdf_icon {
            max-width: 2em;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .invest_acc_container {
    .invest_acc {
      .accordion-item {
        padding: 2em 1.6em 0;
        border-bottom: 0.5em solid #012b5b;
        margin-bottom: 2em;
        .accordion-header {
          padding-bottom: 1.6em;
          .accordion-button {
            font-size: 2.2em;
            letter-spacing: 0px;
            line-height: 1.227;
          }
          .arrow {
            max-width: 0.5em;
          }
        }
        .accordion-body {
          padding: 3em 0 2em;
          .pdf_container {
            margin-bottom: 2.4em;
          }
          .pdf_title {
            font-size: 2em;
            letter-spacing: 0px;
            line-height: 1.2;
            margin-left: 0.45em;
          }
          .pdf_icon {
            max-width: 2.357em;
          }
          .dwn_icon {
            max-width: 1.093em;
            min-width: 1.093em;
            margin-left: 0.8em;
          }
        }
      }
    }
  }
}
