@import url("../../common.css");

.stock_box {
    background-color: #012B5B;
    margin-top: 5.625em;
    color: #fff;
    padding: 3.75em;
    padding-bottom: 5.563em;


    .stock_light_heading {
        font-weight: 300;
        font-size: 2em;
        // opacity: .8;
        margin-bottom: 0.625em;
        line-height: 1.219em;
        font-family: "National-Light";

    }

    .stock_md_heading {
        // font-weight: 500;
        font-size: 2em;
        font-family: "National-Medium";
        line-height: 1.219em;
    }

    .stock_rate_graph_wrapper {
        position: relative;
        min-width: 100%;
        /* background-color: black; */
        width: 46.188em;
        margin-left: -3.75em;
        padding-left: 3.75em;
        margin-top: 2.5em;

        .stock_rate {
            font-size: 6.25em;
            // font-weight: 500;
            font-family: "National-normal";
            margin-bottom: 0.04em;
        }

        .stock_graph_img {
            position: absolute;
            top: -19%;
            left: 0;
        }
    }

    .stock_up_down_percentage {
        color: #D82F44;
        font-size: 1.75em;
        font-family: "National-Normal";
        display: flex;
        align-items: center;
        line-height: 1.179em;
        margin-top: 1em;

        .stock_d_icon {
            width: 0.536em;
            height: 0.357em;
            margin-right: 0.214em;
            margin-top: 0%;
        }
    }

    .date_stock_wrapper {
        display: flex;
        margin-top: 2.688em;
        font-family: "National-Light";

        .date_stock {
            font-size: 1.75em;
            margin-right: 0.429em;
        }

        .time_stock {
            font-size: 1.75em;
        }
    }

    .growth_stock {
        color: #19AF55;
    }
}

@media  (max-width:767px){
    .stock_box {
        padding: 4em 0 4em 3.2em;
    }
}