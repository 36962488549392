/* Importing Common CSS File */
@import url("../../common.css");

.product_listing {
  margin-top: 8em;
  background-color: #f0f6fd;
}

.product_listing .wrapper {
  padding: 3.5em 0 9.8125em;
}

.products {
  margin-top: 3.125em;
}

.products ul {
  display: flex;
  justify-content: space-between;
  flex-basis: 45%;
  flex-wrap: wrap;
}

.products ul li {
  position: relative;
  flex-basis: 45%;
  margin-top: 5em;
}

.products ul li:first-child h3 {
  margin-top: 0.5em;
}

.products ul li:first-child p {
  margin-top: 1em;
  font-size: 1.45em;
}

h3 {
  font-family: "National-Medium";
  font-size: 2.28em;
  line-height: 1.2em;
}

p {
  font-family: "National-Light";
  font-size: 1.137em;
  line-height: 1.65em;
}

.diff_size_p {
  font-family: "National-Light";
  font-size: 1.137em;
  line-height: 1.65em;
  margin-top: 1em;
  font-size: 1.561em;
}

.products_content h3 {
  font-family: "National-Medium";
  font-size: 2.664em;
  line-height: 1.2em;
}

.products_content p {
  font-family: "National-Light";
  font-size: 1.332em !important;
  line-height: 1.65em;
}

.products .products_content {
  /* padding: 6em 4.9375em 6em 4.6875em; */
  padding: 0 8.9375em 0 4.6875em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-transform: initial;
}

.products .products_content .puro_icon {
  max-width: 2.5em;
  display: block;
}

.product_listing .products .link_box {
  width: 100%;
  max-width: 100%;
}

.product_listing .products a {
  /* margin-top: 2em; */
  position: relative;
  color: #fff;
  font-family: "National-Medium";
  font-size: 1.173em;
  line-height: 2.77em;
  text-decoration: none;
  text-transform: uppercase;
}

.product_listing .products .new_link {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  font-family: inherit;
  text-decoration: underline;
}

.product_listing .products .new_link:hover {
  /* font-family: "National-Medium"; */
  font-weight: bold;
}

.product_listing .products .no_link {
  font-family: "National-Light";
  font-size: 1.137em;
  line-height: 1.65em;
  color: #fff;
  margin-top: 0;
  text-transform: initial;
}

.product_listing .products .knowmore_cta {
  color: #002b5a;
  line-height: 2.2em;
  font-size: 1.6em;
}

.products .products_content p {
  margin-top: 2em;
}

.bottom_arrow_container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.bottom_arrow_container:hover .arrow {
  transform: scaleX(1.2);
}

.bottom_arrow_container .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left;
  transition: 0.25s ease-in-out;
}

.bottom_arrow_container_last {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.bottom_arrow_container_last:hover .arrow {
  transform: scaleX(1.2);
}

.bottom_arrow_container_last .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left;
  transition: 0.25s ease-in-out;
}

/* .products .products_content a:after {
    content: url("../../images/Home/Arrow\ white.svg");
    max-width: 8.1875em;
    width: 100%;
    position: absolute;
    top: 1.5em;
    left: 0;
} */

/* .products ul li:first-child {
  margin-top: 1.25em;
}
.products ul li:nth-child(3) {
  margin-top: 1.25em;
}
.products ul li:nth-child(4) {
  margin-top: 5em;
}
.products ul li:nth-child(5) {
  margin-top: 1.25em;
}
.products ul li:nth-child(7) {
  margin-top: 1.25em;
}
.products ul li:nth-child(6) {
  margin-top: 5em;
}
.products ul li:nth-child(8) {
  margin-top: 5em;
} */

/* .products ul li:last-child h3 { color: #1C1C1C; } */
/* .products ul li:last-child a { color: #002B5A; } */
/* .products ul li:last-child .bottom_arrow_container { margin-top: -2.5em; } */

.product_listing .broch_link {
  margin-top: 1em;
}

.subhead {
  font-family: "National-Medium";
  font-size: 1.875em;
}

.products_content .revir_icon {
  max-width: 5em;
  margin: 0 0 0.3em 0.1em;
}

.borndyed_icon {
  max-width: 15px;
  margin-bottom: 0.2em;
}

.dry_cool_Product_icon {
  max-width: 5em;
  margin: 0 0 0.3em 0.1em;
}

.many_things {
  margin-top: 3em;
}

.many_things h3 {
  font-family: "National-Medium";
  font-size: 3.125em;
  line-height: 1.2em;
}

.product_listing .product_img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1536px) {
  .product_listing .hollow_title {
    font-size: 7em;
  }
}

@media (max-width: 1024px) {
  .products ul .product_lists figure img {
    min-height: 42em;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .products .products_content {
    padding: 0 2em 0 2.2em;
  }
}

@media (max-width: 834px) {
  .products ul {
    display: flex;
    justify-content: space-between;
    flex-basis: 45%;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }

  .products .products_content {
    padding: 0 1.8em 0 1.9em;
  }

  .many_things {
    margin-top: 4.9em;
  }

  .many_things h3 {
    font-family: "National-Medium";
    font-size: 3.125em;
    line-height: 1.2em;
  }

}

@media (max-width: 767px) {
  .product_listing .wrapper {
    padding: 3.5em 2.26em 9.8125em;
  }
}

@media (max-width: 600px) {
  .products .products_content .diff_size {
    /* font-size: 0.9em !important; */
    margin-top: 0;
  }

  .diff_size_p {
    font-size: 1.56em;
    color: #fff;
    font-family: "National-Light";
    text-transform: initial;
    line-height: 1.7em;
  }

  .product_listing .hollow_title {
    font-size: 5em;
  }

  .product_listing .wrapper {
    padding-top: 2em;
  }

  .products {
    margin-top: 0;
  }

  .products ul {
    flex-flow: column;
  }

  .products_content .revir_icon {
    max-width: 5em;
  }

  .products .products_content .puro_icon {
    max-width: 2.2em;
  }

  .borndyed_icon {
    max-width: 7px;
  }

  .dry_cool_Product_icon {
    max-width: 5em;
  }

  .strecth_icon {
    max-width: 7em;
  }

  .products ul li:first-child h3 {
    font-size: 2.4em;
    line-height: 1.25em;
  }

  .products ul li:not(:first-child) {
    margin-top: 4.9em;
  }

  .products .products_content {
    padding: 2em 3.8em 2em 2.9em;
  }

  .products_content h3 {
    font-size: 1.6em;
    line-height: 1.5em;
  }

  .products .products_content p {
    font-size: 1.3em;
    line-height: 1.538em;
  }

  .products .products_content a {
    font-size: 1.164em;
    line-height: 3.35em;
  }

  /* .products ul li:last-child h3 {
        font-size: 2em;
        line-height: 3.5em;
    } */

  .products ul li:last-child .bottom_arrow_container {
    /* margin-top: -4em; */
  }


  .subhead {
    font-size: 1.1em;
  }

  .products .products_content p {
    margin-top: 1.3em;
  }

  .products_content .reviro_button {
    margin-top: 0;
  }

  .product_listing .drycool_product {
    height: 100%;
    min-height: 34em;
  }

  .product_listing .cdp_img {
    object-position: right;
  }
}

@media (max-width: 376px) {
  /* .products ul .product_lists {
    min-height: 35em;
  } */

  .products ul .product_lists figure img {
    min-height: 40em;
    height: 100%;
  }
}