/* Importing Common CSS File */
@import url("../../common.css");
.popup_form_wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #1C1C1C 0% 0% no-repeat padding-box;
    opacity: 0.97;
    overflow: hidden;
    z-index: 100;
}

.products_popup {
    max-width: 72.0625em;
    width: 100%;
    min-height: 26.4375em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1;
}

.products_popup .wrapper {
    max-width: 57.625em;
    width: 100%;
    padding: 3.75em 0 5.625em;
    margin-left: 3.5625em;
}

.products_popup .close_button { position: relative; }

.products_popup .close_button button {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin: 0.75em 0.75em 0 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    color: #002B5A;
    font-size: 1.5em;
}

.products_popup figure { 
    max-width: 72.0625em; 
    width: 100%;
}

.products_popup p {
    margin-top: 1.375em;
    font-family: 'National-Light';
    font-size: 1.375em;
    line-height: 1.27em;
}

.products_popup .product_items { margin-top: 3.0625em; }
.products_popup .product_items ul { margin-top: 0.875em; }

.products_popup .product_items ul li { 
    margin-left: 1.3em;
    display: list-item;
    list-style-type: disc;
}

.products_popup ul li p { margin-top: 0.375em; }