/* Importing Common CSS File */
@import url("../../common.css");

/* @keyframes change {
  0%,
  12.66%,
  100% {
    transform: translateY(0);
  }
  16.66%,
  29.32% {
    transform: translateY(-25%);
  }
  33.32%,
  45.98% {
    transform: translateY(-50%);
  }
  49.98%,
  62.64% {
    transform: translateY(-75%);
  }
  66.64%,
  79.3% {
    transform: translateY(-50%);
  }
  83.3%,
  95.96% {
    transform: translateY(-25%);
  }
} */

.style_heading_of_banner_content {
  font-family: "National-SemiBold";
}

.left_section {
  display: inline-block;
}

.second_slider .second_slider_wrapper_content {
  width: 100%;
  /* min-height: 53.313em; */
  /* padding: 8.6875em 5.375em 14.6875em 5.4375em; */
  display: inline-block;
  background-image: url("../../images/General/homeslider1.jpg");
  background-size: cover;
}

.second_slider .second_slider_wrapper_content1 {
  width: 100%;
  /* min-height: 53.313em; */
  /* padding: 10.6875em 5.375em 14.6875em 5.4375em; */
  display: inline-block;
  background-image: url("../../images/General/homeslider2.jpg");
  background-size: cover;
}

.second_slider .second_slider_wrapper_content2 {
  width: 100%;
  /* min-height: 53.313em;
  padding: 10.6875em 5.375em 14.6875em 5.4375em; */
  display: inline-block;
  background-image: url("../../images/General/homeslider3.jpg");
  background-size: cover;
}

.second_slider .second_slider_wrapper_content3 {
  width: 100%;
  /* min-height: 53.313em;
  padding: 10.6875em 5.375em 14.6875em 5.4375em; */
  display: inline-block;
  background-image: url("../../images/Product\ Listing/Group 3540.jpg");
  background-size: cover;
}

.second_slider .second_slider_wrapper_content4 {
  width: 100%;
  /* min-height: 53.313em;
  padding: 10.6875em 5.375em 14.6875em 5.4375em; */
  display: inline-block;
  background-image: url("../../images/Product\ Listing/BornDyed.jpg");
  background-size: cover;
}

.second_slider .second_slider_wrapper_content5 {
  width: 100%;
  /* min-height: 53.313em;
  padding: 10.6875em 5.375em 14.6875em 5.4375em; */
  display: inline-block;
  background-image: url("../../images/Product\ Listing/dry_cool_small_2.jpg");
  background-size: cover;
}

.second_slider .second_slider_wrapper_content6 {
  width: 100%;
  display: inline-block;
  background-image: url("../../images/Product\ Listing/sflex_thumb_home_2.png");
  background-size: cover;
  background-position: bottom;
}

.second_slider .second_slider_wrapper_content7 {
  width: 100%;
  display: inline-block;
  background-image: url("../../images/Product\ Listing/cdp-home-thumb-1.png");
  background-size: cover;
}

.home_sec2 {
  /* background-color: #f0f6fd; */
  overflow: hidden;
}

.home_sec2_mobile_1 {
  display: none;
}

.home_sec2_mobile_2 {
  display: none;
}

.home_sec2 .wrapper {
  padding: 10.5625em 0 60em;
  position: relative;
}

.home_sec2_first_row {
  display: flex;
  justify-content: space-between;
}

.home_sec2_first_row_mobile {
  display: none;
}

.home_sec2_first_row h3 {
  font-family: "National-Normal";
  max-width: 40.9375em;
  font-weight: 600;
  font-size: 2.28em;
  line-height: 1.26em;
  color: #1c1c1c;
}

.home_sec2_first_row_desc {
  max-width: 30em;
  text-align: right;
}

.home_sec2_first_row .home_sec2_first_row_desc p {
  color: #1c1c1c;
  font-family: "National-Light";
  font-size: 1.364em !important;
  line-height: 1.5em;
  text-transform: uppercase;
}

.bottom_arrow_container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.bottom_arrow_container:hover .arrow {
  transform: scaleX(1.2);
}

.bottom_arrow_container .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left;
  transition: 0.25s ease-in-out;
}

.home_sec2 a,
.home_sec3 a {
  margin-top: 1em;
  position: relative;
  color: #002b5a;
  font-family: "National-Medium";
  font-size: 1.375em;
  line-height: 2.77em;
  text-decoration: none;
  text-transform: uppercase;
}

.home_sec3 a,
.home_sec2_fourth_row_left a {
  margin-top: 3.125em;
  color: #fff;
}

.home_sec3 a {
  margin-top: 0em;
}

.home_sec2_second_row {
  margin-top: 0em;
}

.third_row {
  margin-top: 10em;
}

.home_sec2_second_row_mobile {
  display: none;
}

.home_sec2_second_row_content {
  display: flex;
  justify-content: space-around;
  align-items: end;
}

.home_sec2_second_row_content li {
  flex-basis: 30%;
}

.home_sec2_second_row_content li:nth-child(2) {
  text-align: center;
}

.home_sec2_second_row_content li:nth-child(2) img {
  max-width: 11.291875em;
}

.home_sec2_second_row_content li:nth-child(3) {
  text-align: right;
}

.home_sec2_second_row_content h3 {
  color: #002b5a;
  font-weight: 600;
  font-size: 3.637em;
  line-height: 1.5em;
}

.home_sec2_second_row_content h3 span {
  font-family: "Gordita-Bold";
}

.home_sec2_second_row_content h5 {
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.364em;
  line-height: 1.5em;
  text-transform: uppercase;
}

.home_sec2_second_row_content li:nth-child(1) h5 {
  margin-bottom: 3em;
}

.home_sec2_second_row_content li:nth-child(3) h5 {
  margin-bottom: 3em;
}

.home_sec2_second_row_content li:nth-child(2) h5 {
  margin-top: 1.2em;
  /* text-align: center; */
}

.third_row li:nth-child(2) h5 {
  margin-top: 0;
  margin-bottom: 1.6em;
}

.third_row li:nth-child(3) h5 {
  margin-top: 0;
  margin-bottom: 1.6em;
}

.third_row li:nth-child(1) h5 {
  margin-bottom: 0;
  /* margin-bottom: 1em; */
}

.home_sec2_second_row_content p {
  color: #505050;
  font-family: "National-Light";
  font-size: 1.45em;
  line-height: 1.4em;
}

.home_sec2_third_row {
  margin-top: 5em;
}

.home_sec2_third_row_img_container {
  margin-top: 0;
}

.home_sec2_third_row_img_container li:not(:first-child) {
  margin-left: 7.0625em;
}

.home_sec2_third_row_img_container li {
  max-width: 41.6875em;
  width: 100%;
}

.home_sec2_third_row_img_container li figure {
  max-width: 41.6875em;
  width: 100%;
  overflow: hidden;
}

.home_sec2_third_row_img_container li a {
  margin-top: 0;
  position: static;
  color: unset;
  font-family: "National-Medium";
  font-size: 1.375em !important;
  line-height: 1.525em;
  text-decoration: none;
  text-transform: capitalize !important;
}

.home_sec2_third_row_img_container li figure img {
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

.home_sec2_third_row_img_container li figure img:hover {
  transform: scale(1);
}

.home_sec2_third_row_img_container figcaption {
  margin-top: 0.5em;
  color: #fff;
  font-family: "National-Medium";
  font-size: 2em;
  line-height: 1.525em;
}

.home_sec2_fourth_row {
  padding: 26.8125em 0 7.313em;
  margin: -18% 0 0 -7%;
  display: flex;
  justify-content: space-between;
  background-image: url("../../images/Home/bg.jpg");
}

.home_sec2_fourth_row_mobile {
  display: none;
}

.home_sec2_fourth_row .wrapper {
  max-width: 1580px;
  padding: 0;
  position: relative;
}

.home_sec2_fourth_row h4 {
  color: #fff;
  font-family: "National-Normal";
  font-size: 3.125em;
  line-height: 1.2em;
}

.home_sec2_fourth_row_left,
.home_sec2_fourth_row_right {
  display: inline-block;
}

.home_sec2_fourth_row_left {
  max-width: 40em;
  margin-top: 5em;
  vertical-align: top;
}

.home_sec2_fourth_row_right {
  margin-bottom: -18.125em;
  margin-left: 8em;
  position: relative;
}

.home_sec2_fourth_row_right .playicon {
  max-width: 4.375em;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.home_sec2_fourth_row_left p {
  margin-top: 1.3125em;
  color: #fff;
  font-family: "National-Light";
  font-size: 1.45em;
  line-height: 1.65em;
}

.home_sec2_fourth_row_left a,
.home_sec2_fourth_row_right_link a {
  margin-top: 1.5em;
  font-size: 1.375em;
  line-height: 2.77em;
}

.home_sec2_fourth_row_left a {
  color: #fff;
  margin-top: 0em;
}

.home_sec2_fourth_row_right {
  max-width: 41.6875em;
  width: 100%;
}

.home_sec2_fourth_row_right_link {
  position: absolute;
  right: 39.7em;
  bottom: -23em;
}

.home_sec2_fifth_row {
  padding: 11.1875em 0 35em;
  padding-bottom: 3em;
}

.home_sec2_fifth_row_heading .hollow_title {
  font-size: 12.5em;
  line-height: 0.89em;
}

.home_sec2_fifth_row_swiper_wrapper .swiper-slide Link {
  max-width: 26.125em;
  /* width: 100% !important; */
  /* min-height: 36.25em; */
  position: relative;
  transition: 0.5s ease-in-out;
  transform-origin: left;
}

.home_sec2_fifth_row .swiper-slide Link figure {
  max-width: 26.125em;
  /* max-width: 100%; */
  width: 100%;
  /* min-height: 36.25em; */
}

.home_sec2_fifth_row .swiper-slide Link figure img {
  min-height: 30.25em;
  margin-top: 0;
}

.home_sec2_fifth_row_slider_content,
.home_sec2_fifth_row_mobile_slider_content {
  position: absolute;
  bottom: 2em;
  transition: 3s ease-in;
}

.fadeIn-bottom {
  padding: 0 1.5em;
  margin-top: -0.5em;
  display: none;
  color: #fff;
  font-family: "National-Light";
  font-size: 1em;
  line-height: 1em;
}

.fadeIn-bottom li {
  display: list-item;
}

.fadeIn-bottom p {
  line-height: 1.4em;
}

.home_sec2_fifth_row_swiper_wrapper .swiper-slide:hover .fadeIn-bottom {
  display: block;
}

.home_sec2_fifth_row_swiper_wrapper
  .swiper-slide:hover
  .home_sec2_fifth_row_slider_content {
  animation: productbox 0.6s ease;
  -webkit-animation: productbox ease 0.6s;
  position: absolute;
}

@keyframes productbox {
  100% {
    bottom: 2em;
  }

  0% {
    bottom: -3.8em;
  }
}

.home_sec2_fifth_row_slider_content h5,
.home_sec2_fifth_row_mobile_slider_content h5 {
  padding: 0 1em;
  color: #fff;
  font-family: "National-Medium";
  font-size: 1.8125em;
  line-height: 2.1em;
}

.home_sec3 {
  margin-top: -42em;
}

.home_sec3_content {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}

.home_sec3_left_content {
  max-width: 40%;
  padding: 8em 3em;
  background-color: #002b5a;
}

.home_sec3_left_content h3 {
  color: #fff;
}

.home_sec3_left_content p {
  margin-top: 1em;
  color: #fff;
  font-family: "National-Light";
  font-size: 1.625em;
  line-height: 1.26em;
}

.home_sec3_right_content {
  max-width: 60%;
  overflow: hidden;
}

/* .home_sec3_right_content iframe { width: 64.5625em; height: 43em; } */

.home_sec3_right_content img {
  height: 100%;
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

.home_sec3_right_content img:hover {
  transform: scale(1);
}

.home_sec2_fifth_row_heading_mobile {
  display: none;
}

.home_sec2_fifth_row_heading .hollow_title {
  background-image: linear-gradient(
    to right,
    transparent,
    transparent 50%,
    transparent 50%
  );
}

.home_sec4 {
  margin-top: 5em;
}

.home_sec2_first_row_desktop .home_sec2_first_row_desc h3 {
  color: #002b5a;
  font-family: "National-SemiBold";
  font-size: 3.637em;
  line-height: 1.5em;
}

@media (min-width: 1921px) {
  .home_sec2_fourth_row .wrapper {
    max-width: 2140px;
    padding: 0;
    position: relative;
  }

  .home_sec2_fourth_row_right_link {
    right: 59.7em;
    bottom: -19em;
  }
}

@media (min-width: 1601px) and (max-width: 1918px) {
  .home_sec2_fourth_row {
    margin: -18% 0 0 -20%;
  }

  .home_sec2_fourth_row_left {
    margin-left: 17.5em;
  }
}

@media (max-width: 1536px) {
  .home_sec2_fourth_row .wrapper {
    max-width: 1280px;
  }

  .home_sec2_fourth_row_right {
    margin-left: 9.5em;
  }
}

@media (min-width: 1441px) and (max-width: 1536px) {
  .second_slider .second_slider_wrapper_content,
  .second_slider .second_slider_wrapper_content1,
  .second_slider .second_slider_wrapper_content2,
  .second_slider .second_slider_wrapper_content3,
  .second_slider .second_slider_wrapper_content4,
  .second_slider .second_slider_wrapper_content5,
  .second_slider .second_slider_wrapper_content6,
  .second_slider .second_slider_wrapper_content7 {
    min-height: 45.313em;
    max-height: 45.313em;
  }
}

@media (min-width: 1200px) and (max-width: 1535px) {
  .second_slider .second_slider_wrapper_content,
  .second_slider .second_slider_wrapper_content1,
  .second_slider .second_slider_wrapper_content2,
  .second_slider .second_slider_wrapper_content3,
  .second_slider .second_slider_wrapper_content4,
  .second_slider .second_slider_wrapper_content5,
  .second_slider .second_slider_wrapper_content6,
  .second_slider .second_slider_wrapper_content7 {
    min-height: 46.313em;
    max-height: 46.313em;
  }

  .home_sec2_fourth_row .wrapper {
    max-width: 1194.67px;
  }

  .home_sec2_fourth_row_right {
    margin-left: 5em;
  }

  .home_sec2_fourth_row_right_link {
    right: 32.5em;
  }
}

@media (max-width: 1800px) {
  .home_sec2_fourth_row_right_link {
    right: 45em;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .home_sec2_fourth_row_right_link {
    right: 37em;
  }

  .home_sec2_fourth_row .wrapper {
    max-width: 1266.67px;
  }
}

@media (max-width: 1450px) {
  .home_sec2_fourth_row_right_link {
    right: 43.5em;
  }
}

@media (max-width: 1367px) {
  .banner h2:last-child {
    height: 100px;
  }

  .home_sec2_fourth_row_right_link {
    right: 43.5em;
  }

  .home_sec2_fifth_row_swiper_wrapper .swiper-slide .fadeIn-bottom {
    display: block;
  }
}

@media (max-width: 1280px) {
  .banner h2:last-child {
    height: 70px;
  }

  .home_sec2_fourth_row .wrapper {
    max-width: 1061.67px;
  }
}

@media (max-width: 1280px) {
  /* .home_sec2_fourth_row .wrapper {
        max-width: 1074px;
    } */
  .home_sec2_fourth_row_right_link {
    right: 45em;
  }
}

@media (min-width: 993px) and (max-width: 1100px) {
  .home_sec2_fourth_row .wrapper {
    max-width: 885px;
  }

  .home_sec2_fourth_row_right {
    margin-left: 5em;
  }

  .home_sec2_fourth_row_right_link {
    right: 32.5em;
  }
}

@media (max-width: 992px) {
  .home_sec2_fourth_row_left {
    margin-left: 7em;
  }
}

@media (max-width: 991px) {
  .home_sec2 .wrapper {
    padding: 27em 0 60em;
  }

  .home_sec2_fourth_row {
    padding: 14em 0 7.313em;
  }

  .home_sec2_fourth_row .wrapper {
    padding: 6em 0 22em;
  }

  .home_sec2_fourth_row_left {
    margin-top: 0;
  }
}

@media (max-width: 917px) {
  .home_sec2_fourth_row {
    margin: -18% 0 0 0%;
  }

  .home_sec2_fourth_row_left {
    margin-left: 0;
    padding-left: 2.5em;
  }
}

@media (max-width: 834px) {
  .home_sec2 .wrapper {
    padding: 37em 0 60em;
  }
  .home_sec2_fourth_row .wrapper {
    padding: 6em 0 22em;
  }

  .home_sec2_fourth_row_right_link {
    bottom: -20em;
  }
}

@media (max-width: 768px) {
  .fadeIn-bottom {
    display: block;
  }

  .home_sec2_fifth_row_swiper_wrapper .swiper-slide:hover {
    transform: scaleX(1);
  }

  .banner {
    height: 100%;
  }

  .banner .wrapper {
    padding: 11.7em 0 39.1em;
  }

  .left_section {
    display: block;
  }

  .second_slider_wrapper_content2 .revir_icon {
    max-width: 19px;
    margin: 0 0 0.3em 0.1em;
  }

  .banner h2 {
    height: unset;
    margin: 0 auto;
    font-size: 3.6em;
    line-height: 1.2em;
  }

  .banner h2 ul li {
    line-height: 45px;
  }

  .banner h2:last-child {
    height: 50px;
  }

  /* .banner_slider_desktop_view {
    display: none;
  } */

  .banner_slider_mobile_view {
    max-width: 34.3em;
    margin: -35.3em auto 0;
    display: block;
    position: static;
  }

  .first_slider {
    max-width: 13.2em;
  }

  /* .second_slider {
    max-width: 21.1em;
    min-height: 27.8em;
  } */
  .third_slider {
    max-width: 21.1em;
    bottom: 0;
  }

  .first_slider .first_slider_wrapper_image {
    min-height: 40em;
  }

  .first_slider .first_slider_wrapper_image img {
    height: 100%;
  }

  /* .second_slider .second_slider_wrapper_content {
    padding: 9.6875em 4.375em 12.5em 3.4375em;
  } */

  .second_slider .second_slider_wrapper_content_heading {
    font-size: 5.3em;
    line-height: 1.375em;
  }

  .home_sec2 a,
  .home_sec3 a {
    /* .banner_right_section .second_slider a  */
    font-size: 1.4em;
    line-height: 3em;
  }

  /* .banner_right_section .second_slider a {
    margin-top: 0;
  } */

  /* .home_sec2 {
    margin-top: 30em;
  } */
  .home_sec2_first_row {
    flex-flow: column;
  }

  .home_sec2_first_row .cust_h3 h3 {
    font-size: 4em;
    color: #002b5a;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1em;
    font-family: Gordita;
    text-align: center;
    margin-bottom: 0.2em;
  }

  .home_sec2_first_row h3 {
    font-size: 1.72em;
    line-height: 1.25em;
  }

  .home_sec2_first_row_desktop {
    display: none;
  }

  .home_sec2_first_row_mobile {
    display: block;
  }

  .home_sec2_first_row_mobile .home_sec2_first_row_desc {
    max-width: 100%;
    margin-top: 1.8em;
  }

  .home_sec2_first_row_desc p,
  .home_sec3_left_content p {
    font-size: 1.3em;
    line-height: 1.692em;
  }

  .home_sec2_second_row_content {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .home_sec2_second_row_mobile {
    display: block;
  }

  .home_sec2_second_row_mobile
    .home_sec2_second_row_content
    li:first-child
    img {
    max-width: 10em;
  }

  .home_sec2_second_row_mobile .home_sec2_second_row_content p {
    max-width: 25.6em;
  }

  .home_sec2_second_row_mobile .home_sec2_second_row_content {
    text-align: center;
  }

  .home_sec2_second_row_content h5 {
    font-size: 1.364em;
    line-height: 2.625em;
  }

  .home_sec2_second_row_content h3,
  .home_sec2_fifth_row_heading h3,
  .home_sec3_left_content h3 {
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1em;
  }

  .home_sec2_second_row_content li:nth-child(2) h5 {
    margin-top: 0;
  }

  .home_sec2_second_row_mobile
    .home_sec2_second_row_content
    li:not(:first-child) {
    margin-top: 4.7em;
  }

  .home_sec2_third_row_img_container li figure {
    max-width: 34.3em;
    width: 100%;
  }

  .home_sec3 .hollow_title {
    font-size: 5em;
    line-height: 1.88em;
  }

  .home_sec2_third_row_img_container {
    margin-top: 0;
  }

  .home_sec2_third_row_img_container figcaption {
    margin-top: -1em;
    font-size: 1.5em;
    line-height: 4.35em;
  }

  .home_sec2_third_row_img_container li {
    display: block;
  }

  .home_sec2_third_row_img_container li:not(:first-child) {
    margin: 5em 0 0 0;
  }

  .home_sec2 .home_sec2_fourth_row_desktop {
    display: none;
  }

  .home_sec2_fourth_row_mobile {
    display: block;
  }

  .home_sec2_mobile_1 {
    display: block;
    /* padding-top: 3em; */
  }

  .home_sec2_mobile_2 {
    margin-top: -130em;
    display: block;
  }

  .home_sec2_desktop {
    display: none;
  }

  .home_sec2_mobile_1
    .home_sec2_fifth_row_mobile
    .home_sec2_mobile_2
    .home_sec2_fourth_row {
    padding: 65em 0 30em;
    margin: 0;
    display: flex;
    justify-content: space-between;
    background-image: url("../../images/Home/bg.jpg");
  }

  .home_sec2_mobile_2 .wrapper {
    max-width: 712px;
  }

  .home_sec2_fourth_row_left {
    max-width: 100%;
    padding-left: 0;
    /* margin-top: 17em; */
    margin-top: 3em;
  }

  .home_sec2_fourth_row_right {
    margin-left: 0;
  }

  .home_sec2_fifth_row_first_row {
    margin-left: 0;
  }

  .home_sec2_fifth_row_heading {
    flex-flow: column-reverse;
  }

  .home_sec2_fifth_row_swiper_wrapper {
    margin-top: 3em;
  }

  /* .home_sec2_fifth_row_swiper_wrapper .swiper-slide { 
      max-width: 30em; 
    } */

  .home_sec2_fifth_row_heading h3,
  .home_sec3_left_content h3 {
    font-size: 2.4em;
    line-height: 1.25em;
  }

  .home_sec2_fourth_row {
    margin: 0;
  }

  .home_sec3 {
    /* margin-top: -73em; */
    margin-top: 0;
  }

  .home_sec3_content {
    flex-flow: column;
  }

  .home_sec3_left_content {
    max-width: 100%;
  }

  .home_sec3_right_content {
    max-width: 100%;
  }

  /* .home_sec3 a { margin-top: -43em; } */

  .home_sec2_fifth_row_mobile_heading .hollow_title {
    font-size: 5em;
    line-height: 0.94em;
  }

  .home_sec2_mobile_2 .wrapper_2 {
    padding: 13.5625em 0 100em;
    margin-top: -40em;
  }

  .home_sec2_fourth_row_right_link {
    width: 100%;
    left: 0;
    bottom: -4em;
    z-index: 1;
  }

  .home_sec2_second_row_mobile li:last-child {
    text-align: center;
  }

  .home_sec2_fifth_row_heading_mobile {
    display: block;
  }

  .home_sec2_fifth_row_heading_desktop {
    display: none;
  }

  .home_sec2_second_row_content h3 span {
    font-size: 1.5em;
  }

  .home_sec2_third_row {
    margin-top: 3em;
  }

  .home_sec2_fifth_row {
    /* padding-bottom: 3em; */
    padding: 6em 0 3em;
  }

  .home_sec2_mobile_2 .home_sec2_fourth_row {
    padding: 65em 0 30em;
  }

  .home_sec2_mobile_2 .home_sec2_fourth_row .bottom_arrow_container {
    z-index: 1;
  }

  .home_sec2_mobile_2 .home_sec2_fourth_row .bottom_arrow_container a {
    font-size: 1.4em;
    line-height: 3em;
  }

  .home_sec2_fifth_row_mobile_heading .hollow_title {
    background-image: linear-gradient(
      to right,
      transparent,
      transparent 50%,
      transparent 50%
    );
  }

  .home_sec2 .wrapper {
    padding: 26em 0 60em;
  }

  .home_sec2_fourth_row .wrapper {
    padding: 3em 0 6em;
  }

  .home_sec2_mobile_2 .wrapper_2 {
    padding: 13.5625em 0 30em;
  }
}

@media (max-width: 767px) {
  .home_sec2 .wrapper {
    padding: 25em 2.26em 60em;
  }
  .home_sec2_fourth_row .wrapper {
    padding: 3em 0 6em;
  }
  .home_sec2_mobile_2 .wrapper_2 {
    padding: 13.5625em 2.26em 30em;
  }
}

@media (max-width: 600px) {
  .home_sec2_fourth_row {
    /* margin: -18% 0 0 -7%; */
    margin: 0;
  }

  .home_sec2_fourth_row_left {
    padding: 0 2.26em;
  }

  .second_slider .second_slider_wrapper_content7 {
    background-position: right;
  }
}

@media (max-width: 451px) {
  .home_sec2_mobile_2 .wrapper {
    max-width: 330px;
  }

  .home_sec3_left_content {
    padding: 3em;
  }

  .home_sec2_mobile_2 .wrapper_2 {
    padding-bottom: 70em;
  }

  .home_sec3 {
    /* margin-top: -47em; */
    margin: 0;
  }

  .second_slider .bottom_arrow_container {
    margin-top: 0;
  }

  .home_sec2_mobile_2 .wrapper {
    max-width: 300px;
  }

  .home_sec2 .wrapper {
    /* padding: 12em 2.26em 60em; */
    padding: 12em 2.26em 30em;
  }

  .home_sec2_mobile_2 .wrapper_2 {
    max-width: 330px;
    padding: 12em 2.26em 24em;
  }

  .home_sec2_mobile_2 .home_sec2_fourth_row {
    padding: 90em 0 30em;
  }

  .home_sec2_fourth_row .wrapper {
    padding: 12em 2.26em 10em;
  }

  .home_sec2_fourth_row_left {
    padding: 0;
  }
}

@media (max-width: 376px) {
  .home_sec2_mobile_2 .wrapper {
    max-width: 300px;
  }

  .home_sec2_mobile_2 .wrapper_2 {
    max-width: 330px;
  }

  .home_sec3_left_content {
    padding: 3em;
  }

  .home_sec2_mobile_2 .wrapper_2 {
    padding-bottom: 70em;
  }

  .home_sec3 {
    margin-top: -47em;
  }

  .second_slider .bottom_arrow_container {
    margin-top: 0;
  }
}
