/* Importing Common CSS File */
@import url("../../common.css");
.top_footer .wrapper {
  padding-top: 4.625em;
  /* border-bottom: 1px solid #707070; */
}

h6 {
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.375em;
  line-height: 1.227em;
}

.top_footer ul {
  /* margin-top: 1em; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-end;
}

.top_footer a {
  margin-top: 0.3em;
  display: inline-block;
  color: #002b5a;
  font-family: "National-SemiBold";
  font-size: 2.5em;
  line-height: 1.2em;
}

.bottom_footer {
  border-top: 1px solid #707070;
  margin-top: 4em;
}

.bottom_footer .wrapper {
  padding: 2.1875em 0 3.125em;
  /* display: flex;
    justify-content: space-between; */
}

.bottom_footer p {
  color: #1c1c1c;
  font-family: "National-Medium";
}

.bottom_footer .right_mobile {
  display: none;
}

.bottom_footer .left li:not(:first-child),
.bottom_footer .right li:not(:first-child) {
  margin-left: 1.6875em;
}

.bottom_footer .left li {
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.25em;
  line-height: 1.2em;
}

.bottom_footer .left li button {
  border: none;
  background-color: transparent;
}
.top_footer li button {
  border: none;
  background-color: transparent;
  margin-top: 0.3em;
  display: inline-block;
  color: #002b5a;
  font-family: "National-SemiBold";
  font-size: 2.5em;
  line-height: 1.2em;
  padding: 0;
}

.bottom_footer .right li {
  color: #1c1c1c;
  font-size: 1.125em;
  line-height: 1.167em;
  letter-spacing: 0.03em;
}

.top_footer ul li {
  flex-basis: 20%;
}

.top_footer ul li .youtube_img {
  width: 75%;
}
.top_footer ul li .fb_img {
  width: 83%;
}
.top_footer ul li .linkedin_img {
  width: 100%;
}
.top_footer .social_icons li:last-child {
  margin-left: -2em;
}

.social_icons li a {
  max-width: 3.125em;
  display: inline-block;
}

.top_footer h6 {
  font-family: "National-SemiBold";
  font-size: 1.4em;
}

/* .right_desktop { float: right; } */
.right_desktop a {
  color: #1c1c1c;
}
.bottom_footer .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left_mobile {
  display: none;
}

.bottom_footer .left_desktop li,
.bottom_footer .right_desktop li {
  font-family: "National-Normal";
}

@media (max-width: 768px) {
  .top_footer .wrapper {
    padding-top: 5em;
  }
  .top_footer ul {
    flex-flow: column;
    align-items: flex-start;
  }
  .top_footer ul li:not(:first-child) {
    margin-top: 2.4em;
  }
  .top_footer .social_icons li:last-child {
    margin-left: 0;
  }
  .top_footer .social_icons li a {
    /* height: 1em; */
  }
  .top_footer ul li .youtube_img {
    width: 100%;
  }
  .top_footer ul li .fb_img {
    width: 100%;
  }
  h6 {
    font-size: 1.8em;
    line-height: 1.167em;
  }

  .top_footer ul.social_icons {
    max-width: 16.85em;
    margin-top: 0;
    flex-flow: row;
  }

  .top_footer .social_icons li a {
    max-width: 2.5em;
    /* height: 2em; */
  }
  .social_icons li:not(:first-child) {
    margin-left: 2.9em;
  }
  .social_icons li a img {
    height: 100%;
  }

  .top_footer .social_icons li:nth-child(3) a {
    height: 1em;
    /* margin-top: 0; */
  }

  .top_footer ul.social_icons li:not(:first-child) {
    margin-top: 0;
  }

  .top_footer a {
    font-size: 2.5em;
    line-height: 1.23em;
  }

  .bottom_footer .wrapper {
    flex-flow: column;
  }

  .bottom_footer .right li {
    display: block;
  }

  .bottom_footer .right_desktop {
    display: none;
  }

  .bottom_footer .right_mobile {
    margin-top: 1em;
    display: block;
  }

  .bottom_footer .right_mobile li:not(:first-child) {
    margin: 1em 0 0 0;
  }

  .bottom_footer .right_mobile li,
  .bottom_footer .left li button {
    padding: 0;
    color: #1c1c1c;
    font-family: "National-Normal";
    font-size: 1.3em;
    line-height: 1.23em;
  }

  .bottom_footer .right_mobile li a {
    color: #1c1c1c;
  }

  .bottom_footer .left li button {
    font-size: 1.04em;
  }

  .social_icons {
    align-items: center;
  }
  .top_footer .social_icons li:last-child {
    margin-left: 1em;
  }

  .bottom_footer .wrapper {
    align-items: flex-start;
  }
  .left_desktop {
    display: none;
  }
  .left_mobile {
    display: block;
  }

  /* .left_mobile li { display: block; } */
  .left_mobile li:last-child {
    margin-top: 1em;
  }
}

@media (max-width: 767px) {
  .bottom_footer .wrapper {
    padding: 2.1875em 2.26em 3.125em;
  }
}
