.invest_tab_wrapper {
  .tab_name {
    color: #000000;
    font-size: 2.5em;
    font-family: "National-Normal";
    letter-spacing: 0px;
    line-height: 1.25;
    margin-bottom: 1.5em;
    position: relative;
    cursor: pointer;
    padding-right: 0.5em;
    display: block;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "";
      background-color: #012b5b;
      width: calc(100% + 6.25em);
      height: 100%;
      position: absolute;
      // left: -6.25em;
      right: 0.425em;
      top: 0;
      z-index: -1;
      display: none;
      transition: 0.5s ease;
    }
    &::after {
      content: "";
      background-color: #f3f6fa;
      position: absolute;
      right: 0;
      top: 0;
      width: 0.425em;
      height: 100%;
      display: none;
      transition: 0.5s ease;
    }
    &.active {
      color: #ffffff;
      padding: 0.913em 0.5em 0.913em 0;
      &::before {
        display: block;
        transition: 0.5s ease;
      }
      &::after {
        display: block;
        transition: 0.5s ease;
      }
    }
  }
}

@media (max-width: 991px) {
  .invest_tab_wrapper {
    .tab_name {
      font-size: 2.2em;
    }
  }
}


@media (max-width: 767px) {
  .invest_tab_wrapper {
    .tab_name {
      font-size: 2.4em;
      letter-spacing: 0px;
      line-height: 1.417;
      padding-right: 0.417em;
      margin-bottom: 1.667em;
      &.active {
        padding: 0.73em 0.417em 0.73em 0;
      }
      &::after {
        width: 0.417em;
      }
    }
  }
}
