/* Importing Common CSS File */
@import url("../../common.css");
.products_inner {
  padding: 2em 0 3em;
  // margin-top: 9.5em;
  margin: 9.5em 0 3.25em;
  position: relative;
  .left_section {
    max-width: 40%;
    min-height: 48.5em;

    h3 {
      margin-top: 0.5em;
    }
    .capacity {
      margin-top: 1em;
      text-transform: none;
      text-align: left;
      font: normal normal bold 1.625em/3.75em Gordita;
      color: #002b5a;
      opacity: 1;
      line-height: 1em;
      font-weight: 700;
    }
    p {
      /* max-width: 35.25em; */
      margin-top: 1.1875em;
    }
  }

  .arrow {
    display: none;
    max-width: 7em;
    width: 100%;
    min-height: 1.125em;
    margin: 12.68125em 0 0 -3em;
    transform: rotate(90deg);
  }

  .right_section {
    .bg_img {
      max-width: 60.9375em;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .new_link {
    color: inherit;
    font-family: inherit;
    text-decoration: underline;
  }
  .new_link:hover {
    font-weight: bold;
  }

  .broch_link_wrap {
    .broch_link {
      position: relative;
      color: #002b5a;
      font-family: "National-Medium";
      font-size: 1.375em;
      line-height: 2.77em;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        .right_arrow {
          -webkit-transform: scaleX(1.2);
          transform: scaleX(1.2);
        }
      }
    }
    .right_arrow {
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transform-origin: left;
      transform-origin: left;
      transition: 0.25s ease-in-out;
      width: 100%;
      max-width: 100%;
    }
  }
}

.products_inner_list_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    text-align: right;
  }
}

.products_inner_listing {
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 0 0 33.33%;
  }
  div:nth-child(n + 4) {
    margin-top: 2.75em;
  }

  figure {
    max-width: 32.125em;
    width: 100%;
  }

  button {
    border: none;
    background: transparent;
    color: #1c1c1c;
    font-family: "National-Light";
    text-align: left;
    outline: none;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }

    p {
      margin-top: 0.5em;
      font-size: 1.625em;
      line-height: 1.3em;
    }
  }
}
.products_inner_watch_now {
  margin-bottom: 8.15625em;
  figure {
    margin-top: 0;
  }
  .for_mob {
    display: none;
  }
}

@media (max-width: 834px) {
  .products_inner {
    margin-top: 16.5em;
    .left_section {
      max-width: 100%;
      margin-bottom: 3em;
      min-height: auto;
      .hollow_title {
        font-size: 7em;
      }
    }
    .right_section {
      .bg_img {
        position: relative;
      }
    }
    .arrow {
      display: none;
    }
  }
  .products_inner_listing {
    align-items: flex-end;
    justify-content: space-evenly;
    .product_box {
      margin-right: 1em;
    }
  }
  .products_inner_watch_now {
    margin-bottom: 0;
    .for_desk {
      display: none;
    }
    .for_mob {
      display: inline;
    }
  }
}
@media (max-width: 767px) {
  .products_inner {
    padding: 2em 0 9em;
    // margin-top: 8.5em;
    margin: 8.5em 0 5em;
    .hollow_title {
      font-size: 5em;
    }
    .products_inner_p_mobile {
      display: none;
    }
    h3 {
      font-size: 3.125em;
    }
    p {
      margin-bottom: 2em;
      br {
        display: none;
      }
    }
    .products_inner_p_mobile {
      display: block;
    }
    .left_section {
      max-width: 100%;
      p {
        max-width: 100%;
        margin-bottom: 1em;
      }
    }
    .right_section {
      .bg_img {
        max-width: 100%;
        margin-top: 2.2em;
        position: static;
      }
    }
    .products_inner_watch_now {
      margin: 0;
      .hollow_title {
        font-size: 5em;
      }
      figure {
        margin-top: 0;
      }
    }

    .broch_link_wrap {
      .broch_link {
        font-size: 1.4em;
        line-height: 3em;
      }
    }
  }
  .products_inner_list {
    .hollow_title {
      font-size: 5em;
      line-height: 1.3em;
      margin-top: 1.5em;
    }
    h3 {
      font-size: 2.5em;
      text-transform: none;
    }
    br {
      display: none;
    }
    .products_inner_list_heading_wrapper {
      display: block;
      margin-bottom: 0em;
      text-align: center;
      h3 {
        text-align: center;
        max-width: 100%;
      }
    }
    .products_inner_listing {
      margin-top: 2.34375em;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      button {
        padding: 0;
        // margin-bottom: 2.2em;
      }
      div:nth-child(n + 4) {
        margin-top: 2em;
        margin-bottom: 2em;
      }
      .product_box {
        margin: 2em 0;
        P {
          font-size: 2em !important;
          line-height: 1.3em;
          // margin-top: 1em;
        }
      }
    }
  }
  .products_inner_watch_now {
    margin-top: 0;
    .for_desk {
      display: none;
    }
    .for_mob {
      display: inline;
    }
  }
}

@media(max-width:376px) {
  .broch_link_wrap {
    margin-top: 0;
  }
}
