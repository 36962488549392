header {
  img {
    width: 20em;
  }
}
@media (max-width: 767px) {
  header {
    img {
      width: 12em;
    }
  }
}
