@import "../../common.css";
@import "../Newsroom/Newsroom.scss";
.blog_sec2 {
  padding: 12em 0 0em;
  // margin-top: 8.5em;
  .breadcrumb_styles {
    margin: 2em 0;
  }
  .dropdown_flex {
    display: none;
  }

  .blog_details_container {
    padding: 0;
  }
  .blog_detail_wrapper {
    width: 100%;
    max-width: 88%;
    position: relative;
    overflow: hidden;
  }
  .blog_detail_wrapper::before {
    content: "";
    width: 100%;
    background-color: #000;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: -2;
    transition: 0.3s opacity;
  }
  .imageanimate {
    overflow: hidden;
    border-radius: 3px;
    // .content-overlay {
    //   overflow: hidden;
    //   background: rgba(0, 0, 0, 0.6);
    //   position: absolute;
    //   height: 100%;
    //   width: 100%;
    //   left: 0;
    //   top: 0;
    //   bottom: 0;
    //   right: 0;
    //   opacity: 0;
    //   -webkit-transition: all 0.4s ease-in-out 0s;
    //   -moz-transition: all 0.4s ease-in-out 0s;
    //   transition: all 0.4s ease-in-out 0s;
    //   border-radius: 3px;
    // }
    // &:hover .content-overlay {
    //   opacity: 1;
    // }
    &:hover .blog_img {
      transform: scale(1);
    }
  }
  .blog_img {
    width: 100%;
    border-radius: 3px;
    position: relative;
    transform: scale(1.2);
    transition: 0.3s ease-in-out;
  }
  .text_container {
    // position: absolute;
    // bottom: 3.875em;
    bottom: -100%;
    left: 2.688em;
    z-index: 3;
    transition: 0.3s bottom;
    margin-top: 1.75em;
  }
  .blog_date {
    display: inline;
    font-family: "National-Light";
    font-size: 1.57em;
    line-height: 1.5em;
    letter-spacing: 0;
    margin-bottom: 1em;
    color: #333333;
    opacity: 0.8;
  }
  .verticle_line {
    font-size: 1.57em;
    line-height: 1.5em;
    display: inline;
    padding: 0 0.2em;
  }
  .blog_category {
    display: inline;
    font-family: "National-Light";
    font-size: 1.57em;
    line-height: 1.5em;
    letter-spacing: 0;
    margin-bottom: 1em;
    color: #333333;
    opacity: 0.8;
  }
  .blog_name {
    font-family: "National-SemiBold";
    font-size: 1.875em;
    letter-spacing: 0.24px;
    font-weight: 600;
    color: #333333;
    text-transform: capitalize;
    opacity: 1;
    margin-top: 0.75em;
  }
  .blog_synopsis {
    font-family: "National-Light";
    font-size: 1.125em;
    letter-spacing: 0;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    width: 100%;
    color: #333333;
    // opacity: 0.8;
  }
  .blog_detail_wrapper:hover::before {
    opacity: 0.8;
  }
  .blog_detail_wrapper:hover .text_container {
    bottom: 3.875em;
  }
  .blog_cta_wrapper {
    margin-bottom: 6.25em;
    .readmore_button {
      outline: none;
      border: none;
      background: transparent;
      font-family: "National-light";
      font-size: 1.5em;
      font-weight: bold;
      padding: 0;
      &:hover {
        font-weight: 900;
      }
    }
  }
  .horizontal_line {
    border-top: 1px solid black;
    margin-bottom: 3em;
    opacity: 0.35;
  }
  .loadmore_cta_wrapper {
    text-align: center;
    margin-bottom: 3.625em;
  }
}
// @media (max-width: 2561px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         max-height: 35.2em;
//       }
//     }
//   }
// }

// @media (max-width: 1920px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         max-height: 29.7em;
//       }
//     }
//   }
// }
// @media (max-width: 1600px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         max-height: 28.95em;
//       }
//     }
//   }
// }
// @media (max-width: 1450px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         max-height: 29.7em;
//       }
//     }
//   }
// }
// @media (max-width: 1380px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         max-height: 30.6em;
//       }
//     }
//   }
// }
// @media (max-width: 1300px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         max-height: 30em;
//       }
//     }
//   }
// }

// @media (max-width: 1024px) {
//   .blog_sec2 {
//     .imageanimate {
//       .content-overlay {
//         display: none;
//       }
//     }
//   }
// }
@media (max-width: 834px) {
  .blog_sec2 {
    // margin-top: 11em;
    // padding-left: 4em;
    .blog_detail_wrapper {
      max-width: 29em;
    }
  }
}
@media (max-width: 768px) {
  .blog_sec2 {
    // margin-top: 8em;
    .blog_cta_wrapper {
      .readmore_button {
        font-size: 1.6em;
      }
    }
  }
}
@media (max-width: 767px) {
  .blog_sec2 {
    padding-top: 6em;
    // margin-top: 6em;
    .text_container {
      margin-top: 1.5em;
    }
    // padding-left: 4em;
    .blog_detail_wrapper {
      max-width: 29em;
    }
    .blog_name {
      font-size: 1.6em;
      margin-top: 0.8em;
    }
    .blog_synopsis {
      font-size: 1.3em;
      margin-top: 0.6em;
      margin-bottom: 0.6em;
    }
    .blog_cta_wrapper {
      margin-bottom: 2.25em;
    }
    .blog_img {
      border-radius: 6px;
    }
    .blog_detail_wrapper {
      max-width: 100%;
    }
    .horizontal_line {
      display: none;
    }
  }
}
