/* Importing Common CSS File */
@import url("../../common.css");
.our_setup_infrastructure {
  position: relative;
  padding: 2em 0 7.7em;
  margin-top: 8.8em;
  background-color: #f3f6fa;
}

.our_setup_infrastructure .left_section {
  max-width: 50%;
}
.our_setup_infrastructure .left_section h3 {
  margin-top: 0.5em;
}

.our_setup_infrastructure .left_section p {
  max-width: 80%;
  margin-top: 1.1875em;
}

.our_setup_infrastructure .right_section .bg_img {
  /* max-width: 41.0625em; */
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50%;
}

.our_setup_infrastructure .right_section .fg_img {
  max-width: 63.9375em;
  position: absolute;
  right: 0;
  transform: translateY(-30%);
}

.infra_section2 {
  position: relative;
}

.infra_section2 .wrapper {
  padding: 8.5em 0 0;
  position: relative;
}

/* .infra_section2 .infra_section2_second_section {
  min-height: 61.5625em;
  margin-top: 12.28125em;
  position: relative;
  background-color: #002b5a;
} */

/* .infra_section2 .infra_section2_second_section figure {
  max-width: 102.5em; 
  width: 100%;
  position: absolute;
  top: -16em;
}*/

.infra_section2 .infra_section2_third_section {
  margin-top: -25em;
  position: relative;
}

.infra_section2 .infra_section2_third_section .hollow_title {
  opacity: 0.3;
  -webkit-text-stroke-color: #fff;
}

.infra_section2 .infra_section2_third_section .benefits_slider_wrapper li {
  /* max-width: 49.375em; */
  width: 100%;
  min-height: 2.375em;
  height: auto;
  padding: 3.6875em 5.5em 5.3125em 3.375em;
  background-color: #f3f6fa;
}

.infra_section2 .infra_section2_third_section .benefits_slider_wrapper li h4 {
  margin-top: 0.5em;
  font-family: "National-Medium";
  font-size: 2.5em;
  line-height: 1.5em;
}

.infra_section2
  .infra_section2_third_section
  .benefits_slider_wrapper
  li
  figure {
  max-width: 7.5625em;
  width: 100%;
  padding: 25px;
  border-radius: 50%;
  background-color: rgba(0, 64, 131, 0.09);
}

.infra_section2
  .infra_section2_third_section
  .benefits_slider_wrapper
  li
  .sub_description {
  margin: 1.375em 0 0 1em;
  list-style-type: disc;
  list-style-position: outside;
}

.infra_section2
  .infra_section2_third_section
  .benefits_slider_wrapper
  .sub_description
  li {
  padding: 0;
  display: list-item;
  color: #004083;
  list-style-type: disc;
}

.infra_section2
  .infra_section2_third_section
  .benefits_slider_wrapper
  .sub_description
  p {
  color: #1c1c1c;
}

.infra_section2 .infra_section2_third_section .benefits_slider_heading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2em;
}

.infra_section2
  .infra_section2_third_section
  .benefits_slider_heading_section
  .navigations {
  /* margin-top: 2em; */
}

.benefits_slider_wrapper .new_link {
  color: inherit;
  font-family: inherit;
  text-decoration: underline;
}
.benefits_slider_wrapper .new_link:hover {
  font-weight: bold;
}

@media (max-width: 1920px) {
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 6.5em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section {
    max-width: 1720px;
  }
}

@media (max-width: 1536px) {
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section {
    max-width: 1370px;
  }
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 7em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section
    .navigations {
    right: 2em;
    bottom: -36em;
  }
}

@media (max-width: 1480px) {
  /* .benefits_slider_heading_section { max-width: 1290px; } */
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 4.5em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section
    .navigations {
    right: 0;
    bottom: -59em;
  }
}

@media (max-width: 1367px) {
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section {
    max-width: 1290px;
  }
  .infra_section2 .infra_section2_third_section.benefits_slider_wrapper {
    margin-left: 3em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section
    .navigations {
    right: 0;
    bottom: -63em;
  }
}

@media (max-width: 1280px) {
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section {
    max-width: 1146.67px;
  }
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 6.5em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section
    .navigations {
    right: 0;
    bottom: -56em;
  }
}

@media (max-width: 1195px) {
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section {
    max-width: 1127.67px;
  }
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 4em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section
    .navigations {
    right: 0;
    bottom: -61em;
  }
}

@media (max-width: 1194px) {
  .our_setup_infrastructure .right_section .bg_img {
    max-width: 40%;
    height: 100%;
  }
  .our_setup_infrastructure .right_section .bg_img img {
    height: 100%;
  }
  .our_setup_infrastructure .right_section .fg_img {
    max-width: 58%;
  }
}

@media (max-width: 834px) {
  .our_setup_infrastructure {
    margin-top: 7.8em;
  }
  .our_setup_infrastructure .left_section .hollow_title {
    font-size: 12.5em;
  }
  .our_setup_infrastructure .left_section {
    max-width: 100%;
    margin-bottom: 5em;
  }
  .our_setup_infrastructure .right_section .bg_img {
    position: absolute;
    max-width: 34.0625em;
    height: auto;
    top: unset;
    bottom: 0;
    right: 0;
  }
  .our_setup_infrastructure .right_section .fg_img {
    padding-top: 4em;
    position: relative;
    transform: none;
    margin: -4em auto;
    max-width: 63.9375em;
  }
  .our_setup_infrastructure .left_section p {
    margin-bottom: 2em;
    padding-bottom: 1em;
  }
  /* .infra_section2 .infra_section2_second_section {
    min-height: 32.5625em;
  } */
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section {
    padding-right: 1em;
  }
  .infra_section2 .wrapper {
    padding: 0 0 3.5em 0;
  }
  .infra_section2 .hollow_title {
    font-size: 9.5em;
  }
  .our_setup_infrastructure .left_section p {
    max-width: 100%;
    font-size: 1.5em;
  }
}

@media (max-width: 767px) {
  .our_setup_infrastructure .left_section .hollow_title {
    font-size: 5em;
  }
  .our_setup_infrastructure .dash {
    width: 7em;
  }
  .our_setup_infrastructure .right_section .bg_img {
    max-width: 19.0625em;
  }
  .infra_section2 .hollow_title {
    font-size: 5em;
    max-width: 100%;
    line-height: 1;
    text-align: center;
  }
  .infra_section2 .fleet_wrapper {
    text-align: center;
  }
  /* .infra_section2 .infra_section2_second_section {
    margin-top: 17.28125em;
  } */
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 1.6em;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_wrapper
    .swiper-wrapper
    .swiper-slide {
    /* width: 100% !important;
    max-width: 100% !important;
    margin-right: 2em !important;
    padding: 2.6875em 1.5em 2.3125em 1.5em; */
  }
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper li {
    padding: 3.6875em 1.5em 3.3125em 2.375em;
  }
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper li h4 {
    font-size: 1.8em;
    margin-top: 1em;
  }
  .infra_section2 .infra_section2_third_section .infra_section2_second_section {
    position: relative;
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_heading_section
    .navigations {
    /* position: absolute; */
  }
  .infra_section2
    .infra_section2_third_section
    .benefits_slider_wrapper
    .swiper-wrapper {
    /* margin: 1.5em 0 0; */
  }
  .infra_section2 .infra_section2_third_section .benefits_slider_wrapper {
    margin-left: 2em;
  }
}
