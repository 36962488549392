/* Importing Common CSS File */
@import url("../../common.css");
.our_sales_section2 {
  background-color: rgba(30, 80, 147, 0.05);
}
.our_sales_section1 {
  padding: 2em 0 1.75em;
  margin-top: 8.5em;
  position: relative;
}
.clients_navigations {
  padding-right: 6em;
}
.our_sales_section1 .wrapper {
  position: relative;
}
.our_sales_section1 .hollow_title {
  margin-top: 0.2em;
}

.our_sales_section1 .our_sales_heading {
  margin-top: 1.84375em;
  display: flex;
  justify-content: space-between;
  flex-basis: 40%;
}

.our_sales_section1 .our_sales_heading p {
  max-width: 37.25em;
}

.our_sales_section1 .our_sales_heading .new_link {
  color: inherit;
  font-family: inherit;
  text-decoration: underline;
}
.our_sales_section1 .our_sales_heading .new_link:hover {
  font-weight: bold;
}

.our_sales_section1 .map_wrapper {
  max-width: 100.75em;
  width: 100%;
  padding-top: 4em;
  margin: 0 auto;
}

.our_sales_section2 .oursales_slider_wrapper {
  padding: 4.55125em 0 12.663125em 0;
}
.our_sales_section2 .oursales_slider_wrapper ul {
  margin-top: 7em;
}
.our_sales_section2 .oursales_slider_wrapper ul li img {
  max-width: 16.6875em;
}

.our_sales_section2 .oursales_slider_wrapper_heading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.our_sales_section2 .oursales_slider_wrapper .navigations button {
  color: #1e5093 !important;
}
.our_sales_section2 .oursales_slider_wrapper .navigations .left_arrow::after {
  border: 2px dashed #1e5093;
}
.our_sales_section2 .oursales_slider_wrapper .navigations .right_arrow::before {
  border: 2px dashed #1e5093;
}
.our_sales_section2 .oursales_slider_wrapper .swiper-wrapper {
  align-items: center;
}

.our_sales_section2 .oursales_slider_wrapper .navigations_mob {
  display: none;
}

@media (max-width: 1920px) {
  .our_sales_section2 .oursales_slider_wrapper {
    margin-left: 6em;
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section {
    max-width: 1720px;
  }
}

@media (max-width: 1536px) {
  .our_sales_section2 .oursales_slider_wrapper {
    margin-left: 6.5em;
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section {
    max-width: 1370px;
  }
}

@media (max-width: 1480px) {
  .our_sales_section2 .oursales_slider_wrapper {
    margin-left: 4.5em;
  }
}

@media (max-width: 1367px) {
  .our_sales_section2 .oursales_slider_wrapper {
    margin-left: 3em;
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section {
    max-width: 1290px;
  }
}

@media (max-width: 1280px) {
  .our_sales_section2 .oursales_slider_wrapper {
    margin-left: 6em;
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section {
    max-width: 1156.67px;
  }
}

@media (max-width: 1195px) {
  .our_sales_section2 .oursales_slider_wrapper {
    margin-left: 2em;
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section {
    max-width: 1156.67px;
  }
}
@media (max-width: 834px) {
  .our_sales_section2 {
    margin-top: 0;
  }
  .our_sales_section1 .map_wrapper {
    padding: 2em 0 0;
  }
}

@media (max-width: 767px) {
  .clients_navigations {
    padding-right: 0;
  }
  .our_sales_section1 .map_wrapper {
    display: none;
  }
}
/*.our_sales_section1 .hollow_title {
    font-size: 5em;
  }
  .our_sales_section1 .our_sales_heading {
    display: inline-block;
  }
  .our_sales_section2 .oursales_slider_wrapper {
    padding: 4em 0;
  }
  .our_sales_section2 .oursales_slider_wrapper ul {
    margin-top: 5em;
    margin-bottom: 12em;
  }
  .our_sales_section2 .swiper-slide {
    width: 100% !important;
    max-width: 50% !important;
    margin-right: 1em !important;
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section .navigations {
    display: flex;
    position: absolute;
    bottom: 5em;
    transform: translate(68%);
  }
  .our_sales_section2 .oursales_slider_wrapper_heading_section .hollow_title {
    font-size: 5em;
  }
} */
