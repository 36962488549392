@import url("../../common.css");

.ouryarn_sec1 {
  width: 100%;
  height: 67em;
  max-height: 100vh;
  position: relative;
  background-image: url("../../images/Ouryarnpage/Desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .wrapper {
    padding: 22.065em 0;
    position: relative;
  }
  .left_section {
    .heading {
      color: #fff;
      font-family: "National-Normal";
      font-size: 7.5em;
      line-height: 1.2em;
      font-weight: 600;
      overflow: hidden;
      text-transform: capitalize;
      vertical-align: middle;
    }
  }
}
.ouryarn_sec2 {
  background-color: #f0f6fd;
  padding-top: 4.25em;
  .product_inner_sec2 {
    padding: 0 8.75em 8.063em 8.75em;
    .productinner_container {
      margin-bottom: 3.875em;
      .many_head {
        font-family: "National-Medium";
        font-size: 3.125em;
        line-height: 1.2em;
        margin-bottom: 0.5em;
        text-align: center;
      }
      .new_link {
        color: inherit;
        font-family: inherit;
        text-decoration: underline;
        &:hover {
          font-weight: bold;
        }
      }
    }
    .content_cont {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 4.5em;
    }
    .heading {
      margin-top: 0.525em;
      text-transform: capitalize;
      font-family: "National-normal";
      font-weight: bold;
      width: 100%;
      text-align: left;
      font-size: 2.375em;
      letter-spacing: 0px;
      color: #1c1c1c;
      opacity: 1;
    }
    .order-lg-2,
    .order-lg-3,
    .order-lg-1 {
      background-color: #fff;
    }
    .product_inner_img {
      width: 100%;
      max-height: 48em;
    }
    .product_inner_img2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-width: 80%;
      right: 9%;
      max-height: 42.063em;
    }
    .product_inner_img_alternate {
      width: 100%;
      max-height: 48em;
      max-width: 90%;
    }
    .product_inner_img2_alternate {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-width: 80%;
      max-height: 40.063em;
    }
    .productinner_heading_desc {
      margin-top: 0.5em;
      width: 100%;
      max-width: 90%;
      ul {
        line-height: 2.5em;
        li {
          margin-left: 0.5em;
          display: list-item;
          // list-style-type: disc;
          text-align: left;
          font-weight: 300;
          font-size: 1.625em;
          letter-spacing: 0px;
          color: #1c1c1c;
          font-family: "National-Normal";
          opacity: 1;
          &::before {
            content: "\2022";
            color: #0d56a6;
            font-weight: bold;
            display: inline-block;
            width: 0.5em;
            margin-left: -0.5em;
            font-size: 1.5em;
          }
        }
      }
    }
  }
}
.ouryarn_sec3 {
  display: none;
}
@media (max-width: 834px) {
  .ouryarn_sec1 {
    background-image: url("../../images/Ouryarnpage/Mobile.jpg");
    background-size: cover;
  }
}

@media (max-width: 767px) {
  .ouryarn_sec1 {
    background-image: url("../../images/Ouryarnpage/Mobile.jpg");
    background-size: cover;
    .left_section {
      .heading {
        font-size: 3.2em;
        max-width: 50%;
        font-weight: 500;
        line-height: 1em;
        br {
          display: none;
        }
      }
    }
  }
  .ouryarn_sec2 {
    display: none;
  }
  .ouryarn_sec3 {
    display: block;
    padding-top: 4.2em;
    padding-bottom: 6em;
    background-color: #f0f6fd;
    .product_inner_sec2 {
      margin: 0 1.6em 4.063em 1.6em;
      .productinner_detail_wrapper {
        background-color: #f0f6fd;
      }
      .productinner_container {
        margin-bottom: 3.875em;
        .mobile_desc {
          padding: 0 1em;
        }
      }
      .content_cont {
        background-color: #fff;
        position: relative;
        height: 100%;
        display: block;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 0.5em;
        padding: 4.4em 2.75em 26.063em 2.75em;
      }
      .heading {
        margin-top: 0.525em;
        text-transform: capitalize;
        font-family: "National-normal";
        font-weight: bold;
        width: 100%;
        text-align: left;
        font-size: 2.2em;
        letter-spacing: 0px;
        color: #1c1c1c;
        opacity: 1;
      }
      .product_inner_img {
        position: absolute;
        width: 100%;
        max-width: 95%;
        max-height: 48em;
        bottom: 0;
        right: 8%;
      }
      .product_inner_img2 {
        position: absolute;
        bottom: 0;
        width: 100%;
        max-width: 80%;
        left: 8%;
        max-height: 42.063em;
      }
      .productinner_heading_desc {
        margin-top: 0.5em;
        margin-bottom: 4.4em;
        width: 100%;
        max-width: 100%;
        ul {
          line-height: 2em;
          li {
            margin-left: 1.3em;
            line-height: 1.6em;
            display: list-item;
            // list-style-type: disc;
            text-align: left;
            font-weight: 300;
            font-size: 1.4em;
            letter-spacing: 0px;
            color: #1c1c1c;
            font-family: "National-Normal";
            opacity: 1;
            &::before {
              content: "\2022";
              color: #0d56a6;
              font-weight: bold;
              display: inline-block;
              width: 0.5em;
              margin-left: -1em;
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
}
