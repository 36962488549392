@import "../../common.css";

.common_banner {
  position: relative;
  margin-top: 10.5em;
  .disabled_link {
    pointer-events: none;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    opacity: 0.1;
    top: 0;
    left: 0;
  }
  .common_banner_img {
    width: 100%;
    object-fit: cover;
  }
  .breadcrumb_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
  }
  .common_breadcrumb_link {
    font-size: 1.111em;
    line-height: 0.842em;
    letter-spacing: 1.6px;
    font-family: "National-SemiBold";
    font-weight: 400;
    color: #fff;
    opacity: 1;
    text-decoration: none;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .common_breadcrumb_line2 {
    font-size: 2.5em;
    line-height: 1.175em;
    letter-spacing: 0.45px;
    font-family: "National-SemiBold";
    font-weight: 600;
    margin-top: 0.5em;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 0.9;
  }
}

@media (max-width: 1920px) {
  .common_banner {
    margin-top: 10em;
  }
}
@media (max-width: 1600px) {
  .common_banner {
    margin-top: 9.6em;
  }
}
@media (max-width: 767px) {
  .common_banner {
    margin-top: 8.6em;
    .common_breadcrumb_link {
      font-size: 1.4em;
      line-height: 1.5em;
      letter-spacing: 1.4px;
    }
    .common_breadcrumb_line2 {
      font-size: 2em;
      line-height: 1.3em;
      letter-spacing: normal;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 0.4em;
      text-align: center;
    }
    .common_banner_img {
      min-height: 31em;
    }
  }
}
