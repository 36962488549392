/* Importing Common CSS File */
@import url("../../common.css");
/* html,
body {
  overflow: hidden;
  height: 100%;
} */
.popup_form_wrapper_sales {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 43, 90, 0.2);
  opacity: 1;
  overflow: hidden;
  z-index: 100;
}

.salesEnquiry {
  max-width: 54.3125em;
  width: 100%;
  min-height: 44.1875em;
  /* margin-top: 50em; */
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.salesEnquiry .wrapper {
  padding: 5em 7em 6em 6.4375em;
}

.salesEnquiry .close_button {
  width: 95%;
  margin: 0 auto;
  text-align: right;
}

/* .salesEnquiry .close_button button {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-top: 0.5625em;
    background-color: #002B5A;
    color: #fff;
    font-size: 1.5em;
} */

.salesEnquiry .contact_wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.salesEnquiry .contact_wrapper .PhoneInputInput {
  height: 1.4375em;
  padding: 4px 0 5px;
  font-size: 1rem;
  outline: none;
}

.salesEnquiry .contact_wrapper .PhoneInput {
  border-bottom: 2px solid transparent;
  margin-top: 3.5em;
}
.salesEnquiry .contact_wrapper .PhoneInput:hover {
  /* border-bottom: rebeccapurple; */
  overflow: hidden;
}
.css-1480iag-MuiInputBase-root-MuiInput-root:hover:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.salesEnquiry .form_wrapper form .message_wrapper {
  margin-top: 1em !important;
}

.salesEnquiry .form_input_wrapper:nth-child(2) {
  margin-top: 1.5em;
}
.salesEnquiry .form_input_wrapper:nth-child(3) {
  margin-top: 2em;
}

.popup_form_wrapper_sales .btn-close {
  margin-top: 1em;
  float: right;
  display: inline-block;
  /* float: right; */
  /* margin: 0; */
  border: 0;
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
}
.popup_form_wrapper_sales .btn-close-sales.btn-close {
  background-color: #002b5a;
}
.popup_form_wrapper_sales .btn-close .icon-cross {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 24px;
  height: 40px;
}
.popup_form_wrapper_sales .btn-close .icon-cross:before,
.popup_form_wrapper_sales .btn-close .icon-cross:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
  border-radius: 6px;
}
.popup_form_wrapper_sales .btn-close .icon-cross:before {
  transform: rotate(45deg);
}
.popup_form_wrapper_sales .btn-close .icon-cross:after {
  transform: rotate(-45deg);
}
.popup_form_wrapper_sales .btn-close .icon-cross span {
  display: block;
}
.popup_form_wrapper_sales .btn-close:hover,
.popup_form_wrapper_sales .btn-close:focus {
  transform: rotateZ(90deg);
  /* background: #0052cc; */
}

/* .popup_form_wrapper_sales .contact_select_wrapper .are_you_select:hover::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
} */

@media (max-width: 834px) {
  .salesEnquiry .contact_wrapper .PhoneInputInput {
    height: initial;
  }
}

@media (max-width: 450px) {
  .salesEnquiry {
    max-width: 35em;
  }
  .salesEnquiry .wrapper {
    padding: 2em 2em 3em 2em;
  }

  .popup_form_wrapper_sales .btn-close {
    width: 30px !important;
    height: 30px !important;
  }

  .popup_form_wrapper_sales .btn-close .icon-cross {
    width: 15px !important;
    height: 36px !important;
  }
  .popup_form_wrapper_sales .btn-close .icon-cross:before,
  .popup_form_wrapper_sales .btn-close .icon-cross:after {
    height: 2px !important;
  }
}
