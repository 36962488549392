.products_popup4 {
  // height: 40em;
  overflow-y: scroll;
  overflow-x: hidden;
}
.products_popup4::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
}

.products_popup4::-webkit-scrollbar-track {
  background-color: transparent;
}
.products_popup4::-webkit-scrollbar-thumb {
  border-radius: 2em;
  cursor: pointer;
  background-color: #bbb4b4;
}

@media (max-width: 767px) {
    .popup_form_wrapper4 {
      .products_popup4 {
        max-width: 90%;
        max-height: 80%;
        .wrapper {
          margin-left: 2em;
        }
        .pop_sec3 {
          margin-top: 0;
          //   .products_inner_listing {
          display: inline;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          .pop_box2 {
            padding: 3em 0.5em;
            margin-right: 0;
            margin-right: 2em;
            margin-left: 1.2em;
            margin-top: 2.75em;
            max-width: 100%;
            .pop_head {
              text-align: center;
            }
            .pop_desc {
              text-align: center;
            }
            .dashwrapper {
              visibility: hidden;
            }
            // }
          }
        }
      }
    }
  }
  