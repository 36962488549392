/* Importing Common CSS File */
@import url("../../common.css");
.popup_form_wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow: hidden;
  z-index: 100;
}

.thankyou_popup {
  max-width: 40.5625em;
  width: 100%;
  min-height: 26.4375em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  text-align: center;
  z-index: 1;
}

.thankyou_popup .close_button {
  width: 90%;
  margin: 0 auto;
  text-align: right;
}

/* .thankyou_popup .close_button button {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-top: 0.5625em;
    background-color: #002B5A;
    color: #fff;
    font-size: 1.5em;
} */

.thankyou_popup figure {
  max-width: 8em;
  width: 100%;
  margin-top: 3.25em;
  display: inline-block;
}

.thankyou_popup .thankyou_message {
  margin-top: 0.5em;
  display: block;
  font-family: "National-Medium";
  font-size: 3.125em;
  line-height: 1.2em;
}

.thankyou_popup p {
  max-width: 17.5em;
  width: 100%;
  margin: 0.4375em auto 0;
  font-family: "National-Light";
  font-size: 1.375em;
  line-height: 1.27em;
}

.thankyou_popup .btn-close {
  margin-top: 1em;
  float: right;
  display: inline-block;
  /* float: right; */
  /* margin: 0; */
  border: 0;
  padding: 0;
  background-color: #0052cc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
}
.thankyou_popup .btn-close .icon-cross {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 24px;
  height: 40px;
}
.thankyou_popup .btn-close .icon-cross:before,
.thankyou_popup .btn-close .icon-cross:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
  border-radius: 6px;
}
.thankyou_popup .btn-close .icon-cross:before {
  transform: rotate(45deg);
}
.thankyou_popup .btn-close .icon-cross:after {
  transform: rotate(-45deg);
}
.thankyou_popup .btn-close .icon-cross span {
  display: block;
}
.thankyou_popup .btn-close:hover,
.thankyou_popup .btn-close:focus {
  transform: rotateZ(90deg);
  /* background: #0052cc; */
}

@media (max-width: 450px) {
  .thankyou_popup .btn-close {
    width: 30px !important;
    height: 30px !important;
  }

  .thankyou_popup .btn-close .icon-cross {
    width: 15px !important;
    height: 36px !important;
  }
  .thankyou_popup .btn-close .icon-cross:before,
  .thankyou_popup .btn-close .icon-cross:after {
    height: 2px !important;
  }
}
