.fin_perf_sec2 {
  padding: 6.25em 0 6.25em;
  padding-top: 12.625em;
  .invest_acc_container{
    margin-top: 2.5em;
  }
  .invest_row {
    margin: 0 auto;
    .invest_col {
      padding: 0;
      &:nth-child(2n + 1) {
        padding-right: 0.3125em;
      }
      &:nth-child(2n + 2) {
        padding-left: 3.8125em;
      }
      .heading {
        color: #012b5b;
        font-size: 2.5em;
        font-family: "National-Medium";
        letter-spacing: 0px;
        line-height: 1.2;
      }
      .report_row {
        margin: 0 auto;
        margin-top: 2.5em;
        .report_col {
          padding: 0;
          &:nth-child(2n + 1) {
            padding-right: 1.265em;
          }
          &:nth-child(2n + 2) {
            padding-left: 1.265em;
          }
        }
        .report_tab_name {
          font-size: 2.5em;
          font-family: "National-Normal";
          text-align: center;
          color: #000000;
          letter-spacing: 0px;
          line-height: 1.2;
          border: 1px solid #012b5b;
          border-bottom: 0.25em solid #012b5b;
          padding: 0.6125em 0.25em;
          cursor: pointer;
          transition: 0.5s ease;
          &.active {
            background-color: #f3f6fa;
            border: 1px solid #f3f6fa;
            border-bottom: 0.25em solid #012b5b;
            transition: 0.5s ease;
          }
        }
        .year {
          font-size: 2.5em;
          font-family: "National-Medium";
          color: #012b5b;
          letter-spacing: 0px;
          line-height: 1.2;
          border-bottom: 1px solid rgba($color: #707070, $alpha: 0.5);
          padding: 1em 0 0.75em;
          margin-bottom: 0.75em;
        }
      }
    }
  }
}
.stck_ivstor_rel{
  background-color: transparent;
}
.fin_perf_sec1 {
  .wrapper {
    .right_section {
      .box_stock_hold {
        width: 27.649em;
        height: 17.313em;
        position: absolute;

        .stock_box {
          margin-top: 5.625em;
          color: #fff;
          padding: 1.875em;
          padding-bottom: 2.5em;
          height: 100%;

          .stock_light_heading {
            font-size: 1.125em;
          }

          .stock_md_heading {
            font-size: 1.125em;
          }

          .stock_rate_graph_wrapper {
            position: relative;
            min-width: 100%;
            padding-left: 1.875em !important;
            margin-top: 2em;
            width: 27.649em !important;
            margin-left: -1.875em !important;

            .stock_rate {
              font-size: 3.125em;
            }

            .stock_graph_img {
              top: -53%;
            }
          }

          .stock_up_down_percentage {
            font-size: 1em;

            .stock_d_icon {}
          }

          .date_stock_wrapper {
            margin-top: 1.313em;

            .date_stock {
              font-size: 1em;
            }

            .time_stock {
              font-size: 1em;
            }
          }

          .growth_stock {}
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .fin_perf_sec2 {
    .invest_row {
      .invest_col {
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
        .heading {
          font-size: 2.2em;
        }
        .report_row {
          .report_col {
            &:nth-child(2n + 1) {
              padding-right: 1em;
            }
            &:nth-child(2n + 2) {
              padding-left: 1em;
            }
          }
          .report_tab_name {
            font-size: 2.2em;
          }
          .year {
            font-size: 2.2em;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .stck_ivstor_rel{
    padding-bottom: 0;
    margin-top: 6em;
  }
}
@media (max-width: 767px) {
  .fin_perf_sec2 {
    padding: 6em 0 6em;
    .invest_row {
      .invest_col {
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .heading {
          font-size: 2.4em;
          letter-spacing: 0px;
          line-height: 1.417;
          margin: 0.8334em 0;
        }
        .invest_tab_wrapper_2 {
          margin-top: 4em;
        }
        .report_row {
          margin-top: 0;
          .report_col {
            &:nth-child(2n + 1) {
              padding-right: 0;
            }
            &:nth-child(2n + 2) {
              padding-left: 0;
            }
          }
          .report_tab_col {
            &:nth-child(2n + 1) {
              padding-right: 1em;
            }
            &:nth-child(2n + 2) {
              padding-left: 1em;
            }
          }
          .report_tab_name {
            font-size: 2.4em;
            letter-spacing: 0px;
            line-height: 1.417;
            border-bottom: 0.2084em solid #012b5b;
            padding: 0.438em 0.25em;
            &.active {
              border-bottom: 0.2084em solid #012b5b;
            }
          }
          .year {
            font-size: 2.2em;
            letter-spacing: 0px;
            line-height: 1.227;
            padding: 1.819em 0 0.728em;
            margin-bottom: 1.091em;
          }
        }
      }
    }
  }
}
