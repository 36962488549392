/* Importing Common CSS File */
@import url("../../common.css");
.our_setup_technology {
  padding: 2em 0 7.7em;
  margin-top: 8.8em;
  position: relative;
  background-color: #f3f6fa;
}

.our_setup_technology .left_section {
  max-width: 50%;
}
.our_setup_technology .left_section h3 {
  margin-top: 0.5em;
}

.our_setup_technology .left_section p {
  max-width: 80%;
  margin-top: 1.1875em;
}

.our_setup_technology .right_section .bg_img {
  max-width: 41.0625em;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.our_setup_technology .right_section .bg_actual_img {
  height: 100%;
}
.our_setup_technology .right_section .fg_img {
  width: 100%;
  max-width: 63.9375em;
  min-height: 33.5em;
  position: absolute;
  top: 36%;
  right: 0;
  transform: translateY(-30%);
}

.our_setup_technology_second_section .wrapper {
  padding: 8.4375em 0;
}

.our_setup_technology_second_section .digital_and_technology {
  margin-top: 6.4375em;
  display: flex;
  justify-content: space-between;
}

.our_setup_technology_second_section .digital_and_technology li {
  max-width: 23.625em;
  width: 100%;
  position: relative;
  background-color: #002b5a;
}

.our_setup_technology_second_section .digital_and_technology li figure {
  position: absolute;
  top: -6em;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.our_setup_technology_second_section .digital_and_technology li figure img {
  max-width: 11.5625em;
  border-radius: 50%;
}

.our_setup_technology_second_section .digital_and_technology li p {
  padding: 7em 1em 2em;
  color: #fff;
  font-family: "National-Light";
  font-size: 1.375em;
  line-height: 1.5em;
  text-align: center;
}

.our_setup_technology_second_section .sustainability {
  margin-top: 5.0625em;
  position: relative;
}

.our_setup_technology_second_section
  .sustainability
  .sustainability_swiper_wrapper {
  margin-top: 1.625em;
}
.our_setup_technology_second_section
  .sustainability
  .sustainability_swiper_wrapper
  li {
  display: flex;
}

.our_setup_technology_second_section
  .sustainability
  .sustainability_swiper_wrapper
  li
  div {
  max-width: 23.375em;
  width: 100%;
  background-color: #002b5a;
  display: flex;
  justify-content: center;
}

.our_setup_technology_second_section .sustainability figure {
  position: relative;
}
.our_setup_technology_second_section
  .sustainability
  figure
  .sustainability_small_icons {
  max-width: 5.603125em;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.our_setup_technology_second_section .sustainability_swiper_wrapper li {
  width: 100%;
  max-width: 100%;
}
.our_setup_technology_second_section .sustainability_swiper_wrapper li p {
  margin: 0 1em 0 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.our_setup_technology_second_section .sustainability .navigations {
  position: absolute;
  top: 0;
  right: 3em;
}

.our_setup_technology_second_section .sustainability .navigations button {
  color: #1e5093 !important;
}
.our_setup_technology_second_section
  .sustainability
  .navigations
  .left_arrow:after {
  border: 2px dashed #1e5093;
}
.our_setup_technology_second_section
  .sustainability
  .navigations
  .right_arrow::before {
  border: 2px dashed #1e5093;
}
/* vijay css start */
.technosec1 {
  padding-top: 9.313em;
  padding-left: 6.813em;
  padding-bottom: 6em;
  background-color: #f3f6fa;
}
.technosec1 .hollow_title {
  line-height: normal;
  margin-top: 0.4em;
}
.technosec1 .breadcrumb_styles {
  line-height: normal;
}
.technosec1 .col_1 {
  padding-top: 3.5em;
}
.technosec1 .dash_wrapper {
  margin-bottom: 1em;
}
.technosec1 h3 {
  color: #1c1c1c;
  margin-bottom: 0.38em;
}
.technosec1 .row {
  margin: 0;
}
.technosec1 p {
  font-size: 1.25em;
  line-height: 1.65em;
  color: #1c1c1c;
  width: 100%;
  max-width: 86%;
}

.technosec1 li {
  position: relative;
  display: block;
  padding-left: 1em;
}
.technosec1 li::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0.1em;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #002b5a;
}
.technosec1 .col_2 {
  position: relative;
  text-align: right;
  padding: 0;
}
.technosec1 .col_2 .tech_banner_bg {
  width: 100%;
  max-width: 42.688em;
  min-height: 59em;
}

.technosec1 .col_2 .tech_banner_video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  max-width: 63.938em;
  min-height: 33.5em;
  z-index: 9999;
}
.technosec2 {
  padding: 6.125em 6.125em 0;
}
.technosec2 .col_1 {
  align-self: center;
}
.technosec2 h1 {
  font-size: 3.125em;
  line-height: 1.2em;
  font-family: "National-Normal";
  color: #1c1c1c;
}
.technosec2 .digi_para {
  font-size: 1.25em;
  line-height: 1.65em;
  color: #1c1c1c;
  font-family: "National-Light";
}
.technosec2 .digi_wrapper {
  margin-top: 2.688em;
}
.technosec2 .digi_wrapper p {
  font-size: 1.375em;
  line-height: 2.727em;
  color: #1c1c1c;
  font-family: "National-Normal";
  margin-top: 1em;
}
.sustainability {
  padding-left: 6.125em;
}

@media (max-width: 1600px) {
  .our_setup_technology_second_section .sustainability .navigations {
    top: -1em;
  }
}

@media (max-width: 1450px) {
  .our_setup_technology_second_section .sustainability .navigations {
    top: -1.5em;
  }
}

@media (max-width: 1280px) {
  .our_setup_technology_second_section .sustainability .navigations {
    top: -2em;
  }
}

/* vijay css end */
@media (max-width: 1194px) {
  .technosec1 {
    padding-left: 1.5em;
  }
  .technosec1 p {
    max-width: 100%;
  }
  .technosec1 .col_2 {
    margin-top: 4em;
  }
}
@media (max-width: 1023px) {
  .technosec2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

@media (max-width: 834px) {
  .technosec1 {
    padding-bottom: 0;
  }
  .sustainability {
    padding-left: 1.5em;
  }

  .our_setup_technology {
    position: relative;
  }
  .our_setup_technology .left_section {
    max-width: 100%;
  }
  .our_setup_technology .right_section .bg_img {
    margin-left: 30em;
    top: unset;
    bottom: -4em;
    right: 0;
  }
  .our_setup_technology .right_section .fg_img {
    position: relative;
    max-width: 100%;
    padding-top: 5em;
    margin-top: 5em;
    top: 11em;
  }
  .our_setup_technology_second_section .sustainability_swiper_wrapper li p {
    text-align: center;
    padding: 3em 1.4375em;
    margin: 0;
  }
}

@media (max-width: 790px) {
  .our_setup_technology .right_section .fg_img {
    top: 14em;
  }
  .our_setup_technology_second_section .sustainability_swiper_wrapper li p {
    padding: 2em 0.4375em;
  }
}

@media (max-width: 767px) {
  /* vijay css start */
  .technosec1 .col_1 {
    padding-left: 0;
    padding-top: 1.5em;
  }
  .technosec1 h3 {
    font-size: 2.4em;
  }
  .technosec1 p {
    font-size: 1.3em;
  }

  .technosec1 .col_2 .tech_banner_bg {
    max-width: 50%;
    min-height: 35em;
  }
  .technosec1 .col_2 .tech_banner_video {
    min-height: 19.5em;
    right: 0.7em;
  }
  .technosec2 {
    padding-top: 7.7em;
  }
  .technosec2 h1 {
    font-size: 2.4em;
    line-height: 1.25em;
  }
  .technosec2 .row1 {
    text-align: center;
  }
  .technosec2 .digi_para {
    font-size: 1.3em;
    line-height: 1.538em;
    margin-top: 0.9em;
  }
  .technosec2 .col-lg-3:nth-child(n + 2) {
    margin-top: 3.3em;
  }
  .technosec2 .digi_wrapper p {
    margin-top: 0.7em;
  }
  .technosec3 {
    padding-left: 1.5em;
    padding-top: 4em;
  }
  .technosec3 span {
    display: block;
    text-align: center;
    font-size: 2.4em;
    line-height: 1.25em;
    font-family: "National-Normal";
    color: #1c1c1c;
    margin-bottom: 1em;
  }
  .technosec3 .sus-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8.2em 1.5em;
    background-color: #002b5a;
    height: 22.9em;
  }
  .technosec3 .sus-text .sus_para {
    font-size: 1.6em;
    line-height: 1.545em;
    color: #fff;
    font-family: "National-Normal";
    text-align: center;
  }
  /* vijay css end */

  .our_setup_technology .left_section .hollow_title {
    font-size: 5em;
  }
  .our_setup_technology .left_section p {
    max-width: 100%;
  }

  .our_setup_technology .right_section .bg_img {
    margin-left: 17em;
    bottom: -5em;
    height: initial;
  }
  .our_setup_technology .right_section .bg_actual_img {
    min-height: 32em;
    height: initial;
  }

  .our_setup_technology .right_section .fg_img {
    top: 12em;
    padding-top: 0;
    margin-top: 1em;
    min-height: 19.5em;
  }
  .our_setup_technology_second_section .hollow_title {
    font-size: 5em;
    text-align: center;
    line-height: 1.2em;
  }
  .our_setup_technology_second_section .digital_and_technology {
    display: inline-block;
    text-align: center;
  }
  .our_setup_technology_second_section .digital_and_technology li {
    margin-bottom: 8em;
  }
  .our_setup_technology_second_section .sustainability .navigations {
    position: absolute;
    top: 35em;
    transform: translateX(-50%);
  }
  .our_setup_technology_second_section
    .sustainability
    .sustainability_swiper_wrapper
    li {
    display: inline-block;
    /* width: 80% !important; */
  }
  .our_setup_technology_second_section
    .sustainability
    .sustainability_swiper_wrapper
    li
    div {
    width: 100%;
    max-width: 100%;
  }
  .our_setup_technology_second_section .sustainability_swiper_wrapper li p {
    padding: 2em 0.4375em;
  }
  .our_setup_technology_second_section .sustainability {
    margin-top: 0;
  }
}
