.products_popup {
  // height: 40em;
  overflow-y: scroll;
  overflow-x: hidden;
}
.products_popup::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
}

.products_popup::-webkit-scrollbar-track {
  background-color: transparent;
}
.products_popup::-webkit-scrollbar-thumb {
  border-radius: 2em;
  cursor: pointer;
  background-color: #bbb4b4;
}

@media (max-width: 767px) {
  .popup_form_wrapper {
    .products_popup {
      max-width: 90%;
      max-height: 80%;
      .wrapper {
        margin-left: 2em;
      }
      .pop_sec3 {
        margin-top: 0;
        .products_inner_listing {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          .pop_box {
            padding: 3em;
            margin-right: 0;
            margin-top: 2.75em;
            .pop_head {
              text-align: center;
            }
            .pop_desc {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
