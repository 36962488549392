@import "../../common.css";
.infoModel {
  width: 100%;
  max-width: 71%;

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .modal-header {
    border: none;
  }
  .infoDetail {
    padding: 0 !important;
    border: none;
    .modal_cross_brown {
      position: absolute;
      background-color: #fff;
      border-radius: 40px;
      padding: 0.2em;
      top: -2.5em;
      right: 0;
      z-index: 20000;
      width: 100%;
      max-width: 2em;
      cursor: pointer;
    }

    .iframes {
      //   height: 47.75em;
      height: 40em;
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
    }

    .modelThumbIcon {
      //   height: 47.75em;
      height: 40em;
    }

    .playicon {
      right: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 6.625em;
      cursor: pointer;
      user-select: none;
    }
  }

  .modal-header .btn-close {
    position: absolute;
    right: 10px;
    top: -55px;
    z-index: 9;
    font-size: 3.75em;
    padding: 0;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    color: white;
    background-color: white;
  }

  .modal-header {
    padding: 0 !important;
  }

  .modal-header .btn-close {
    color: white;
    opacity: 0.75;
  }

  .playIcon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding-right: 0;
    cursor: pointer;
    width: 4em;
    height: 4em;
  }
}

@media (max-width: 1366px) {
  .infoModel .infoDetail {
    .modelThumbIcon,
    .iframes {
      height: 45.75em;
    }
  }

  .infoModel .modal-header .btn-close {
    position: absolute;
    right: 10px;
    top: -30px;
    z-index: 9;
    font-size: 3.75em;
    padding: 0;
  }
}
@media (max-width: 1023px) {
  .infoModel {
    max-width: 100%;
    padding: 0;
    padding-left: 2em;
    padding-right: 2em;
    margin: 0;

    .infoDetail .iframes,
    .modelThumbIcon {
      height: 40em;
      width: 100%;
      max-width: 100%;
      vertical-align: middle;
    }
  }
}

@media (max-width: 767px) {
  .infoModel .infoDetail {
    .modelThumbIcon,
    .iframes {
      height: 20.07em;
      width: 100%;
      max-width: 100%;
      vertical-align: middle;
    }
  }
}
