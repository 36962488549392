/* Importing Common CSS File */
@import url("../../common.css");
.aboutus_management {
  padding: 4em 0 5em;
  margin-top: 8.5em;
  position: relative;
  background-color: #f0f6fd;
}

.aboutus_management h3 {
  text-transform: none;
}

.aboutus_management .left_section {
  max-width: 100%;
}
.aboutus_management .left_section h3 {
  margin-top: 0.5em;
}

.aboutus_management .left_section p {
  max-width: 39.25em;
  margin-top: 1.1875em;
}

.aboutus_management .right_section .bg_img {
  max-width: 55.6875em;
  position: absolute;
  top: 0;
  right: 0;
}

.board_of_directors {
  padding: 0 0 10.4375em;
  background-color: #f0f6fd;
}

/* .board_of_directors .hollow_title_wrapper { text-align: center; } */

.board_of_directors
  .board_of_directors_container_desktop
  .board_of_directors_profiles {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.board_of_directors
  .board_of_directors_container_desktop
  .board_of_directors_profiles
  li {
  flex-basis: 21%;
}
/* .board_of_directors
  .board_of_directors_container_desktop
  .board_of_directors_profiles
  li:last-child {
    cursor: default;
} */
.board_of_directors
  .board_of_directors_container_desktop
  .board_of_directors_profiles
  li:nth-child(n + 5) {
  margin-top: 3.5em;
}

.board_of_directors_profiles .name_and_designation {
  min-height: 8em;
  padding: 1.1875em 0 1.18em 1.375em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #002b5a;
}

.board_of_directors_profiles .name_and_designation h6 {
  color: #fff;
  font-family: "National-Medium";
  font-size: 1.5em;
  line-height: 1.5em;
}

.board_of_directors_profiles .name_and_designation p {
  color: #fff;
}
.board_of_directors_profiles .description {
  margin: 1.9375em 0 0 1em;
}

.board_of_directors_profiles .description li p {
  color: #1c1c1c;
  line-height: 1.65em;
}

.description {
  list-style-type: disc;
  list-style-position: outside;
}

.description li {
  display: list-item;
  color: #004083;
  list-style-type: disc;
}

.management_team {
  background-color: #002b5a;
}

.management_team .wrapper {
  padding: 4.90625em 0 11em;
  position: relative;
}

.management_team .hollow_title,
.join_us .content .hollow_title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #fff, #fff 50%, transparent 50%);
  opacity: 0.4;
  -webkit-text-stroke-color: #fff;
}

.management_team .hollow_title:hover,
.join_us .content .hollow_title:hover {
  -webkit-text-stroke-color: #fff;
}

.management_team .management_team_thumb_container {
  margin-top: 2.71875em;
}
.management_team .management_team_container figure,
.management_team .management_team_thumb_container figure {
  max-width: 21.1875em;
}
.management_team .management_team_container {
  margin-top: 2.8125em;
}

.management_team .management_team_container ul,
.management_team .management_team_thumb_container ul {
  display: flex;
  justify-content: space-between;
}

.management_team .name_and_designation {
  max-width: 21.1875em;
  min-height: 10em;
  padding: 1.1875em;
  background-color: #fff;
}

.management_team .name_and_designation h5 {
  color: #1c1c1c;
  font-family: "National-Medium";
  font-size: 1.625em;
  line-height: 1em;
}

.management_team .navigations {
  position: absolute;
  bottom: 3em;
  left: 50%;
  transform: translateX(-50%);
}

.join_us .wrapper {
  padding-top: 6.25em;
  position: relative;
}

/* .join_us { 
    background-image: url("../../images/AboutUs/Management/Group\ 3197.jpg"); 
    background-repeat: no-repeat;
    background-size: cover;
} */

.join_us .content {
  position: absolute;
  top: 15em;
  right: 11em;
}

.join_us .content .hollow_title {
  opacity: 0.5;
  -webkit-text-stroke-color: #fff;
}

.join_us .content h3 {
  color: #fff;
}
.join_us .content p {
  max-width: 20em;
  width: 100%;
  color: #fff;
  font-family: "National-Normal";
  font-size: 1.625em;
  line-height: 1.26em;
}

.join_us .content a {
  /* border-bottom: 1px solid #002B5A; */
  margin-top: 1.9375em;
  position: relative;
  color: #fff;
  font-family: "National-Medium";
  font-size: 1.5em;
  line-height: 2.5em;
  text-decoration: none;
  text-transform: uppercase;
}
/* 
.join_us .content a:after {
    content: url("../../images/General/Arrow\ white.svg");
    position: absolute;
    top: 1.5em;
    left: 0;
} */

.bottom_arrow_container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 2em;
}

.bottom_arrow_container:hover .arrow {
  transform: scaleX(1.2);
}

.bottom_arrow_container button {
  border: none;
  background-color: transparent;
  margin-bottom: 0.5em;
}
.bottom_arrow_container .arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left;
  transition: 0.25s ease-in-out;
}

/* .join_us .content .link_wrapper {
    display: inline-block;
    position: relative;
}

.join_us .content a {
    color: #fff;
    font-family: 'National-Medium';
    font-size: 1.5em;
    line-height: 2.54em;
    text-decoration: none;
    text-transform: uppercase;
}

.join_us .content .link_wrapper .arrow {
    max-width: 12.5em;
    position: absolute;
    bottom: 0;
    left: 0.5em;
} */

.management_team_container_mobile {
  display: none;
}
.board_of_directors_container_mobile {
  display: none;
}
.management_mobile_heading {
  display: none;
}
.join_us_mobile {
  display: none;
}

.profiles_modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  opacity: 0.97;
  overflow: hidden;
  z-index: 100;
}

.profiles_modal_popup {
  max-width: 40.5625em;
  width: 100%;
  /* min-height: 26.4375em; */
  max-height: 90%;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  text-align: center;
  z-index: 1;
}

.profiles_modal_popup .close_button {
  width: 90%;
  margin: 0 auto;
  text-align: right;
}

.profiles_modal_popup .close_button button {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin-top: 0.5625em;
  background-color: #002b5a;
  color: #fff;
  font-size: 1.5em;
}

.profiles_modal_popup .close_button span {
  position: absolute;
  top: -18.5%;
  left: 23%;
  font-size: 1.5em;
}

/* .modal_data {
    position: relative;
}

.modal_data li {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.modal_data {
  padding: 2em;
  text-align: left;
}
.modal_data li {
  display: list-item;
  list-style-type: disc;
}

.modal_data h6,
.modal_data h5 {
  margin-left: 0.5em;
  font-size: 1.8em;
}

.modal_data p {
  margin-left: 1em;
}

.modal_data li p {
  margin-left: 0;
  color: #000;
  font-size: 1.4em;
  line-height: 1.2em;
  text-align: left;
}
.modal_data li:not(:first-child) {
  margin-top: 1em;
}
.board_of_directors_profiles li {
  cursor: pointer;
}
.management_team li {
  cursor: pointer;
}

/* .management_team_thumb_container li:nth-child(1) { padding-bottom: 2em; }
.management_team_thumb_container li:nth-child(2) { padding-bottom: 2em; }
.management_team_thumb_container li:nth-child(4) { padding-bottom: 2em; }
.management_team_thumb_container li:nth-child(5) { padding-bottom: 2em; }

.management_team_container li:nth-child(7) { padding-bottom: 2em; }
.management_team_container li:nth-child(10) { padding-bottom: 2em; } */

/* .management_team li:nth-child(1) .name_and_designation { padding-bottom: 3em; }
.management_team li:nth-child(2) .name_and_designation { padding-bottom: 3em; }
.management_team li:nth-child(4) .name_and_designation { padding-bottom: 3em; }
.management_team li:nth-child(5) .name_and_designation { padding-bottom: 3em; } */
/* .management_team li:nth-child(7) .name_and_designation { padding-bottom: 3em; } */
/* .management_team li:nth-child(10) .name_and_designation { padding-bottom: 3em; } */
/* .management_team li:nth-child(11) .name_and_designation { padding-bottom: 3em; } */

/* .management_team li:nth-child(3) .name_and_designation { padding-bottom: 1em; } */

/* .management_team li:not(:nth-child(3)) .name_and_designation 
.management_team li:not(:nth-child(6)) .name_and_designation,
.management_team li:not(:nth-child(8)) .name_and_designation,
.management_team li:not(:nth-child(9)) .name_and_designation,
.management_team li:not(:nth-child(12)) .name_and_designation { padding-bottom: 1em; } */

.name_and_designation p {
  line-height: 1.2em;
}
.management_team .name_and_designation p {
  margin-top: 0.2em;
}
.name_and_designation {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .aboutus_management {
    /* padding-bottom: 8em; */
    padding-bottom: 4em;
  }
  .aboutus_management .left_section,
  .aboutus_management .right_section .bg_img {
    max-width: 100%;
  }
  .aboutus_management .left_section p {
    max-width: 100%;
  }
  .aboutus_management .right_section {
    margin-top: 5.8em;
  }

  .aboutus_management .right_section .bg_img {
    position: static;
  }

  .board_of_directors .hollow_title_wrapper {
    margin-bottom: 1.85em;
    text-align: center;
  }

  .board_of_directors .hollow_title {
    max-width: 5em;
    line-height: 0.96em;
  }

  .board_of_directors
    .board_of_directors_container_desktop
    .board_of_directors_profiles
    li {
    flex-basis: 30%;
  }
  .board_of_directors_container_desktop
    .board_of_directors_profiles
    .description
    li
    p {
    font-size: 1.3em;
  }

  .board_of_directors_container_desktop {
    display: none;
  }
  .board_of_directors_container_mobile {
    display: block;
  }

  .management_team .hollow_title {
    line-height: 0.96em;
  }

  .management_team_thumb_container {
    display: none;
  }
  .management_team_container {
    display: none;
  }

  .management_team_container_mobile {
    margin-top: 2em;
    display: block;
  }

  .join_us {
    display: none;
  }

  .join_us_mobile {
    margin-top: 3.7em;
    display: block;
    position: relative;
  }

  .join_us_mobile .content {
    padding: 0 3em;
    position: absolute;
    top: 3em;
  }

  .join_us_mobile .content .hollow_title {
    font-size: 6em;
    opacity: 0.5;
    -webkit-text-stroke-color: #fff;
  }

  .join_us_mobile .content p {
    max-width: 100%;
    width: 100%;
    color: #fff;
    font-family: "National-Normal";
    font-size: 2em;
    line-height: 1.5em;
  }

  .join_us_mobile .content a {
    /* border-bottom: 1px solid #002B5A; */
    margin-top: 1.9375em;
    position: relative;
    color: #fff;
    font-family: "National-Medium";
    font-size: 1.5em;
    line-height: 2.5em;
    text-decoration: none;
    text-transform: uppercase;
  }

  .aboutus_management .left_section h3 {
    font-size: 2.4em;
    line-height: 1.25em;
  }

  .board_of_directors {
    /* padding: 6.3em 0 7.5em; */
    padding: 2em 0 7.5em;
  }

  .profiles_modal_popup {
    max-width: 30em;
  }

  .management_team .navigations {
    position: absolute;
    bottom: 4em;
    left: 50%;
    transform: translateX(-50%);
  }

  .management_team .name_and_designation {
    max-width: 100%;
    min-height: 10em;
  }
  .management_mobile_heading {
    display: block;
  }
  .management_desktop_heading {
    display: none;
  }

  .aboutus_our_journey {
    padding-bottom: 9em;
  }
  .aboutus_our_journey_slider {
    padding-top: 7em;
  }
}

@media (max-width: 600px) {
 .profiles_modal_popup {
    height: 100%;
    max-height: 50%;
    overflow-y: auto;
  }
  .profiles_modal_popup .close_button span {
    left: 25%;
   }

}

@media (max-width: 376px) {
  /* .management_mobile_heading { display: block; }
    .management_desktop_heading { display: none; } */
  /* .board_of_directors .board_of_directors_profiles li { flex-basis: 75%; } */

  .management_team .hollow_title {
    text-align: center;
  }

  .management_team .name_and_designation {
    max-width: 100%;
  }

  .join_us_mobile .content .hollow_title {
    opacity: 0.5;
    -webkit-text-stroke-color: #fff;
  }

  .join_us_mobile .content {
    top: 4em;
  }
  .join_us_mobile .hollow_title {
    line-height: 1.4em;
  }
  .join_us_mobile .content p {
    max-width: 20em;
    width: 100%;
    color: #fff;
    font-family: "National-Normal";
    font-size: 1.625em;
    line-height: 1.26em;
  }

  .join_us_mobile .content a {
    /* border-bottom: 1px solid #002B5A; */
    margin-top: 1.9375em;
    position: relative;
    color: #fff;
    font-family: "National-Medium";
    font-size: 1.5em;
    line-height: 2.5em;
    text-decoration: none;
    text-transform: uppercase;
  }

  .bottom_arrow_container {
    margin-top: 2em;
  }

  .management_team .navigations {
    position: absolute;
    bottom: 4em;
    left: 50%;
    transform: translateX(-50%);
  }
  .navigations button:last-child {
    margin-left: 2.9em;
  }
}
