/* Importing Common CSS File */
@import url("../../common.css");
.investor_relations {
  padding: 2em 0 15em;
  margin-top: 8.8em;
  position: relative;
  background-color: #f3f6fa;
}

.investor_relations .left_section {
  max-width: 50%;
  margin-top: 1.28125em;
}
.investor_relations .left_section .hollow_title {
  line-height: 1em;
}
.investor_relations .left_section h3 {
  margin-top: 0.5em;
}

.investor_relations .right_section .bg_img {
  max-width: 30em;
  position: absolute;
  top: 0;
  right: 0;
}

.investor_relations .right_section .fg_img {
  max-width: 42em;
  position: absolute;
  top: 43%;
  right: 6%;
  transform: translateY(-30%);
}

.overview .wrapper {
  padding: 8.9375em 0 12.4375em;
}

.overview .overview_first_sec {
  border: 1px solid #002b5a;
  display: flex;
  /* justify-content: space-between; */
}

.overview .overview_first_sec figure {
  max-width: 42em;
  width: 100%;
}

.overview .overview_first_sec .content {
  /* max-width: 36.875em; */
  width: 100%;
  padding: 4.5625em 0 4.5em 9.0625em;
}

.overview .content .main_content {
  margin-top: 1em;
  display: flex;
}

.overview .overview_first_sec .content .fields,
.overview .overview_first_sec .content .description {
  /* margin-top: 1em; */
  display: flex;
  flex-flow: column;
}

.overview .overview_first_sec .content .fields span {
  color: #002b5a;
  font-family: "National-SemiBold";
  font-size: 1.625em;
  line-height: 1.69em;
}

.overview .overview_first_sec .content .description span {
  margin-left: 6.375em;
  color: #002b5a;
  font-family: "National-Normal";
  font-size: 1.625em;
  line-height: 1.69em;
}

.overview .overview_first_sec .content .fields span:not(:first-child),
.overview .overview_first_sec .content .description span:not(:first-child) {
  margin-top: 0.4em;
}

.overview .overview_second_sec {
  margin-top: 7.375em;
  display: flex;
  justify-content: space-between;
}

.overview .overview_second_sec .content {
  max-width: 39.25em;
  margin-top: 3em;
}

/* .overview .overview_second_sec .content h3 { text-transform: capitalize; } */
.overview .overview_second_sec .content p {
  margin-top: 1.875em;
}

.overview .overview_second_sec figure {
  max-width: 42em;
  width: 100%;
}

.company_details {
  background-color: #00000029;
}
.company_details .wrapper {
  padding: 6.6875em 0 10.92875em;
}
.company_details .stock_information {
  margin-top: 2.0625em;
}
.company_details .stock_information span {
  display: block;
}

.company_details .stock_information ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.company_details .stock_information ul li:nth-child(n + 3) {
  margin-top: 4.25em;
}
.company_details .stock_information ul li {
  flex-basis: 45%;
}
.company_details .stock_information img {
  max-width: 5.75em;
}
.company_details .stock_information img:last-child {
  margin-left: 1.875em;
}

.company_details .stock_information .heading,
.investor_contact .heading {
  color: #002b5a;
  font-family: "National-SemiBold";
  font-size: 1.875em;
  line-height: 2em;
}

.company_details .stock_information .content {
  color: #1c1c1c;
  font-family: "National-Light";
  font-size: 1.25em;
  line-height: 1.65em;
}

.company_details .stock_information .content_additional_style {
  font-family: "National-Medium";
}
.company_details .stock_information ul li:nth-child(3) span:nth-child(3) {
  margin-top: 1em;
}

.placement_document {
  margin-top: 7.5em;
}

.placement_document ul {
  margin-top: 2.0625em;
  display: flex;
  justify-content: space-between;
}

.placement_document ul li {
  /* max-width: 39.25em;
    width: 100%; */
  flex-basis: 45%;
}

.corporate_governance {
  margin-top: 7.5em;
}

.corporate_governance .main_content_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.corporate_governance .left_section,
.corporate_governance .right_section {
  flex-basis: 45%;
}
.corporate_governance .main_content_wrapper {
  margin-top: 2.0625em;
}

.corporate_governance .main_content_wrapper ul {
  margin-left: 1em;
  list-style-type: disc;
}

.corporate_governance .main_content_wrapper .left_section li,
.corporate_governance .main_content_wrapper .right_section li {
  display: list-item;
  list-style-type: disc;
}

.investor_contact {
  margin-top: 7.5em;
}

.investor_contact .first_heading {
  margin-top: 0.5em;
  display: block;
}

.investor_contact .second_heading {
  margin-top: 0.1em;
  display: block;
}

.investor_contact a {
  color: #1c1c1c;
  font-family: "National-Light";
  font-size: 1.25em;
  line-height: 1.65em;
}
/* .our_quality { padding: 9.0625em 0; }
.our_quality .left_section { max-width: 53.25em; }
.our_quality ul { margin-top: 5.955em; }

.our_quality .our_quality_slider_heading_section {
    display: flex;
    justify-content: space-between;
} */

/*  New sections css */
.overview_financialinfo {
  margin-bottom: 8.313em;
}
.overview_financialinfo .btn_wrapper {
  margin-top: 0.5em;
}

.fi_heading {
  margin-top: 2.725em;
}
.flexbox {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}
.num1 {
  margin-right: 9.375em;
}
.num2 {
  margin-right: 9.375em;
}
.flexbox .num1,
.flexbox .num2,
.flexbox .num3, 
.flexbox .num4 {
  flex-basis: 33.33%;
  text-decoration: none;
}
.flexbox .num1:hover,
.flexbox .num2:hover,
.flexbox .num3:hover {
  text-decoration: none;
}
.flexbox .num1 .hollow_title,
.flexbox .num2 .hollow_title,
.flexbox .num3 .hollow_title,
.flexbox .num4 .hollow_title {
  font-family: "Gordita-Medium";
}
.investor_relations .hollow_title {
  font-size: 12.5em;
  font-weight: 500;
  margin-bottom: 0;
  /* font-family: "Gordita"; */
}
.fi_numsdesc {
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.875em;
  line-height: 1.5em;
}

.overview_otherinfo {
  background-color: #f3f6fa;
  padding: 6.25em 0;
}
.other_information {
  padding: 8.25em 0;
  background-color: #f3f6fa;
}
.other_information .fi_heading {
  margin-top: 0;
}
.other_desc {
  letter-spacing: 0px;
  color: #1c1c1c;
  font-family: "National-Normal";
  font-size: 1.875em;
  line-height: 1.5em;
  margin-bottom: 1.688em;
}

/* other info section */
table {
  width: 100%;
  max-width: 42%;
  border: 1px solid #002b5a;
  border-left: 0.5px solid #002b5a;
  text-align: left;
  font: normal normal normal 1.25em/2.063em National;
  letter-spacing: 0px;
  color: #002b5a;
  opacity: 1;
}
.onlytr {
  border-bottom: 1px solid #002b5a;
  font-family: "National-Normal";
  letter-spacing: 0px;
  color: #002b5a;
  opacity: 1;
}
td {
  border-right: 1px solid #002b5a;
  font-family: "National-Normal";
  letter-spacing: 0px;
  color: #1c1c1c;
  opacity: 1;
}
.pdf_box_2,
.pdf_box_3 {
  padding-left: 7em;
}
.left_td {
  font-size: 1.25em;
  padding: 0.7em 0.5em 0.7em 2em;
  width: 100%;
  max-width: 62%;
}
.right_td {
  font-size: 1.25em;
  text-align: center;
}
.mobile_section {
  display: none;
}
.overview_financialinfo .sanathan_btn {
  text-decoration: none;
}
.pdf_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 43, 90, 0.2);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.pdf_modal_wrapper .pdf_modal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 70%;
  background-color: #fff;
  padding: 4em 2em;
  /* overflow-y: scroll; */
  overflow-x: hidden;
 
}
.pdf_modal_wrapper .fin_popup_modal {
  height: 100%;
  max-height: fit-content;
  overflow-y: auto;
}
/* .pdf_modal_wrapper .pdf_modal_content::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
} */
.pdf_modal_wrapper .pdf_modal_content::-webkit-scrollbar {
  width: 0.75em;
  cursor: pointer;
}
.pdf_modal_wrapper .pdf_modal_content::-webkit-scrollbar-thumb {
  border-radius: 2em;
  cursor: pointer;
  background-color: #bbb4b4;
}

.pdf_modal_wrapper .pdf_modal_content a {
  text-decoration: none;
}
.pdf_modal_content .pdf_modal_cross {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 100%;
  max-width: 3.25em;
  cursor: pointer;
}
.pdf_modal_content .hollow_title {
  font-family: "Gordita-Medium";
  line-height: 1em;
}
.pdf_modal_content h3 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  font-family: "National-Medium";
  font-size: 3.125em;
  line-height: 1.2em;
}
.pdf_modal_content .pdf_flex,
.pdf_flex2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.pdf_flex2 {
  flex-wrap: wrap;
}
.pdf_modal_content .fin_popup {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.pdf_modal_content .fin_popup .pdf_content {
  max-width: 25%;
  padding-right: 0.5em;
  margin-bottom: 2em;
}
.other_information .sanathan_btn {
  text-decoration: none;
}

.pdf_modal_content .pdf_content {
  width: 100%;
  max-width: 32%;
  text-align: left;
}
@media (max-width: 1440px) {
  .pdf_modal_wrapper .pdf_modal_content {
    padding: 5em 2em;
  }
}
@media (max-width: 991px) {
  .pdf_modal_content .fin_popup .pdf_content {
    max-width: 33.33%;
  }
}

@media (max-width: 767px) {
  .pdf_box_2,
  .pdf_box_3 {
    padding-left: 0;
  }
  .pdf_modal_wrapper .pdf_modal_content {
    max-width: 90%;
    height: 100%;
    max-height: 95%;
    overflow-y: scroll;
  }
  .pdf_modal_content .pdf_flex{
    flex-direction: column;
  }
  .pdf_modal_content .pdf_content {
    max-width: 100%;
    margin-top: 4em;
  }
  .pdf_modal_content .hollow_title {
    line-height: 0.7em;
  }
  .pdf_modal_content h3 {
    margin-bottom: 0;
  }
  .pdf_box {
    margin-top: 1.5em;
  }
}
@media (max-width: 834px) {
  .overview_financialinfo {
    overflow-x: hidden;
  }
  table {
    max-width: 100%;
    overflow-x: hidden;
  }
  .overview_financialinfo .right_section {
    display: block;
    text-align: right;
    margin-right: -12px;
    position: relative;
  }
}

@media (max-width: 767px) {
  .flexbox {
    display: inline;
  }
  .investor_relations {
    padding: 2em 0 3.9em;
    overflow-x: hidden;
  }
  .investor_relations .left_section {
    max-width: 100%;
    margin-bottom: 5em;
  }
  .overview_financialinfo .right_section {
    display: none;
  }
  .mobile_section {
    display: block;
    text-align: right;
    margin-right: -25px;
    position: relative;
  }
  .mob_bg_img .mob_img1 {
    max-width: 65%;
  }
  .mob_fg_img {
    max-width: 33em;
    position: absolute;
    margin-right: 12px;
    top: 16%;
  }
  .num1,
  .num2,
  .num3 {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
  .investor_relations .hollow_title {
    font-size: 5em;
    margin-bottom: -0.5em;
    margin-top: 0em;
  }
  .left_td,
  .right_td {
    font-size: 1.5em;
  }
  .left_td {
    padding: 0.7em 0.5em 0.7em 1em;
    width: 100%;
    max-width: 70%;
  }
  .overview_financialinfo .flexbox a:not(:first-child),
  .other_information .flexbox a:not(:first-child) {
    margin-top: 3em;
  }
  .overview_financialinfo .flexbox .hollow_title,
  .other_information .flexbox .hollow_title {
    line-height: 1.5em;
  }
  .fi_heading {
    margin-top: 2em;
  }
  .pdf_modal_content .fin_popup .pdf_content {
    max-width: 100%;
    margin-top: 0;
  }
}
