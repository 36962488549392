@charset "utf-8";

/*------------------------------------------------------------------
  [Table of contents]
  * To keep an overview of structure of your code, we make use of table of 
  * content. Table of content is use display the overall tree structure of your
  * Layout. 
  * (NOTE: Remove this commented code once you start developing the code.
  * Remove the following table of content and add your own as this is just and 
  * example to let you know)

  1. CSS Reset Code
    1.1. Eric Meyers Reset/HTML5 display-role reset for older browsers
    1.2. Clear Fix
    1.3. Font class declaration
  2. Body
  3. Header / .header
    2.1. Navigation / .navbar
  4. Content / .content
    4.1. Left column / .leftcolumn
    4.2. Right column / .rightcolumn
    4.3. Sidebar / .sidebar
      4.3.1. RSS / .rss
      4.3.2. Search / .search
      4.3.3. Boxes / .box
      4.3.4. Sideblog / .sideblog
      4.3.5. Advertisements / .ads
  5. Footer / .footer
  ------------------------------------------------------------------*/

/*------------------------------------------------------------------
  [Color codes]
  * Mention the color code you have used in your html document for the respective
  * elements given as follow. 
  * (NOTE: Remove this commented code once you start developing the code. 
  * Remove the following color code and add your own as this is just and 
  * example to let you know)

  Header nav: #e5e5df (Cararra)
  Header nav a (hover): #fff (white)
  Header nav a (active): #fff (white)
  -------------------------------------------------------------------*/

/*------------------------------------------------------------------
  [Typography]
  * Mention the Typography you have used in your html document for the respective
  * elements given as follow.
  * (NOTE: Remove this commented code once you start developing the code. 
  * Remove the following typography and add your own as this is just and 
  * example to let you know)

  Header nav a: 16px 'Futura Bold', 'Gill Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  View-register li a : 12px 'Futura Bold', 'Gill Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  -------------------------------------------------------------------*/

/* ==========================================================================
   1. CSS Reset Code
   ========================================================================== */

/* 1.1. Eric Meyers Reset
   http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
   ========================================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  /* font: inherit; */
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* Force scrollbar */
html {
  overflow-y: scroll;
}

/* Align radios and text inputs with their label */

input[type="radio"] {
  vertical-align: text-bottom;
}

/* Hand cursor on clickable input elements */
label,
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
}

strong {
  font-weight: bold;
  font-family: inherit;
  font-size: inherit;
}

em {
  font-style: italic;
}

img {
  border: 0;
  vertical-align: middle;
}

sub,
sup {
  vertical-align: baseline;
  position: relative;
  font-size: 55%;
  line-height: 0;
}

sup {
  top: -0.7em;
}

sub {
  bottom: -0.25em;
}

/* 1.2. Clear Fix
========================================================================== */

.cf:before,
.cf:after {
  display: table;
  content: " ";
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

/* 1.3. Font class declaration
========================================================================== */
/*
 * Mention the font property (eg: font-family, font-size, font-weight, font-style
 * etc) that have been commonly used in the html document. Make a class of it and
 * apply to the element wherever it is needed. If any single property in changed,
 * then override only that property in the particular class which is used for the
 * element.
 * (NOTE: Remove this commented code once you start 
 * developing the code. Remove the following font class and add your own
 * as this is just and example to let you know. If there is only one font family
 * followed throughout the site, then menstion the font property in body. No need
 * of creating an extra class for it) */

.futura-bold-font {
  font-family: "Futura Bold", "Gill Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  font-style: normal;
  font-weight: bold;
}

/* ======= Your styling goes below ======= */

/*============================
    Common Styling    
============================*/
body {
  outline: none;
}

li {
  display: inline-block;
  list-style-type: none;
}

a {
  display: inline-block;
  outline: none;
  text-decoration: none;
}
a {
  text-decoration: none;
}
a:hover {
  outline: none;
  text-decoration: none;
}

p {
  font-size: 1.57em !important;
  line-height: 1.65em !important;
}

img {
  width: 100%;
}
html {
  scroll-behavior: smooth;
}
#root {
  position: relative;
}
/* body { position: relative; } */
button {
  outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}

.hollow_title {
  font-size: 8.75em;
  font-family: "National-SemiBold";
  line-height: 1.3em;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-image: linear-gradient(
    to right,
    #1c1c1c,
    #1c1c1c 50%,
    transparent 50%
  ); */
  background-size: 200% 100%;
  background-position: 100%;
  transition: all 1s cubic-bezier(0, 0, 0.23, 1);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1c1c1c;
  display: inline-block;
  /* text-transform: capitalize; */
}

/* .hollow_title:hover {
  transition: all 3s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1c1c1c;
} */

.breadcrumb_styles {
  color: #1c1c1c;
  font-family: "National-Medium";
  font-size: 1.25em;
  line-height: 3.15em;
}

.breadcrumb_link {
  color: inherit;
}

.breadcrumb_link:hover {
  color: inherit;
}

.navigations button {
  border: none;
  position: relative;
  background: transparent;
  color: #fff;
  font-family: "National-Normal";
  font-size: 1.25em;
  line-height: 3.15em;
  outline: none;
}

.navigations .left_arrow:after {
  content: "";
  width: 47px;
  height: 47px;
  border: 2px dashed #fff;
  border-radius: 50%;
  position: absolute;
  right: -1em;
  top: 0.3em;
  opacity: 0.7;
}

.navigations .right_arrow::before {
  content: "";
  width: 47px;
  height: 47px;
  border: 2px dashed #fff;
  border-radius: 50%;
  position: absolute;
  left: -1em;
  top: 0.3em;
  opacity: 0.7;
}

.navigations button:last-child {
  margin-left: 3em;
}
.navigations .swiper-button-disabled {
  opacity: 0.5;
}
.navigations .left_arrow:hover:after {
  animation: reverse_spin 10s linear infinite;
}
.navigations .right_arrow:hover:before {
  animation: spin 10s linear infinite;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes reverse_spin {
  100% {
    transform: rotateZ(-360deg);
  }
}

@font-face {
  font-family: "Gordita-Medium";
  src: url("./fonts/Gordita-Medium.eot");
  src: url("./fonts/Gordita-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gordita-Medium.woff2") format("woff2"),
    url("./fonts/Gordita-Medium.woff") format("woff"),
    url("./fonts/Gordita-Medium.ttf") format("truetype"),
    url("./fonts/Gordita-Medium.svg#National-Book") format("svg");
  font-weight: medium;
  font-style: medium;
  font-display: swap;
}

@font-face {
  font-family: "Gordita-Regular";
  src: url("./fonts/Gordita-Regular.eot");
  src: url("./fonts/Gordita-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gordita-Regular.woff2") format("woff2"),
    url("./fonts/Gordita-Regular.woff") format("woff"),
    url("./fonts/Gordita-Regular.ttf") format("truetype"),
    url("./fonts/Gordita-Regular.svg#National-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gordita-Bold";
  src: url("./fonts/Gordita-Bold.eot");
  src: url("./fonts/Gordita-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gordita-Bold.woff2") format("woff2"),
    url("./fonts/Gordita-Bold.woff") format("woff"),
    url("./fonts/Gordita-Bold.ttf") format("truetype"),
    url("./fonts/Gordita-Bold.svg#National-Book") format("svg");
  font-weight: bold;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "National-Normal";
  src: url("./fonts/National-Book.eot");
  src: url("./fonts/National-Book.eot?#iefix") format("embedded-opentype"),
    url("./fonts/National-Book.woff2") format("woff2"),
    url("./fonts/National-Book.woff") format("woff"),
    url("./fonts/National-Book.ttf") format("truetype"),
    url("./fonts/National-Book.svg#National-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National-Light";
  src: url("./fonts/National-Light.eot");
  src: url("./fonts/National-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/National-Light.woff2") format("woff2"),
    url("./fonts/National-Light.woff") format("woff"),
    url("./fonts/National-Light.ttf") format("truetype"),
    url("./fonts/National-Light.svg#National-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National-Medium";
  src: url("./fonts/National-Medium.eot");
  src: url("./fonts/National-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/National-Medium.woff2") format("woff2"),
    url("./fonts/National-Medium.woff") format("woff"),
    url("./fonts/National-Medium.ttf") format("truetype"),
    url("./fonts/National-Medium.svg#National-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National-SemiBold";
  src: url("./fonts/National-Semibold.eot");
  src: url("./fonts/National-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/National-Semibold.woff2") format("woff2"),
    url("./fonts/National-Semibold.woff") format("woff"),
    url("./fonts/National-Semibold.ttf") format("truetype"),
    url("./fonts/National-Semibold.svg#National-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .wrapper {
    max-width: 2293.3px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .wrapper {
    max-width: 1720px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
}

@media (max-width: 1919px) {
  body {
    font-size: 14px;
  }
  .wrapper {
    max-width: 1361.67px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
}

@media (max-width: 1536px) {
  body {
    font-size: 13px;
  }
  .navigations .right_arrow::before {
    left: -1.4em;
  }
}

@media (max-width: 1440px) {
  body {
    font-size: 12px;
  }
  .wrapper {
    max-width: 1290px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
  .navigations .right_arrow::before {
    left: -1.7em;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 11px;
  }
  .wrapper {
    max-width: 1223.708px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
  .navigations .right_arrow::before {
    left: -1.8em;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10.5px;
  }
  .wrapper {
    max-width: 1146.67px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .wrapper {
    max-width: 1069.625px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  body {
    font-size: 10px;
  }
  .wrapper {
    max-width: 950px;
    width: 100%;
    /* padding: 0 15px; */
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .wrapper {
    max-width: 800px;
    /* padding: 0 15px; */
  }
}

@media (max-width: 834px) {
  body {
    font-size: 11.5px;
  }
  .wrapper {
    max-width: 747.125px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 10px;
  }
  .wrapper {
    max-width: 712px;
    width: 100%;
    margin: 0 auto;
  }
  p {
    font-size: 1.6em !important;
    line-height: 1.65em !important;
  }
  .hollow_title {
    font-size: 5em;
    line-height: 1.88em;
  }
  /* h3 {
    font-size: 2.4em;
    line-height: 1.25em;
  }
  p {
    font-size: 1.3em;
    line-height: 1.53em;
  } */
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
  .wrapper {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 2.26em;
  }
}

/* @media (max-width: 451px) {
  body {
    font-size: 10px;
  }
  .wrapper {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }
} */

/* @media (max-width: 400px) {
  body {
    font-size: 10px;
  }
  .wrapper {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }
} */
.sanathan_actual_btn {
  border: 0;
  background-color: transparent;
  /* margin-top: 2em; */
}
.sanathan_actual_btn .download_btn_text {
  font-size: 1.375em;
  line-height: 1.227em;
}
.sanathan_btn {
  cursor: pointer;
}
.sanathan_btn:hover .btn_arrow {
  transform: scaleX(1.1);
  transform-origin: left;
}
.download_btn_text {
  font-size: 1.25em;
  color: #002b5a;
  font-family: "National-Medium";
  text-transform: uppercase;
}
.btn_arrow {
  width: 100%;
  max-width: 6.125em;
  display: block;
  transition: 0.3s all;
}
/* google translater css start */
/* body > .skiptranslate {
  display: none;
}
body {
  top: 0px !important;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
#google_translate_element {
  position: fixed;
  z-index: 22;
  top: 0;
  right: 1em;
  width: 11.5em;
  overflow: hidden;
}
#google_translate_element .goog-logo-link {
  display: none;
} */
/* google translater css end */
