.share_hold_sec2 {
  padding: 6.25em 0 6.875em;
  padding-top: 12.625em; 
  .invest_row {
    margin: 0 auto;
    .invest_col {
      padding: 0;
      &:nth-child(2n + 1) {
        padding-right: 0.3125em;
      }
      &:nth-child(2n + 2) {
        padding-left: 3.8125em;
      }
      .heading {
        color: #012b5b;
        font-size: 2.5em;
        font-family: "National-Medium";
        letter-spacing: 0px;
        line-height: 1.2;
        margin-bottom: 1em;
      }
      .data_list_container {
        .year {
          font-size: 2.5em;
          font-family: "National-Medium";
          color: #012b5b;
          letter-spacing: 0;
          line-height: 1.2;
          border-bottom: 1px solid rgba($color: #707070, $alpha: 0.5);
          padding: 1em 0 0.75em;
          margin-bottom: 0.75em;
        }
        .data_col {
          &:nth-child(1) {
            .year {
              padding-top: 0;
            }
          }
        }

        .data_list_box {
          border: 1px solid #707070;
          border-bottom: 0.625em solid #012b5b;
          margin-bottom: 2.5em;
          padding: 1.875em 2.5em 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .head_wrapper {
          width: 100%;
          max-width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 1.875em;
          .title {
            color: #000;
            font-size: 2.5em;
            font-family: "National-Normal";
            letter-spacing: 0px;
            line-height: 1.25;
          }
          .arrow {
            width: 100%;
            max-width: 1.3125em;
            margin-left: 1.25em;
            transition: 0.3s ease;
            transform: rotate(180deg);
          }
        }
        .content_wrapper {
          border-top: 1px solid #707070;
          padding: 2.5em 0;
          .pdf_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.75em;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .pdf_text_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // width: 100%;
            // max-width: 70%;
            margin-right: 0.625em;
          }
          .pdf_icon {
            width: 100%;
            max-width: 2.4375em;
          }
          .pdf_title {
            color: #000000;
            font-size: 2em;
            font-family: "National-Normal";
            letter-spacing: 0px;
            line-height: 1.219;
            margin-left: 0.625em;
          }
          .pdf_link {
            display: block;
            text-decoration: underline;
            // width: 100%;
            // max-width: 30%;
            color: #012b5b;
            font-size: 1.25em;
            font-family: "National-Normal";
            letter-spacing: 0px;
            line-height: 1.2;
            text-align: right;
          }
          .dwn_icon {
            width: 100%;
            max-width: 1.05em;
            min-width: 1.05em;
            margin-left: 0.5em;
          }
        }
      }
    }
  }
}
.stck_ivstor_rel{
  background-color: transparent;
}
.share_hold_sec1 {
  .wrapper {
    .right_section {
      .box_stock_hold {
        width: 27.649em;
        height: 17.313em;
        position: absolute;

        .stock_box {
          margin-top: 5.625em;
          color: #fff;
          padding: 1.875em;
          padding-bottom: 2.5em;
          height: 100%;

          .stock_light_heading {
            font-size: 1.125em;
          }

          .stock_md_heading {
            font-size: 1.125em;
          }

          .stock_rate_graph_wrapper {
            position: relative;
            min-width: 100%;
            padding-left: 1.875em !important;
            margin-top: 2em;
            width: 27.649em !important;
            margin-left: -1.875em !important;

            .stock_rate {
              font-size: 3.125em;
            }

            .stock_graph_img {
              top: -53%;
            }
          }

          .stock_up_down_percentage {
            font-size: 1em;

            .stock_d_icon {}
          }

          .date_stock_wrapper {
            margin-top: 1.313em;

            .date_stock {
              font-size: 1em;
            }

            .time_stock {
              font-size: 1em;
            }
          }

          .growth_stock {}
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .share_hold_sec2 {
    .invest_row {
      .invest_col {
        &:nth-child(2n + 2) {
          padding-left: 1.5em;
        }
        .heading {
          font-size: 2.2em;
        }
        .data_list_container {
          .year {
            font-size: 2.2em;
          }
          .data_list_box {
            padding: 1.875em 1.6em 0;
          }
          .head_wrapper {
            .title {
              font-size: 2.2em;
            }
            .arrow {
              max-width: 0.455em;
            }
          }
          .content_wrapper {
            .pdf_title {
              font-size: 1.8em;
            }
            .pdf_icon {
              max-width: 2em;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .stck_ivstor_rel{
    padding-bottom: 0;
    margin-top: 6em;
  }
}
@media (max-width: 767px) {
  .share_hold_sec2 {
    padding: 6em 0 6em;
    .invest_row {
      .invest_col {
        &:nth-child(2n + 1) {
          padding-right: 0;
        }
        &:nth-child(2n + 2) {
          padding-left: 0;
        }
        .heading {
          font-size: 2.4em;
          letter-spacing: 0px;
          line-height: 1.417;
          margin: 0.8334em 0;
        }
        .data_list_container {
          .year {
            font-size: 2.2em;
            letter-spacing: 0px;
            line-height: 1.227;
            padding: 1.819em 0 0.728em;
            margin-bottom: 1.091em;
          }

          .data_list_box {
            padding: 2em 1.6em 0;
          }
          .head_wrapper {
            padding-bottom: 1.6em;
            .title {
              font-size: 2.2em;
              letter-spacing: 0px;
              line-height: 1.227;
            }
            .arrow {
              max-width: 1.1em;
              margin-left: 1.1em;
            }
          }
          .content_wrapper {
            padding: 3em 0 2em;
            .pdf_container {
              margin-bottom: 2.4em;
            }
            .pdf_title {
              font-size: 2em;
              letter-spacing: 0px;
              line-height: 1.2;
              margin-left: 0.45em;
            }
            .pdf_icon {
              max-width: 2.357em;
            }
            .dwn_icon {
              max-width: 1.093em;
              min-width: 1.093em;
              margin-left: 0.8em;
            }
          }
        }
        .invest_tab_wrapper_2 {
          margin-top: 4em;
        }
      }
    }
  }
}
