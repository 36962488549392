.our_sales_section2 {
  .swiper-pagination-bullet {
    transition: all 0.3s ease-in-out;
  }
  .swiper-pagination-bullet-active {
    padding: 0em 0.7em;
    border-radius: 5px;
  }
}
.map_sec2 {
  display: none;
}

@media (max-width: 834px) {
  .our_sales_section1 {
    margin-top: 11.5em;
    padding: 2em 0 3.75em;
    .hollow_title {
      font-size: 6.75em;
      line-height: 1em;
      margin-top: 0;
    }
    .our_sales_heading {
      h3 {
        font-size: 2.5em;
      }
      p {
        max-width: 36.25em;
      }
    }
  }
  .our_sales_section2 {
    .navigations {
      margin-right: 2em;
    }
  }
  .clients {
    margin-top: 6em;
  }
}

@media (max-width: 767px) {
  .our_sales_section1 {
    .hollow_title {
      font-size: 5em;
    }
    .our_sales_heading {
      display: inline-block;
    }
  }
  .our_sales_section2 {
    .hollow_title {
      font-size: 5em;
    }
    .oursales_slider_wrapper {
      padding: 4em 0;
    }
    .oursales_slider_wrapper {
      ul {
        margin-top: 5em;
        margin-bottom: 12em;
      }
    }
    .swiper-slide {
      width: 100% !important;
      max-width: 50% !important;
      margin-right: 1em !important;
    }
    .navigations {
      display: flex;
      position: absolute;
      bottom: 5em;
      transform: translate(68%);
    }
  }
  .map_sec2 {
    display: block;
    ul {
      li {
        display: block;
        position: relative;
        padding-left: 1.5em;
        margin-top: 1em;
        letter-spacing: 0px;
        color: #1c1c1c;
        font-family: "National-Light";
        font-size: 1.25em;
        opacity: 1;
        width: 100%;
        max-width: 80%;
        &::before {
          content: "";
          position: absolute;
          top: 1em;
          left: 0.1em;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: #002b5a;
        }
      }
    }
  }
}
